import React, { Ref, useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useTheme } from 'styled-components/native';

import { useMergeRefs } from '~/hooks/useMergedRefs';

import {
  Container,
  Content,
  EndIconContainer,
  StartIconContainer,
  TextInput,
} from './FocusableInput.styles.web';
import { IFocusableInput, FocusIn } from './FocusableInput.types';
import { HelperText } from './components/HelperText/HelperText';

const FocusableInputComponent = (props: IFocusableInput) => {
  const {
    focused,
    focusColor,
    endIcon,
    startIcon,
    onClickEndIcon,
    onClickStartIcon,
    onChange,
    placeholder,
    type,
    style,
    setFocus,
    error,
    innerRef,
    placeholderTextColor,
  } = props;
  const [focusIn, setFocusIn] = useState<FocusIn>();
  const inputRef = useRef<HTMLInputElement>(null);
  const endIconRef = useRef<HTMLButtonElement>(null);
  const startIconRef = useRef<HTMLButtonElement>(null);
  const multiRef = useMergeRefs(inputRef, innerRef as Ref<HTMLInputElement>);
  const { colors } = useTheme();

  const styles = {
    button: {
      height: '3.5rem',
      width: '100%',
      ...(StyleSheet.flatten(style) as any),
    },
  };

  const setElementFocus = useCallback(
    (element: 'start' | 'input' | 'end' | undefined) => {
      if (element === 'input') {
        setFocusIn(FocusIn.Input);
        startIconRef.current?.blur();
        inputRef.current?.focus();
        endIconRef.current?.blur();
      } else if (element === 'start') {
        setFocusIn(FocusIn.StartIcon);
        startIconRef.current?.focus();
        inputRef.current?.blur();
        endIconRef.current?.blur();
      } else if (element === 'end') {
        setFocusIn(FocusIn.EndIcon);
        startIconRef.current?.blur();
        inputRef.current?.blur();
        endIconRef.current?.focus();
      } else {
        setFocusIn(undefined);
        startIconRef.current?.blur();
        inputRef.current?.blur();
        endIconRef.current?.blur();
      }
    },
    [startIconRef, inputRef, endIconRef]
  );

  const handleChangeFocus = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      switch (e.key) {
        case 'Enter':
          if (focused) {
            if (focusIn === FocusIn.EndIcon && onClickEndIcon) {
              onClickEndIcon();
            } else if (focusIn === FocusIn.StartIcon && onClickStartIcon) {
              onClickStartIcon();
            }
          }
          break;
        case 'ArrowLeft':
          if (focusIn === FocusIn.Input && startIcon) {
            setElementFocus('start');
          } else if (focusIn === FocusIn.Input && endIcon) {
            setElementFocus('end');
          } else if (focusIn === FocusIn.StartIcon && endIcon) {
            setElementFocus('end');
          } else {
            setElementFocus('input');
          }
          break;
        case 'ArrowRight':
          if (focusIn === FocusIn.Input && endIcon) {
            setElementFocus('end');
          } else if (focusIn === FocusIn.Input && startIcon) {
            setElementFocus('start');
          } else if (focusIn === FocusIn.EndIcon && startIcon) {
            setElementFocus('start');
          } else {
            setElementFocus('input');
          }
          break;
      }
    },
    [
      endIcon,
      focusIn,
      setElementFocus,
      startIcon,
      focused,
      onClickEndIcon,
      onClickStartIcon,
    ]
  );

  const onMouseEnter = (element: 'start' | 'input' | 'end') => () => {
    setFocus();
    setElementFocus(element);
  };

  useEffect(() => {
    if (focused && !focusIn) {
      setElementFocus('input');
    } else if (!focused && focusIn) {
      setElementFocus(undefined);
    }
  }, [setElementFocus, focusIn, focused]);

  return (
    <Container onKeyDown={handleChangeFocus}>
      <Content>
        {startIcon && (
          <StartIconContainer
            ref={startIconRef}
            focused={focused && focusIn === FocusIn.StartIcon}
            focusColor={colors.spatialFocus}
            onClick={onClickStartIcon}
            onMouseEnter={onMouseEnter('start')}
          >
            {startIcon}
          </StartIconContainer>
        )}

        <TextInput
          style={styles.button}
          startIcon={!!startIcon}
          endIcon={!!endIcon}
          onChange={
            onChange as
              | ((event: React.ChangeEvent<HTMLInputElement>) => void)
              | undefined
          }
          type={type}
          ref={multiRef}
          focused={focused && focusIn === FocusIn.Input}
          focusColor={focusColor || colors.spatialFocus}
          placeholder={placeholder}
          onMouseEnter={onMouseEnter('input')}
          placeholderTextColor={placeholderTextColor}
        />

        {endIcon && (
          <EndIconContainer
            ref={endIconRef}
            focused={focused && focusIn === FocusIn.EndIcon}
            focusColor={colors.spatialFocus}
            onClick={onClickEndIcon}
            onMouseEnter={onMouseEnter('end')}
          >
            {endIcon}
          </EndIconContainer>
        )}
      </Content>

      {!!error && <HelperText error={!!error} text={error ?? ''} />}
    </Container>
  );
};

export const FocusableInput = withFocusable()(FocusableInputComponent);
