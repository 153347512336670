import styled, { css } from 'styled-components/native';

import { verticalScale, horizontalScale } from '~/utils/layout';
import { FocusableButton } from '../FocusableButton/FocusableButton';
import { FB_BORDER_WIDTH } from '../FocusableButton/FocusableButton.styles';
import { CARD_DIMENSIONS } from '~/utils/constants';

import type { ICard } from './Card.types';

const CARD_BORDER_RADIUS = verticalScale(8);

export const StyledFocusableButton = styled(FocusableButton)`
  border-radius: ${CARD_BORDER_RADIUS}px;
`;

const getCardWidthWithoutBorders = (dense?: boolean) => {
  const baseWidth = dense ? CARD_DIMENSIONS.denseWidth : CARD_DIMENSIONS.width;
  return baseWidth - FB_BORDER_WIDTH * 2;
};

export const CardItem = styled.ImageBackground.attrs({
  imageStyle: {
    borderRadius: CARD_BORDER_RADIUS,
    aspectRatio: CARD_DIMENSIONS.aspectRatio,
  },
})<Pick<ICard, 'dense' | 'customWidth'>>`
  background-color: ${({ theme }) => theme.colors.itemBackground};
  justify-content: center;
  align-items: center;
  border-radius: ${CARD_BORDER_RADIUS}px;
  ${({ dense, customWidth }) => {
    const width = customWidth || getCardWidthWithoutBorders(dense);

    return css`
      width: ${width}px;
      height: ${width * CARD_DIMENSIONS.aspectRatio}px;
    `;
  }}
`;

export const CardBlocked = styled.View`
  flex: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.overlay};
  justify-content: center;
  align-items: center;
`;

export const CardBadge = styled.View`
  flex: 1;
  position: absolute;
  z-index: 1;
  left: ${horizontalScale(16)}px;
  top: ${verticalScale(16)}px;
`;

export const Tag = styled.View`
  background-color: ${({ theme }) => theme.colors.overlay};
  padding: ${verticalScale(12)}px;
  position: absolute;
  right: ${horizontalScale(16)}px;
  top: ${verticalScale(16)}px;
  align-items: center;
  justify-content: center;
  border-radius: ${verticalScale(4)}px;
`;

export const TagText = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(14)}px;
`;

export const FallbackCardTitle = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(26)}px;
  position: absolute;
  bottom: ${verticalScale(25)}px;
`;

export const WatchedClass = styled.View`
  flex: 1;
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${({ theme }) => theme.colors.progress.elapsed};
  width: 100%;
  height: 8px;
`;
