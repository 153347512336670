import axios from 'axios';
import { Platform } from 'react-native';

export interface IDevice {
  id: string;
  parentId: number | null;
  userId: number;
  ip: string;
  metadata: IDeviceMetadata;
  status: IDeviceStatus;
  type: IDeviceType;
  media?: IDeviceMedia;
  createdAt: Date | null;
  updatedAt: Date | null;
  location: IDeviceLocation;
}

export interface IDevicesListProps {
  currentDevice: IDevice;
  otherDevices: IDevice[];
}

export interface IDeviceLocation {
  city: string;
  country: string;
  countryName: string;
  region: string;
  regionName: string;
  latitude: string;
  longitude: string;
  ip: string;
}

export enum PlayerTypes {
  PROGRAM = 'program',
  SHEET_GYM = 'sheet_gym',
  TEASER = 'teaser',
  LIVE = 'live',
}

export interface IDeviceMedia {
  name: string;
  type: PlayerTypes;
}

export interface IDeviceMetadata {
  os: string;
  brand: string;
  model?: string;
}

export type IDeviceStatus = 'inactive' | 'active' | 'watching';

export type IDeviceType = 'web' | 'tv' | 'mobile' | 'unknown';

export interface IDeviceInfo {
  brand?: string;
  deviceOS?: string;
  platformVersion?: string;
  family?: string;
  screenHeight?: number;
  screenWidth?: number;
  navigator?: string;
  modelName?: string;
}

export interface IIpAdress {
  ip?: string;
  region?: string;
  city?: string;
  country?: string;
  org?: string;
}

export const getPlatform = () => {
  if (webOS.platform?.tv) {
    return 'webOS';
  }
  if (window.tizen) {
    return 'tizen';
  }
  if (Platform.OS === 'android') {
    return 'android';
  }
  return;
};

export const getDeviceLocation = async () => {
  const { data } = await axios.get<IDeviceLocation>(
    'https://geo.queimadiaria.com/'
  );

  return data;
};

const getNavigator = () => {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) {
      return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return M.join(' ');
};

export const getIp = async () => {
  return new Promise<IIpAdress | null>((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://ipinfo.io/json');
    xhr.addEventListener('load', () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.response));
        } else {
          resolve(null);
        }
      }
    });
    xhr.send();
  });
};

export const getDeviceInfo = () => {
  const platform = getPlatform();
  const navigator = Platform.OS === 'web' ? getNavigator() : '';

  return new Promise<IDeviceInfo | null>((resolve) => {
    if (platform === 'webOS') {
      webOS.deviceInfo(
        ({ sdkVersion, modelName, screenHeight, screenWidth }) => {
          resolve({
            brand: 'LG',
            deviceOS: 'Web0S',
            family: 'LG Web0S SmartTV',
            screenHeight,
            screenWidth,
            platformVersion: sdkVersion,
            modelName,
            navigator,
          });
        }
      );
    } else if (platform === 'tizen') {
      const platformVersion = window.tizen?.systeminfo?.getCapability(
        'http://tizen.org/feature/platform.version'
      );
      const modelName = window.tizen?.systeminfo?.getCapability(
        'http://tizen.org/system/model_name'
      );
      const screenHeight = window.tizen?.systeminfo?.getCapability(
        'http://tizen.org/feature/screen.height'
      );
      const screenWidth = window.tizen?.systeminfo?.getCapability(
        'http://tizen.org/feature/screen.width'
      );
      resolve({
        brand: 'Samsung',
        deviceOS: 'Tizen',
        family: 'LG Web0S SmartTV',
        screenHeight,
        screenWidth,
        platformVersion,
        modelName,
        navigator,
      });
    } else if (platform === 'android') {
      const constants = (Platform as any).constants;
      const brand = constants?.Brand;
      const platformVersion = constants?.Release;
      const modelName = constants?.Model;
      resolve({
        brand,
        deviceOS: Platform.OS + 'TV',
        platformVersion,
        modelName,
      });
    } else {
      resolve(null);
    }
  });
};
