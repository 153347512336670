import { useMemo } from 'react';

import { useSession } from '~/contexts/SessionContext/SessionContext';
import { isValidDate } from '~/utils/functions';

export const useHasAccessToProgram = (programId: number) => {
  const { userPrograms } = useSession();

  const hasAccessToProgram = useMemo(() => {
    if (!userPrograms) {
      return false;
    }

    const arrayPrograms = Object.keys(userPrograms).filter(
      (key: string) => Number(key) === programId
    );
    return (
      !arrayPrograms.length &&
      !isValidDate(userPrograms[programId]?.expirationDate)
    );
  }, [programId, userPrograms]);

  return hasAccessToProgram;
};
