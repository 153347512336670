import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';

import type { IStageVariant } from './Stage.types';

const SIZE = verticalScale(48);

export const Container = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: ${verticalScale(16)}px;
`;

export const LabelBackground = styled.View<IStageVariant>`
  background-color: ${({ theme: { colors }, variant }) =>
    variant === 'highlight' ? colors.stage.highlight : 'transparent'};
  border-radius: ${SIZE / 2}px;
  height: ${SIZE}px;
  width: ${SIZE}px;
  justify-content: center;
  align-items: center;
  margin-right: ${verticalScale(16)}px;
  border-width: ${({ variant }) =>
    variant === 'highlight' ? 0 : verticalScale(1)}px;
  border-color: ${({ theme: { colors }, variant }) =>
    variant === 'active' ? colors.stage.active : colors.text.default};
`;

const BaseText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(24)}px;
`;

export const Label = styled(BaseText)<IStageVariant>`
  font-size: ${verticalScale(24)}px;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme: { colors }, variant }) =>
    variant === 'active' ? colors.stage.active : colors.text.default};
`;

export const StepText = styled(BaseText)`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(24)}px;
`;
