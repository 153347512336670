import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';
import { Animated } from 'react-native';
import { LayoutObject } from '@noriginmedia/react-spatial-navigation';

import { baseAssetsUrl } from '~/configs/env';
import { LockIcon } from '~/assets/icons/LockIcon';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';

import {
  StyledFocusableButton,
  CardItem,
  CardBlocked,
  CardBadge,
  Tag,
  TagText,
  FallbackCardTitle,
  WatchedClass,
} from './Card.styles';

import type { ICard } from './Card.types';

const BASE_URL = baseAssetsUrl + '/';
const TRANSITION_TIME = 150;

export const Card = (props: ICard) => {
  const {
    blockFocusDirections,
    isContentBlocked,
    imageURL,
    onSelectCard,
    innerRef,
    timesWatched,
    onBecameFocused,
    name,
    badge,
    focusKey,
    hasTVPreferredFocus,
    dense,
    customWidth,
  } = props;

  const { t } = useTranslation();
  const { colors } = useTheme();
  const scale = useRef(new Animated.Value(1)).current;
  const [showFallbackImage, setShowFallbackImage] = useState(false);
  const cardAction = isContentBlocked ? undefined : onSelectCard;
  const cardProps = useBlockFocusDirection(blockFocusDirections || []);

  const innerOnBecameFocused = (element: LayoutObject) => {
    onBecameFocused?.(element);
    Animated.timing(scale, {
      toValue: 1.1,
      duration: TRANSITION_TIME,
      useNativeDriver: true,
    }).start();
  };

  const onBecameBlurred = () => {
    Animated.timing(scale, {
      toValue: 1,
      duration: TRANSITION_TIME,
      useNativeDriver: true,
    }).start();
  };

  return (
    <Animated.View style={{ transform: [{ scale }] }}>
      <StyledFocusableButton
        focusKey={focusKey}
        hasTVPreferredFocus={hasTVPreferredFocus}
        onPress={cardAction}
        onEnterPress={cardAction}
        focusedBorderColor={colors.spatialFocus}
        onBecameFocused={innerOnBecameFocused}
        onBecameBlurred={onBecameBlurred}
        innerRef={innerRef}
        {...cardProps}
      >
        {badge && <CardBadge>{badge}</CardBadge>}
        {showFallbackImage ? (
          <CardItem
            dense={dense}
            resizeMethod="resize"
            resizeMode="cover"
            source={require('../../assets/img/fallbackCard.png')}
            customWidth={customWidth}
          >
            <FallbackCardTitle>{name}</FallbackCardTitle>
          </CardItem>
        ) : (
          <CardItem
            dense={dense}
            resizeMethod="resize"
            resizeMode="cover"
            onError={() => setShowFallbackImage(true)}
            source={{ uri: BASE_URL + imageURL }}
            customWidth={customWidth}
          />
        )}

        {isContentBlocked && (
          <CardBlocked>
            <LockIcon />
          </CardBlocked>
        )}

        {!isContentBlocked && !!timesWatched && (
          <>
            <Tag>
              <TagText>
                {t('program.watched', 'Visto')} {timesWatched}{' '}
                {timesWatched > 1
                  ? t('program.times', 'vezes')
                  : t('program.time', 'vez')}
              </TagText>
            </Tag>
            <WatchedClass />
          </>
        )}
      </StyledFocusableButton>
    </Animated.View>
  );
};
