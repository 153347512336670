import React, { memo, useMemo, useCallback } from 'react';

import { KeyboardTemplate } from '../KeyboardTemplate/KeyboardTemplate';
import { KeyboardLayoutMap } from '../../Keyboard.types';

import { generateEmailKeys } from './generateEmailKeys';

import type { IKeyboardControls } from '../../Keyboard.types';

const EmailControlsComponent = (props: IKeyboardControls) => {
  const { textRef, onChangeText, keyboardLayout, updateLayout } = props;

  const toggleSpecialLayout = useCallback(() => {
    updateLayout((prevLayout) =>
      prevLayout !== KeyboardLayoutMap.Special
        ? KeyboardLayoutMap.Special
        : KeyboardLayoutMap.Alphanumeric
    );
  }, [updateLayout]);

  const handleBackspace = useCallback(() => {
    const newText = textRef.current.slice(0, -1);
    onChangeText(newText);
  }, [onChangeText, textRef]);

  const template = useMemo(
    () =>
      generateEmailKeys({
        keyboardLayout,
        toggleSpecialLayout,
        handleBackspace,
      }),
    [handleBackspace, keyboardLayout, toggleSpecialLayout]
  );

  return <KeyboardTemplate {...props} template={template} />;
};

export const EmailControls = memo(EmailControlsComponent);
