import React, { useEffect } from 'react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';

import { WarningIcon } from '~/assets/icons/WarningIcon';
import { FloatingLogo } from '../FloatingLogo/FloatingLogo';
import { ActionButton } from '../ActionButton/ActionButton';
import { ScreenBackground } from '../ScreenBackground/ScreenBackground';

import { Content, ErrorTitle, ErrorMessage } from './ScreenError.styles';

import type { IScreenError } from './ScreenError.types';

const FOCUS_KEY = 'screen-error';

const ScreenErrorComponent = (props: IScreenError) => {
  const { setFocus, retry, message } = props;

  const { t } = useTranslation();

  useEffect(() => {
    setFocus(FOCUS_KEY);
  }, [setFocus]);

  return (
    <ScreenBackground>
      <FloatingLogo />

      <Content>
        <WarningIcon />

        <ErrorTitle>{t('common.error_occurred', 'Ocorreu um erro')}</ErrorTitle>
        <ErrorMessage>{message}</ErrorMessage>

        <ActionButton
          text={t('common.try_again', 'Tentar novamente')}
          onEnterPress={retry}
          onPress={retry}
          hasTVPreferredFocus
          focusKey={FOCUS_KEY}
        />
      </Content>
    </ScreenBackground>
  );
};

export const ScreenError = withFocusable({
  blockNavigationOut: true,
})(ScreenErrorComponent);
