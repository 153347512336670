import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { I18nContext } from 'react-i18next';
import { setWatchParams } from '@queimadiaria/content-watch-tracker';

import { baseUrl } from '~/configs/env';
import { api } from '~/configs/api';
import { navigationRef } from '~/routes/rootNavigation';
import { storage } from '~/utils/storage';
import { Crashlytics } from '~/utils/crashlytics';
import { appendAxiosHeader } from '~/utils/functions';
import { USER_TOKEN_KEY } from '~/utils/constants';
import {
  deleteUserDevicesConnected,
  fetchCurrentUser,
  fetchUserProgramsAccess,
} from '~/services/user/userApi';
import type { ICurrentUser } from '~/services/user/userApi.types';
import { useDeviceLimit } from '../DeviceLimitContext/DeviceLimitContext';

import type { ISessionContext, ISessionData } from './SessionContext.types';

const SessionContext = createContext({} as ISessionContext);

export const useSession = () => useContext(SessionContext);

const PERSISTED_EMAIL_KEY = '@Queima::persisted_email';
const EMPTY_SESSION_DATA = {
  currentUser: null,
  userPrograms: null,
};

export const SessionProvider: React.FC = ({ children }) => {
  const { i18n } = useContext(I18nContext);
  const { startSocketConnections, closeSocketConnections } = useDeviceLimit();

  const [persistedEmail, setPersistedEmail] = useState('');
  const [sessionData, setSessionData] = useState<ISessionData>({
    currentUser: undefined,
    userPrograms: null,
  });

  const requestInterceptor = useRef<number | null>(null);
  const responseInterceptor = useRef<number | null>(null);

  const endSession = useCallback(() => {
    storage.removeItem(USER_TOKEN_KEY);
    removeApiInterceptors();
    closeSocketConnections();
    setSessionData(EMPTY_SESSION_DATA);
    Crashlytics.setUser(null);
  }, [closeSocketConnections]);

  const handleLogout: ISessionContext['handleLogout'] =
    useCallback(async () => {
      try {
        await deleteUserDevicesConnected();
      } catch (err) {
        Crashlytics.handleException(err, 'Delete device');
      } finally {
        endSession();
      }
    }, [endSession]);

  const onDeviceLimitReached = useCallback(() => {
    storage.removeItem(USER_TOKEN_KEY);
    removeApiInterceptors();
    closeSocketConnections();
    navigationRef.current?.navigate('DeviceDisconnected');
  }, [closeSocketConnections]);

  const setUserPreferences: ISessionContext['setUserPreferences'] = useCallback(
    (preferences) => {
      setSessionData((state) => {
        if (!state.currentUser) {
          return state;
        }

        return {
          ...state,
          currentUser: {
            ...state.currentUser,
            preferences: {
              ...state.currentUser.preferences,
              ...preferences,
            },
          },
        };
      });
    },
    []
  );

  const updateUserConfig = useCallback(
    (user: ICurrentUser) => {
      Crashlytics.setUser({ id: String(user.id), email: user.email });
      i18n.changeLanguage(user.preferences.language);
    },
    [i18n]
  );

  const initContentWatchTracker = useCallback(async () => {
    const token = await storage.getItem(USER_TOKEN_KEY);
    if (token) {
      setWatchParams({
        token,
        baseURL: baseUrl,
      });
    }
  }, []);

  const getSessionData = useCallback(async () => {
    const [currentUser, userPrograms] = await Promise.all([
      fetchCurrentUser(),
      fetchUserProgramsAccess(),
    ]);
    initContentWatchTracker();
    updateUserConfig(currentUser);
    closeSocketConnections();
    startSocketConnections({ onDeviceLimitReached });
    setSessionData({ currentUser, userPrograms });
  }, [
    initContentWatchTracker,
    onDeviceLimitReached,
    startSocketConnections,
    updateUserConfig,
    closeSocketConnections,
  ]);

  const setApiInterceptors = useCallback(
    (token: string) => {
      const requestInterceptorId = api.interceptors.request.use((config) => {
        appendAxiosHeader(config, 'Authorization', token);
        return config;
      });
      const responseInterceptorId = api.interceptors.response.use(
        undefined,
        (error) => {
          if (error?.response?.status === 401) {
            endSession();
          }
          return Promise.reject(error);
        }
      );

      requestInterceptor.current = requestInterceptorId;
      responseInterceptor.current = responseInterceptorId;
      storage.setItem(USER_TOKEN_KEY, token);
    },
    [endSession]
  );

  const removeApiInterceptors = () => {
    if (requestInterceptor.current) {
      api.interceptors.request.eject(requestInterceptor.current);
      requestInterceptor.current = null;
    }
    if (responseInterceptor.current) {
      api.interceptors.response.eject(responseInterceptor.current);
      responseInterceptor.current = null;
    }
  };

  const updatePersistedEmail = (email?: string | null) => {
    if (email) {
      storage.setItem(PERSISTED_EMAIL_KEY, email);
      setPersistedEmail(email);
    } else {
      storage.removeItem(PERSISTED_EMAIL_KEY);
      setPersistedEmail('');
    }
  };

  const onLoginSuccess: ISessionContext['onLoginSuccess'] = useCallback(
    async ({ userToken, email }) => {
      updatePersistedEmail(email);
      setApiInterceptors(userToken);
      await getSessionData();
    },
    [getSessionData, setApiInterceptors]
  );

  const onSocketLoginSuccess: ISessionContext['onSocketLoginSuccess'] =
    useCallback(
      async (eventData) => {
        setApiInterceptors(eventData.token);
        storage.removeItem(PERSISTED_EMAIL_KEY);
        const userPrograms = await fetchUserProgramsAccess();

        updateUserConfig(eventData.user);
        setSessionData({
          currentUser: eventData.user,
          userPrograms,
        });
      },
      [setApiInterceptors, updateUserConfig]
    );

  const handleAppStart: ISessionContext['handleAppStart'] =
    useCallback(async () => {
      const email = await storage.getItem(PERSISTED_EMAIL_KEY);
      updatePersistedEmail(email);

      const userToken = await storage.getItem(USER_TOKEN_KEY);
      if (!userToken) {
        setSessionData(EMPTY_SESSION_DATA);
        return;
      }

      try {
        setApiInterceptors(userToken);
        await getSessionData();
      } catch (err) {
        setSessionData(EMPTY_SESSION_DATA);
      }
    }, [setApiInterceptors, getSessionData]);

  return (
    <SessionContext.Provider
      value={{
        ...sessionData,
        persistedEmail,
        onLoginSuccess,
        onSocketLoginSuccess,
        handleAppStart,
        handleLogout,
        setUserPreferences,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
