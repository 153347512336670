import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';

export const PowerOffIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const { width = 45, height = 45, fill = colors.text.default } = props;

  return (
    <Svg width={width} height={height} viewBox={'0 0 45 45'}>
      <Path
        fill={fill}
        fillRule="evenodd"
        d="M17.852 2.637C17.852 1.143 19 0 20.502 0c1.503 0 2.652 1.143 2.652 2.637v15.82a2.651 2.651 0 0 1-2.651 2.637c-1.503 0-2.651-1.143-2.651-2.637V2.637zm18.116 35.332C32.08 42.45 26.424 45 20.503 45 9.19 45 0 35.86 0 24.61A20.264 20.264 0 0 1 7.07 9.228c1.149-.967 2.74-.88 3.712.263s.883 2.725-.266 3.692A15.016 15.016 0 0 0 9.014 34.54c5.48 6.328 15.112 7.031 21.475 1.494 6.363-5.537 7.07-15.03 1.502-21.357a12.03 12.03 0 0 0-1.502-1.494c-1.149-.967-1.237-2.637-.265-3.692.972-1.055 2.651-1.23 3.712-.263 8.572 7.382 9.456 20.302 2.032 28.74z"
      />
    </Svg>
  );
};
