import React, { useEffect, useCallback, useState } from 'react';
import { Platform } from 'react-native';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { LoadingIndicator } from '~/components/LoadingIndicator/LoadingIndicator';
import { GridCardList } from '~/components/GridCardList/GridCardList';
import { IGridCardListItem } from '~/components/GridCardList/GridCardList.types';
import { searchPrograms } from '~/services/programs/programsApi';
import { useScrollPostion } from '~/hooks/useScrollPostion';
import { Crashlytics } from '~/utils/crashlytics';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import type { SideMenuNavigationProp } from '~/routes/routes.types';
import { horizontalScale } from '~/utils/layout';
import { ISearchProgramsResponse } from '~/services/programs/programsApi.types';

import {
  SearchFilter,
  SEARCH_INPUT_KEY,
} from './components/SearchFilter/SearchFilter';
import {
  ContentRow,
  ScrollArea,
  EmptyListContainer,
  EmptyListText,
  Sidebar,
} from './Search.styles';

import type { IProgram } from './Search.types';
import type { OptionType } from './components/SearchFilter/SearchFilter';

const INITIAL_FILTERS = new Set<number>([]);

const CUSTOM_CARD_WIDTH = horizontalScale(328);

const SearchComponent = (props: FocusableElement) => {
  const { setFocus, stealFocus, updateAllSpatialLayouts } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const [loading, setLoading] = useState(true);
  const [programs, setPrograms] = useState<IGridCardListItem[]>([]);
  const [firstRender, setFirstRender] = useState(true);
  const navigation = useNavigation<SideMenuNavigationProp<'Search'>>();
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [selectedObjectives, setSelectedObjectives] = useState(INITIAL_FILTERS);
  const [selectedDifficulties, setSelectedDifficulties] =
    useState(INITIAL_FILTERS);
  const { scrollRef, scrollProps, handleElementFocus } = useScrollPostion();

  useEffect(() => {
    if (Platform.OS === 'web' && setFocus) {
      setFocus(SEARCH_INPUT_KEY);
    }
  }, [setFocus]);

  const findProgram = useCallback(
    ({ name }: IProgram) =>
      name.toLowerCase().includes(searchText.toLowerCase()),
    [searchText]
  );
  const findInstructor = useCallback(
    ({ instructors }: IProgram) => {
      if (instructors && instructors.length > 0) {
        return instructors[0].name
          .toLowerCase()
          .includes(searchText.toLowerCase());
      }
      return false;
    },
    [searchText]
  );

  useEffect(() => {
    const getPrograms = async () => {
      try {
        setLoading(true);
        const getSearch = await searchPrograms({
          searchText,
          objectivesFilter: [...selectedObjectives],
          levelsFilter: [...selectedDifficulties],
        });

        setPrograms(
          getSearch.map((program: ISearchProgramsResponse) => ({
            id: program.id,
            imageURL: program.imageCard,
            onSelectCard: () => {
              navigation.navigate('Program', { id: program.id });
            },
          }))
        );
      } catch (err) {
        Crashlytics.handleException(err, 'Search page filters');
      } finally {
        setFirstRender(false);
        setLoading(false);
      }
    };

    const timer = setTimeout(() => getPrograms(), 500);

    return () => clearTimeout(timer);
  }, [
    searchText,
    selectedObjectives,
    selectedDifficulties,
    navigation,
    findProgram,
    findInstructor,
  ]);

  const handleSearchInput = (event: any) => {
    if (Platform.OS === 'web') {
      setSearchText(event?.target?.value);
    } else {
      setSearchText(event);
    }
  };

  const onSelectObjective = (id: number) => {
    const newSet = new Set(selectedObjectives);
    newSet.has(id) ? newSet.delete(id) : newSet.add(id);
    setSelectedObjectives(newSet);
  };

  const onSelectDifficulty = (id: number) => {
    const newSet = new Set(selectedDifficulties);
    newSet.has(id) ? newSet.delete(id) : newSet.add(id);
    setSelectedDifficulties(newSet);
  };

  const handleOptionChange = (id: number, type: OptionType) => {
    if (type === 'objetive') {
      onSelectObjective(id);
    } else {
      onSelectDifficulty(id);
    }
  };
  return (
    <ScreenBackground>
      <ContentRow>
        <Sidebar>
          <SearchFilter
            searchText={searchText}
            handleSearchInput={handleSearchInput}
            handleOptionChange={handleOptionChange}
            selectedOptions={{ selectedObjectives, selectedDifficulties }}
          />
        </Sidebar>

        <ScrollArea ref={scrollRef as any} {...scrollProps}>
          <LoadingIndicator loading={loading} />

          <GridCardList
            name={`${t('search.results', 'Resultados da busca')} (${
              programs.length
            })`}
            cards={programs}
            onBecameFocused={handleElementFocus}
            columns={3}
            customCardWidth={CUSTOM_CARD_WIDTH}
          />

          {!firstRender && programs.length === 0 && (
            <EmptyListContainer>
              <EmptyListText>
                {t(
                  'search.no_result',
                  'Não encontramos essa combinação de aulas pra você.'
                )}
              </EmptyListText>
            </EmptyListContainer>
          )}
        </ScrollArea>
      </ContentRow>
    </ScreenBackground>
  );
};

export const Search = withFocusable()(SearchComponent);
