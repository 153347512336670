import React, { createContext, useContext, useState, useCallback } from 'react';

import type { ISideMenuContext } from './SideMenuContext.types';

const SideMenuContext = createContext({} as ISideMenuContext);

export const useSideMenu = () => {
  const context = useContext(SideMenuContext);

  if (!context?.enableSideMenuFocus) {
    throw Error('useSideMenu hook should only be within SideMenuNavigator');
  }

  return context;
};

export const SideMenuProvider: React.FC = ({ children }) => {
  const [isSideMenuEnabled, setIsSideMenuEnabled] = useState(false);

  const enableSideMenuFocus = useCallback(() => {
    setIsSideMenuEnabled(true);
  }, []);

  const disableSideMenuFocus = useCallback(() => {
    setIsSideMenuEnabled(false);
  }, []);

  return (
    <SideMenuContext.Provider
      value={{
        isSideMenuEnabled,
        enableSideMenuFocus,
        disableSideMenuFocus,
      }}
    >
      {children}
    </SideMenuContext.Provider>
  );
};
