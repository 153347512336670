import React, { useEffect, useState, useCallback } from 'react';
import {
  FocusableElement,
  withFocusable,
} from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { ActivityIndicator } from 'react-native';

import { verticalScale } from '~/utils/layout';
import { FloatingLogo } from '~/components/FloatingLogo/FloatingLogo';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { TvOffIcon } from '~/assets/icons/TvOffIcon';
import { ActionButton } from '~/components/ActionButton/ActionButton';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import type { RootStackNavigationProp } from '~/routes/routes.types';
import { fetchUserDevicesConnected } from '~/services/user/userApi';
import type { IDevice } from '~/utils/systemInfo';

import {
  Content,
  Box,
  IconContent,
  Title,
  Description,
  ProgramsList,
  ProgramsItem,
  Buttons,
  ProgramsItemDevice,
} from './DeviceLimit.styles';

const ICON_SIZE = verticalScale(48);
const LOADING_SIZE = verticalScale(50);
const FOCUS_KEY = 'device-limit-preferred-focus';

const DeviceLimitComponent = (props: FocusableElement) => {
  const { setFocus, stealFocus, updateAllSpatialLayouts } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const { t } = useTranslation();
  const { colors } = useTheme();
  const navigation = useNavigation<RootStackNavigationProp<'DeviceLimit'>>();
  const [devices, setDevices] = useState<IDevice[]>();
  const [loading, setLoading] = useState(true);

  const fetchDevicesData = useCallback(async () => {
    const devicesList = await fetchUserDevicesConnected('watching');
    setDevices(devicesList.otherDevices);
    setLoading(false);
  }, []);

  const getNameByDeviceType = (device: IDevice) => {
    switch (device.type) {
      case 'web':
        return t('common.web', 'Navegador');
      case 'mobile':
        return `${device.metadata.brand} ${
          device?.metadata?.model ?? device.metadata.os
        }`;
      case 'tv':
        return `${
          device.metadata.brand !== 'undefined'
            ? device.metadata.brand
            : t('common.unknown', 'Desconhecido')
        } ${device?.metadata?.model ?? device.metadata.os}`;
      case 'unknown':
        return t('common.unknown', 'Desconhecido');
    }
  };

  useEffect(() => {
    setFocus(FOCUS_KEY);
    fetchDevicesData();
  }, [fetchDevicesData, setFocus]);

  return (
    <ScreenBackground>
      <FloatingLogo />

      <Content>
        <Box>
          <IconContent>
            <TvOffIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              fill={colors.selectedItem}
            />
          </IconContent>

          <Title>{t('device_limit.title', 'Limite Atingido')}</Title>

          <Description>
            {t(
              'device_limit.description',
              'Existe um ou mais dispositivos conectados à sua assinatura exibindo alguma aula neste momento. Finalize as transmissões ou desconecte os dispositivos na opção "Gerenciar dispositivos".'
            )}
          </Description>

          <Description>
            {t('device_limit.programs_watched', 'Aulas sendo exibidas:')}
          </Description>

          <ProgramsList>
            {loading && (
              <ActivityIndicator
                size={LOADING_SIZE}
                color={colors.spinner.primary}
              />
            )}

            {!loading &&
              devices?.map((item: IDevice) => (
                <ProgramsItem key={item.id}>
                  <ProgramsItemDevice>
                    {`${getNameByDeviceType(item)} - ${item?.media?.name}`}
                  </ProgramsItemDevice>
                </ProgramsItem>
              ))}

            {!loading && devices?.length === 0 && (
              <ProgramsItem>
                <ProgramsItemDevice>
                  {t(
                    'device_limit.no_devices_found',
                    'Nenhum dispositivo encontrado...'
                  )}
                </ProgramsItemDevice>
              </ProgramsItem>
            )}
          </ProgramsList>

          <Buttons>
            <ActionButton
              rightGap
              text={t('common.go_back', 'Voltar')}
              onPress={() => navigation.goBack()}
              onEnterPress={() => navigation.goBack()}
            />

            <ActionButton
              focusKey={FOCUS_KEY}
              hasTVPreferredFocus
              text={t('help.manage_devices', 'Gerenciar dispositivos')}
              onPress={() =>
                navigation.navigate('SideMenuNavigator', { screen: 'Help' })
              }
              onEnterPress={() =>
                navigation.navigate('SideMenuNavigator', { screen: 'Help' })
              }
            />
          </Buttons>
        </Box>
      </Content>
    </ScreenBackground>
  );
};

export const DeviceLimit = withFocusable({ blockNavigationOut: true })(
  DeviceLimitComponent
);
