import React from 'react';
import { Path, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';

export const Logo = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const { width = 100, height = 50, fill = colors.text.default } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 72 72">
      <Path
        fill={fill}
        fillRule="evenodd"
        d="M33.381 0c6.848 0 12.791 1.48 17.832 4.44 5.04 2.96 8.907 7.014 11.602 12.16 2.694 5.15 4.042 10.875 4.042 17.18 0 4.377-.618 8.462-1.855 12.258-1.235 3.797-3.028 7.206-5.373 10.23l5.707 6.37L54.684 72l-6.372-6.755c-4.438 2.058-9.415 3.087-14.93 3.087-6.722 0-12.603-1.495-17.643-4.488-5.04-2.991-8.925-7.06-11.65-12.209C1.362 46.49 0 40.762 0 34.455c0-6.626 1.426-12.56 4.279-17.806 2.853-5.244 6.815-9.329 11.888-12.257C21.239 1.465 26.977 0 33.38 0zm-2.05 10.723c3.085 7.602-.88 19.674-3.916 21.96 0 0-1.786-5.49-.024-10.806-6.634 5.838-7.955 13.91-7.955 18.805 0 7.408 5.918 13.414 13.218 13.414S45.87 48.09 45.87 40.682c0-4.944-.856-8.024-3.94-12.943 0 0 .416 7.577-2.228 11.6 1.322-8.494-1.762-21.462-8.371-28.616z"
      />
    </Svg>
  );
};
