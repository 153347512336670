import React from 'react';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useTheme } from 'styled-components/native';

import { FocusableInput } from '~/components/FocusableInput/FocusableInput';
import { Filter } from '../Filter/Filter';

import { InputLabel, Text, input } from './SearchFilter.styles';

export const SEARCH_INPUT_KEY = 'SEARCH_INPUT';

const OBJECTIVES = [
  {
    id: 1,
    getLabel: (t: TFunction) => t('search.lose_weight', 'Perder Peso'),
  },
  {
    id: 4,
    getLabel: (t: TFunction) => t('search.flexibility', 'Flexibilidade'),
  },
  {
    id: 3,
    getLabel: (t: TFunction) =>
      t('search.gain_lean_mass', 'Ganhar Massa Magra'),
  },
  {
    id: 2,
    getLabel: (t: TFunction) =>
      t('search.lose_localized_fat', 'Perder Gordura Localizada'),
  },
];

const DIFFICULTIES = [
  { id: 1, getLabel: (t: TFunction) => t('search.beginner', 'Iniciante') },
  {
    id: 2,
    getLabel: (t: TFunction) => t('search.intermediate', 'Intermediário'),
  },
  {
    id: 3,
    getLabel: (t: TFunction) => t('search.advanced', 'Avançado'),
  },
];

export type OptionType = 'objetive' | 'difficulty';

interface ISearchFilterProps {
  searchText: string;
  handleSearchInput: (event: any) => void;
  handleOptionChange: (id: number, type: OptionType) => void;
  selectedOptions: {
    selectedObjectives: Set<number>;
    selectedDifficulties: Set<number>;
  };
}

const _SearchFilter = ({
  searchText,
  handleSearchInput,
  handleOptionChange,
  selectedOptions: { selectedDifficulties, selectedObjectives },
}: ISearchFilterProps & FocusableElement) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <>
      <InputLabel>{t('common.search', 'Buscar')}</InputLabel>
      <FocusableInput
        type="text"
        name="search"
        focusKey={SEARCH_INPUT_KEY}
        placeholder={t(
          'search.training_and_trainners',
          'Treinos e treinadores'
        )}
        placeholderTextColor={colors.search.placeholder}
        returnKeyType="search"
        value={searchText}
        onChange={handleSearchInput}
        onChangeText={handleSearchInput}
        focusColor={colors.selectedItem}
        style={input}
        autoFocus
      />

      <Text>{t('search.filter_by', 'Filtrar por')}</Text>

      <Filter
        label={t('search.goal', 'Objetivo')}
        options={OBJECTIVES}
        onSelect={(id) => handleOptionChange(id, 'objetive')}
        selectedOptions={selectedObjectives}
      />

      <Filter
        label={t('search.level', 'Nível')}
        options={DIFFICULTIES}
        onSelect={(id) => handleOptionChange(id, 'difficulty')}
        selectedOptions={selectedDifficulties}
      />
    </>
  );
};

export const SearchFilter = withFocusable()(_SearchFilter);
