import React from 'react';
import { View } from 'react-native';

import { RedText, WhiteText } from './HelperText.styles';
import { IHelperText } from './HelperText.types';

export const HelperText = (props: IHelperText) => {
  const { text, error } = props;

  return (
    <View>
      {error ? <RedText>{text}</RedText> : <WhiteText>{text}</WhiteText>}
    </View>
  );
};
