import { useRef, useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { Platform } from 'react-native';

export interface IUseSpatialScreenFocusProps {
  stealFocus: () => void;
  updateAllSpatialLayouts: () => void;
}

/**
 * [Web only] Handle screen focus after moving back from another screen
 */
export const useSpatialScreenFocus = (props: IUseSpatialScreenFocusProps) => {
  const { stealFocus, updateAllSpatialLayouts } = props;
  const firstScreenFocus = useRef(true);

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS === 'web' && !firstScreenFocus.current) {
        updateAllSpatialLayouts();
        stealFocus();
      }

      return () => {
        firstScreenFocus.current = false;
      };
    }, [stealFocus, updateAllSpatialLayouts])
  );
};
