import { createGlobalStyle } from 'styled-components';

import GraphikRegular from '~/assets/fonts/graphik/GraphikRegular.otf';
import GraphikMedium from '~/assets/fonts/graphik/GraphikMedium.otf';
import GraphikBold from '~/assets/fonts/graphik/GraphikBold.otf';

export default createGlobalStyle`
    @font-face {
        font-family: 'GraphikRegular';
        src: local('GraphikRegular'), local('GraphikRegular'),
        url(${GraphikRegular}) format('truetype'),
        url(${GraphikRegular}) format('opentype');        
    }
    @font-face {
        font-family: 'GraphikMedium';
        src: local('GraphikMedium'), local('GraphikMedium'),
        url(${GraphikMedium}) format('truetype'),
        url(${GraphikMedium}) format('opentype');        
    }
    @font-face {
        font-family: 'GraphikBold';
        src: local('GraphikBold'), local('GraphikBold'),
        url(${GraphikBold}) format('truetype'),
        url(${GraphikBold}) format('opentype');        
    }    
`;
