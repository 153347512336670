import { useRef } from 'react';
import { Platform } from 'react-native';
import { FocusableElement } from '@noriginmedia/react-spatial-navigation';

import { useSetFocusDirection } from '~/hooks/useSetFocusDirection';
import type { FocusableButtonRef } from '~/components/FocusableButton/FocusableButton.types';
import { LoginFocusKeys } from '../Login.types';

import type { LoginStage } from './useLogin';

export interface IUseLoginFocusPropsProps {
  setFocus: FocusableElement['setFocus'];
  currentStage: LoginStage;
}

export const useLoginFocusProps = (props: IUseLoginFocusPropsProps) => {
  const { setFocus, currentStage } = props;

  const memorizeCheckboxRef = useRef<FocusableButtonRef>(null);
  const accessByPhoneRef = useRef<FocusableButtonRef>(null);
  const forgotPasswordRef = useRef<FocusableButtonRef>(null);
  const passwordToggleRef = useRef<FocusableButtonRef>(null);

  const memorizeCheckbox =
    Platform.OS === 'web' ? LoginFocusKeys.Memorize : memorizeCheckboxRef;
  const accessByPhone =
    Platform.OS === 'web' ? LoginFocusKeys.AcessByPhone : accessByPhoneRef;
  const forgotPassword =
    Platform.OS === 'web' ? LoginFocusKeys.ForgotPassword : forgotPasswordRef;
  const passwordToggle =
    Platform.OS === 'web' ? LoginFocusKeys.PasswordToggle : passwordToggleRef;

  const memorizeCheckboxProps = useSetFocusDirection(
    memorizeCheckbox,
    setFocus,
    {
      nextFocusUp: currentStage === 'email' ? memorizeCheckbox : undefined,
      nextFocusRight: currentStage === 'email' ? memorizeCheckbox : undefined,
    }
  );

  const passwordToggleProps = useSetFocusDirection(passwordToggle, setFocus, {
    nextFocusUp: currentStage === 'password' ? passwordToggle : undefined,
    nextFocusDown: currentStage === 'password' ? forgotPassword : undefined,
    nextFocusLeft: currentStage === 'password' ? forgotPassword : undefined,
  });

  const forgotPasswordProps = useSetFocusDirection(forgotPassword, setFocus, {
    nextFocusUp: currentStage === 'password' ? passwordToggle : undefined,
    nextFocusRight: currentStage === 'password' ? passwordToggle : undefined,
  });

  const nextStageProps = useSetFocusDirection(accessByPhone, setFocus, {
    nextFocusUp: currentStage === 'email' ? memorizeCheckbox : forgotPassword,
    nextFocusRight: accessByPhone,
  });

  return {
    memorizeCheckboxProps,
    passwordToggleProps,
    forgotPasswordProps,
    nextStageProps,
  };
};
