import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';

import type { IAvatarImage } from './Avatar.types';

export const Image = styled.ImageBackground.attrs<IAvatarImage>(({ size }) => ({
  imageStyle: {
    borderRadius: verticalScale(size) / 2,
  },
}))<IAvatarImage>`
  height: ${({ size }) => verticalScale(size)}px;
  width: ${({ size }) => verticalScale(size)}px;
  border-radius: ${({ size }) => verticalScale(size / 2)}px;
  background-color: ${({ theme, hasFallbackText }) =>
    hasFallbackText
      ? theme.colors.avatarFallbackBackground
      : theme.colors.itemBackground};
  justify-content: center;
  align-items: center;
`;

export const Fallback = styled.Text<{ size: number }>`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  text-align: center;
  font-size: ${({ size }) => verticalScale(size / 2)}px;
`;
