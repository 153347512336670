import React from 'react';

import { Container } from './LinearGradient.styles.web';

import type { ILinearGradient } from './LinearGradient.types';

export const LinearGradient = (props: ILinearGradient) => {
  const { direction = 'vertical', colors, children, style } = props;

  return (
    <Container
      style={style}
      colors={colors.join(', ')}
      angle={direction === 'vertical' ? 180 : 90}
    >
      {children}
    </Container>
  );
};
