import React, { memo, useMemo } from 'react';

import { KeyboardTemplate } from '../KeyboardTemplate/KeyboardTemplate';

import { generateSpecialKeys } from './generateSpecialKeys';

import type { IKeyboardLayout } from '../../Keyboard.types';

const SpecialLayoutComponent = (props: IKeyboardLayout) => {
  const template = useMemo(() => generateSpecialKeys(), []);

  return <KeyboardTemplate {...props} template={template} hasGap />;
};

export const SpecialLayout = memo(SpecialLayoutComponent);
