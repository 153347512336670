import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';

export const Content = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const BaseText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
`;

export const Title = styled(BaseText)`
  font-size: ${verticalScale(36)}px;
  margin-bottom: ${verticalScale(48)}px;
`;

export const Instructions = styled(BaseText)`
  font-size: ${verticalScale(24)}px;
  margin-bottom: ${verticalScale(24)}px;
`;

export const Email = styled(BaseText)`
  font-size: ${verticalScale(36)}px;
  margin-bottom: ${verticalScale(48)}px;
`;

export const ButtonContainer = styled.View`
  width: ${verticalScale(250)}px;
`;
