import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';

export const MinusIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const { width = 21, height = 21, fill = colors.text.default } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 21 21">
      <Path fill={fill} fillRule="evenodd" d="M21 12H0V9H21V12Z" />
    </Svg>
  );
};
