const totalTime = (range: any) => Math.abs(range.end - range.start);

const getTotalTime = (ranges: any) =>
  ranges.reduce((acc: any, range: any) => acc + totalTime(range), 0);

export const getVideoWatchedRangesAndTime = (playerRef: any) => {
  const watchedRanges: { start: number; end: number }[] = [];
  let watchedRangesTime = 0;

  if (!playerRef) {
    return {
      watchedRanges,
      watchedRangesTime,
    };
  }

  const htmlVideoPlayedRanges = playerRef.played();

  for (let i = 0; i < htmlVideoPlayedRanges.length; i++) {
    const watchedRange = {
      start: Math.floor(htmlVideoPlayedRanges.start(i) || 0),
      end: Math.floor(htmlVideoPlayedRanges.end(i) || 0),
    };
    watchedRanges.push(watchedRange);
    watchedRangesTime = getTotalTime(watchedRanges);
  }
  return { ranges: watchedRanges, time: watchedRangesTime };
};
