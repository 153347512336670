import styled from 'styled-components/native';

import { FocusableButton } from '~/components/FocusableButton/FocusableButton';
import { verticalScale } from '~/utils/layout';
import {
  HORIZONTAL_SAFE_ZONE,
  VERTICAL_SAFE_ZONE,
  SPACING_FROM_SIDE_MENU,
  FLOATING_LOGO_HEIGHT,
} from '~/utils/constants';

export const Content = styled.View`
  padding: ${FLOATING_LOGO_HEIGHT}px ${HORIZONTAL_SAFE_ZONE}px
    ${VERTICAL_SAFE_ZONE}px ${SPACING_FROM_SIDE_MENU}px;
  flex: 1;
`;

export const HelpTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(64)}px;
  font-weight: bold;
  margin-bottom: ${VERTICAL_SAFE_ZONE}px;
`;

export const Column = styled.View`
  flex-direction: row;
`;

export const ColumnLeft = styled.View`
  flex: 2;
`;

export const ColumnRight = styled.View`
  flex: 3;
  margin-left: ${verticalScale(150)}px;
`;

export const StyledFocusableButton = styled(FocusableButton).attrs(
  ({ theme: { colors } }) => ({
    bgColor: colors.itemBackground,
    borderColor: colors.text.default,
    textStyle: { fontSize: verticalScale(32), fontWeight: '500' },
  })
)`
  border-width: ${verticalScale(2)}px;
  border-radius: ${verticalScale(4)}px;
  margin-bottom: ${verticalScale(16)}px;
  padding: ${verticalScale(16)}px 0;
`;
