import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { appVersion } from '~/configs/env';
import { getDeviceInfo, getIp } from '~/utils/systemInfo';
import type { IDeviceInfo, IIpAdress } from '~/utils/systemInfo';

import {
  ContentTitle,
  ContentBody,
  QRCodeCard,
  StyledQRCode,
} from './ContentData.styles';

export const CustomerSupport = () => {
  const { t } = useTranslation();

  return (
    <>
      <ContentTitle>
        {t('help.customer_support', 'Suporte ao cliente')}
      </ContentTitle>
      <ContentBody>
        {t('help.whatsapp', 'WhatsApp: ')}
        <ContentBody bold>
          {t('help.customer_support_phone_number', '(31) 98222-5779')}
        </ContentBody>
        {t('help.or_by_email', ' ou pelo e-mail')}
      </ContentBody>
      <ContentBody bold>
        {t('help.customer_support_email', 'suporte@queimadiaria.com.br')}
      </ContentBody>
      <ContentBody>
        {t(
          'help.speed_up_customer_support',
          'Para agilizar o atendimento, já informe o seu e-mail de cadastro e CPF utilizados durante a inscrição.'
        )}
      </ContentBody>
      <ContentBody>
        {t(
          'help.office_hours_details',
          'Nosso horário de atendimento é de segunda à sexta (exceto feriados), de 09h às 18h e as mensagens são respondidas em ordem de chegada. O tempo de resposta é de até 1 dia útil.'
        )}
      </ContentBody>
    </>
  );
};

export const AccessValidity = () => {
  const { t } = useTranslation();

  return (
    <>
      <ContentTitle>
        {t('help.access_validity', 'Validade do acesso')}
      </ContentTitle>
      <ContentBody>
        {t(
          'help.access_validity_details',
          'O acesso a todo conteúdo da plataforma Queima Diária tem validade de 1 ano, a contar da data da compra. Esse acesso é renovado anualmente de maneira automática, caso não opte pelo cancelamento da renovação automática da assinatura.'
        )}
      </ContentBody>
    </>
  );
};

export const CancelSubscription = () => {
  const { t } = useTranslation();

  return (
    <>
      <ContentTitle>
        {t('help.cancel_subscription', 'Cancelar assinatura')}
      </ContentTitle>
      <ContentBody>
        {t(
          'help.cancel_subscription_deadline',
          'A Queima Diária estipula para todos os programas (com exceção dos produtos com período de teste grátis - trial) o prazo de até 30 dias, a contar da data da compra, para que seja realizado o cancelamento da assinatura ou da renovação automática, que acontece após um ano da inscrição.'
        )}
      </ContentBody>
      <ContentBody>
        {t(
          'help.cancel_subscription_action',
          'Para cancelar, basta enviar, dentro do prazo, um e-mail para suporte@queimadiaria.com.br informando o seu e-mail de cadastro e CPF utilizados durante a inscrição e aguardar pelo retorno da nossa equipe com as orientações necessárias para concluir esse processo.'
        )}
      </ContentBody>
    </>
  );
};

export const ManageDevices = () => {
  const { t } = useTranslation();

  return (
    <>
      <ContentTitle>
        {t('help.manage_devices', 'Gerenciar Dispositivos')}
      </ContentTitle>
      <ContentBody spaceBottom>
        {t(
          'help.manage_devices_description',
          'Acessando a plataforma, você terá acesso a lista de dispositivos que já acessaram a sua assinatura, o local e o tipo de dispositivo (celular ou computador, por exemplo). Além disso, você pode desconectar os dispositivos que considerar desnecessários.'
        )}
      </ContentBody>
      <ContentBody>
        {t(
          'help.manage_devices_link_title',
          'Acesse o endereço no seu dispositivo'
        )}
      </ContentBody>
      <ContentBody font40 spaceBottom bold>
        {t(
          'help.manage_devices_link',
          'play.queimadiaria.com/account-management/devices'
        )}
      </ContentBody>
      <ContentBody spaceBottom>
        {t(
          'help.manage_devices_qrcode_1',
          'Ou e escaneie o Código QR para acessar a plataforma:'
        )}
      </ContentBody>
      <QRCodeCard>
        <StyledQRCode
          value={t(
            'help.manage_devices_qrcode_url',
            'play.queimadiaria.com/account-management/devices'
          )}
        />
      </QRCodeCard>
    </>
  );
};

export const CalorieExpenditure = () => {
  const { t } = useTranslation();

  return (
    <>
      <ContentTitle>
        {t('help.calorie_expenditure_per_workout', 'Gasto calórico por treino')}
      </ContentTitle>
      <ContentBody>
        {t(
          'help.how_programs_work',
          'Cada programa é específico para um determinado objetivo. O número de calorias depende do programa escolhido, sua intensidade na aula e diversos outros fatores, porém, a média gira em torno de 300 calorias por aula.'
        )}
      </ContentBody>
      <ContentBody>
        {t(
          'help.programs_benefits',
          'Mais do que perder calorias, você estará trabalhando todo o seu corpo e obtendo benefícios intermináveis. É pra fazer qualquer um suar a camisa e alcançar os resultados dos sonhos!'
        )}
      </ContentBody>
      <ContentBody>
        {t(
          'help.how_programs_work_reminder',
          'Lembrando que você tem acesso a todos os programas de exercícios da plataforma e, caso já tenha condicionamento físico para fazer mais de um programa, você pode! Mas, precisa seguir as orientações certinhas de cada um dos programas, hein? Essas orientações ficam dentro da aba "Como funciona" que te expliquei acima.'
        )}
      </ContentBody>
    </>
  );
};

export const PlatformAccess = () => {
  const { t } = useTranslation();

  return (
    <>
      <ContentTitle>
        {t('help.how_to_access', 'Como acessar a plataforma')}
      </ContentTitle>
      <ContentBody>
        {t(
          'help.available_platforms',
          'Além do nosso aplicativo para Smart TV, você tem acesso à plataforma web (play.queimadiaria.com) e ao nosso aplicativo para iOS ou Android (Procure por "Queima Diária" nas lojas).'
        )}
      </ContentBody>
      <ContentBody>
        {t(
          'help.important_tips',
          'Algumas dicas importantes: dentro de cada programa, na aba "Como funciona", é explicado sobre os principais objetivos de cada treino. Aconselhamos dar uma olhadinha no programa que mais se encaixa com seus objetivos. Ao selecionar o programa desejado, também verifique nesse mesmo lugar as demais orientações (como os dias de descanso recomendados, duração do programa, nível, objetivo principal, como treinar e etc.).'
        )}
      </ContentBody>
      <ContentBody>
        {t(
          'help.programs_bonus_tab',
          'Existe também a aba Bônus em cada programa, onde você vai encontrar várias informações legais para conciliar com os treinos!'
        )}
      </ContentBody>
    </>
  );
};

export const AppVersion = () => {
  const { t } = useTranslation();
  const [deviceInfo, setDeviceInfo] = useState<IDeviceInfo | null>(null);
  const [ipAdress, setIPAdress] = useState<IIpAdress | null>(null);

  useEffect(() => {
    getDeviceInfo().then(setDeviceInfo);
    getIp().then(setIPAdress);
  }, []);

  return (
    <>
      <ContentTitle>
        {t('help.app_version', 'Versão do aplicativo')}
      </ContentTitle>
      <ContentBody>
        {t('help.app_version_description1', 'Você está utilizando a versão')}
        {` ${appVersion} `}
        {t('help.app_version_description2', 'do aplicativo Queima Diária TV')}
      </ContentBody>
      {deviceInfo && (
        <ContentTitle spaceTop>
          {t('help.app_info', 'Informações do Dispositivo')}
        </ContentTitle>
      )}
      {deviceInfo?.brand && (
        <ContentBody>
          {t('help.app_info_brand', 'Fabricante:')} {deviceInfo?.brand}
        </ContentBody>
      )}
      {deviceInfo?.deviceOS && deviceInfo?.platformVersion && (
        <ContentBody>
          {t('help.app_info_os', 'Sistema Operacional:')} {deviceInfo?.deviceOS}{' '}
          {deviceInfo?.platformVersion}
        </ContentBody>
      )}
      {deviceInfo?.modelName && (
        <ContentBody>
          {t('help.app_info_model', 'Modelo do dispositivo:')}{' '}
          {deviceInfo?.modelName}
        </ContentBody>
      )}
      {deviceInfo?.navigator && (
        <ContentBody>
          {t('help.app_info_navigator', 'Navegador:')} {deviceInfo?.navigator}
        </ContentBody>
      )}
      {ipAdress?.ip && (
        <ContentBody>
          {t('help.app_info_ip', 'IP/Provedor:')}{' '}
          {`${ipAdress?.ip} | ${ipAdress.region} | ${ipAdress.city} | ${ipAdress.country} | ${ipAdress.org}`}
        </ContentBody>
      )}
    </>
  );
};
