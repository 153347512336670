import styled from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';

export const Container = styled.View`
  align-items: center;
`;

export const Content = styled.View`
  height: 100%;
  width: ${horizontalScale(760)}px;
  align-items: center;
`;

export const HeaderText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(64)}px;
  font-weight: bold;
  margin-bottom: ${verticalScale(21)}px;
`;

export const TitleDescription = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(42)}px;
  font-weight: bold;
  margin-bottom: ${verticalScale(32)}px;
`;

export const TextDescription = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(27)}px;
  margin-bottom: ${verticalScale(54)}px;
  text-align: center;
`;

export const ContentAvatar = styled.View`
  margin-bottom: ${verticalScale(54)}px;
`;
