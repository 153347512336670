import React from 'react';

import { Container, LabelBackground, Label, StepText } from './Stage.styles';

import type { IStage } from './Stage.types';

export const Stage = (props: IStage) => {
  const { variant = 'default', number, children, style } = props;

  return (
    <Container style={style}>
      <LabelBackground variant={variant}>
        <Label variant={variant}>{number}</Label>
      </LabelBackground>

      <StepText>{children}</StepText>
    </Container>
  );
};
