import { useRef } from 'react';
import { Platform, ScrollView, findNodeHandle, UIManager } from 'react-native';

import { verticalScale } from '~/utils/layout';
import { FB_BORDER_WIDTH } from '~/components/FocusableButton/FocusableButton.styles';
import { AVATAR_SEPARATOR_SIZE } from '../AvatarList.styles';

import type { OnScrollEvent, IHandleAvatarFocus } from './useAvatarList.types';

const AVATAR_SIZE = verticalScale(170);

const calculateOffsetX = (avatarIndex: number): number | null => {
  const avatarViewBox =
    AVATAR_SIZE + AVATAR_SEPARATOR_SIZE + FB_BORDER_WIDTH * 2;

  if (avatarIndex % 8 === 7) {
    const offsetX = avatarViewBox * (avatarIndex - 7);
    return offsetX;
  }

  if (avatarIndex > 0 && avatarIndex % 8 === 0) {
    const offsetX = avatarViewBox * avatarIndex;
    return offsetX;
  }

  return null;
};

export const useAvatarList = () => {
  const scrollRef = useRef<ScrollView>(null);
  const scrollPositionRef = useRef<number>(0);

  const onScroll = ({ nativeEvent }: OnScrollEvent) => {
    scrollPositionRef.current = nativeEvent.contentOffset.x;
  };

  const handleAvatarFocus = (props: IHandleAvatarFocus) => {
    const { element, avatarIndex } = props;

    if (Platform.OS === 'web') {
      const offsetX = calculateOffsetX(avatarIndex);

      if (offsetX !== null && scrollPositionRef.current !== offsetX) {
        scrollRef.current?.scrollTo({
          animated: true,
          y: 0,
          x: offsetX,
        });
      }

      return;
    }

    const elementNode = findNodeHandle(element.node as any);
    const scrollNode = findNodeHandle(scrollRef.current);

    if (elementNode === null || scrollNode === null) {
      return;
    }

    UIManager.measureLayout(
      elementNode,
      scrollNode,
      () => {},
      () => {
        const offsetX = calculateOffsetX(avatarIndex);

        if (offsetX !== null && scrollPositionRef.current !== offsetX) {
          scrollRef.current?.scrollTo({
            animated: true,
            y: 0,
            x: offsetX,
          });
        }
      }
    );
  };

  return {
    handleAvatarFocus,
    getScrollViewProps: () => ({
      ref: scrollRef as any,
      onScroll,
      scrollEventThrottle: 16,
      horizontal: true,
      showsHorizontalScrollIndicator: false,
      scrollEnabled: false,
    }),
  };
};
