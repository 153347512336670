import React, { forwardRef, CSSProperties } from 'react';
import { View, ViewStyle } from 'react-native';
import styled from 'styled-components';

import { omitProps } from '~/utils/functions';

import type { IButtonWrapper } from './ButtonWrapper.types';

const transformStyle = (style: ViewStyle) => {
  const buttonStyle: CSSProperties = {
    position: style?.position,
    top: style?.top,
    right: style?.right,
    bottom: style?.bottom,
    left: style?.left,
    height: style?.height,
    width: style?.width,
    margin: style?.margin,
    marginTop: style?.marginVertical || style?.marginTop,
    marginBottom: style?.marginVertical || style?.marginBottom,
    marginRight: style?.marginHorizontal || style?.marginRight,
    marginLeft: style?.marginHorizontal || style.marginLeft,
    flex: style?.flex,
    flexGrow: style?.flexGrow,
    flexShrink: style?.flexShrink,
    flexBasis: style?.flexBasis,
    alignSelf: style?.alignSelf,
  };

  const contentStyle: ViewStyle = omitProps({ ...style, height: '100%' }, [
    'marginVertical',
    'marginHorizontal',
    'marginTop',
    'marginBottom',
    'marginRight',
    'marginLeft',
    'position',
    'top',
    'right',
    'bottom',
    'left',
    'flex',
    'flexGrow',
    'flexShrink',
    'flexBasis',
    'alignSelf',
    'width',
  ]);

  return { buttonStyle, contentStyle };
};

const HTMLButton = styled.button`
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background: transparent;
  border: none;
`;

export const ButtonWrapper = forwardRef<HTMLButtonElement, IButtonWrapper>(
  ({ children, style, onPress, setFocus }, ref) => {
    const { buttonStyle, contentStyle } = transformStyle(style);

    return (
      <HTMLButton
        ref={ref}
        onClick={onPress}
        onMouseEnter={() => setFocus()}
        style={buttonStyle}
      >
        <View style={contentStyle}>{children}</View>
      </HTMLButton>
    );
  }
);
