import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';

export const ClosedCaptionsIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const { width = 38, height = 38, fill = colors.text.default } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 38 38">
      <Path
        d="M30.0833 38H7.91667C5.81704 38 3.8034 37.1659 2.31874 35.6813C0.834075 34.1966 0 32.183 0 30.0833V7.91667C0 5.81704 0.834075 3.8034 2.31874 2.31874C3.8034 0.834075 5.81704 0 7.91667 0H30.0833C32.183 0 34.1966 0.834075 35.6813 2.31874C37.1659 3.8034 38 5.81704 38 7.91667V30.0833C38 32.183 37.1659 34.1966 35.6813 35.6813C34.1966 37.1659 32.183 38 30.0833 38ZM7.91667 3.16667C6.65689 3.16667 5.44871 3.66711 4.55791 4.55791C3.66711 5.44871 3.16667 6.65689 3.16667 7.91667V30.0833C3.16667 31.3431 3.66711 32.5513 4.55791 33.4421C5.44871 34.3329 6.65689 34.8333 7.91667 34.8333H30.0833C31.3431 34.8333 32.5513 34.3329 33.4421 33.4421C34.3329 32.5513 34.8333 31.3431 34.8333 30.0833V7.91667C34.8333 6.65689 34.3329 5.44871 33.4421 4.55791C32.5513 3.66711 31.3431 3.16667 30.0833 3.16667H7.91667Z"
        fill={fill}
      />
      <Path
        d="M20.5833 31.6667H7.91659C7.49666 31.6667 7.09393 31.4999 6.797 31.2029C6.50007 30.906 6.33325 30.5033 6.33325 30.0833C6.33325 29.6634 6.50007 29.2607 6.797 28.9637C7.09393 28.6668 7.49666 28.5 7.91659 28.5H20.5833C21.0032 28.5 21.4059 28.6668 21.7028 28.9637C21.9998 29.2607 22.1666 29.6634 22.1666 30.0833C22.1666 30.5033 21.9998 30.906 21.7028 31.2029C21.4059 31.4999 21.0032 31.6667 20.5833 31.6667Z"
        fill={fill}
      />
      <Path
        d="M30.0833 31.6667H26.9166C26.4967 31.6667 26.0939 31.4999 25.797 31.2029C25.5001 30.906 25.3333 30.5033 25.3333 30.0833C25.3333 29.6634 25.5001 29.2607 25.797 28.9637C26.0939 28.6668 26.4967 28.5 26.9166 28.5H30.0833C30.5032 28.5 30.9059 28.6668 31.2028 28.9637C31.4998 29.2607 31.6666 29.6634 31.6666 30.0833C31.6666 30.5033 31.4998 30.906 31.2028 31.2029C30.9059 31.4999 30.5032 31.6667 30.0833 31.6667Z"
        fill={fill}
      />
      <Path
        d="M30.0833 25.3334H17.4166C16.9967 25.3334 16.5939 25.1666 16.297 24.8697C16.0001 24.5727 15.8333 24.17 15.8333 23.7501C15.8333 23.3302 16.0001 22.9274 16.297 22.6305C16.5939 22.3336 16.9967 22.1667 17.4166 22.1667H30.0833C30.5032 22.1667 30.9059 22.3336 31.2028 22.6305C31.4998 22.9274 31.6666 23.3302 31.6666 23.7501C31.6666 24.17 31.4998 24.5727 31.2028 24.8697C30.9059 25.1666 30.5032 25.3334 30.0833 25.3334Z"
        fill={fill}
      />
      <Path
        d="M11.0833 25.3334H7.91659C7.49666 25.3334 7.09393 25.1666 6.797 24.8697C6.50007 24.5727 6.33325 24.17 6.33325 23.7501C6.33325 23.3302 6.50007 22.9274 6.797 22.6305C7.09393 22.3336 7.49666 22.1667 7.91659 22.1667H11.0833C11.5032 22.1667 11.9059 22.3336 12.2028 22.6305C12.4998 22.9274 12.6666 23.3302 12.6666 23.7501C12.6666 24.17 12.4998 24.5727 12.2028 24.8697C11.9059 25.1666 11.5032 25.3334 11.0833 25.3334Z"
        fill={fill}
      />
    </Svg>
  );
};
