import React from 'react';
import { Platform } from 'react-native';
import { enableScreens } from 'react-native-screens';
import '@procot/webostv/webOSTV';

import { AppProviders } from './contexts/AppProviders';
import { NetworkStatus } from './components/NetworkStatus/NetworkStatus';
import GlobalStyle from './components/GlobalStyle/GlobalStyle';
import { Routes } from './routes/routes';

if (Platform.OS !== 'web') {
  enableScreens();
}

export const App = () => {
  return (
    <AppProviders>
      <NetworkStatus>
        <GlobalStyle />
        <Routes />
      </NetworkStatus>
    </AppProviders>
  );
};
