import { Platform } from 'react-native';

import { baseTrackUrl, appVersion } from '~/configs/env';
import { api } from '~/configs/api';

import type {
  ILogin,
  ILoginResponse,
  IMetadata,
  ITrackLogin,
  IForgotPassword,
} from './authApi.types';

export const login = async (payload: ILogin) => {
  const { data } = await api.post<ILoginResponse>(
    '/users/customers/login',
    payload
  );

  return data;
};

export const trackLogin = async ({
  userId,
  sessionId,
  method,
}: ITrackLogin) => {
  const metadata: IMetadata = {
    device: Platform.isTV ? 'tv' : 'browser',
    platform: 'app-tv-' + Platform.OS,
    platformVersion: Platform.Version,
    appVersion,
    method,
    userAgent: navigator.userAgent,
  };
  const payload = {
    uid: userId,
    sid: sessionId,
    name: 'user_session',
    metric: 1,
    metadata: JSON.stringify(metadata),
    time: new Date().toUTCString(),
  };
  const { data } = await api.post(baseTrackUrl, payload, {
    headers: { 'content-type': 'text/plain' },
  });
  return data;
};

export async function forgotPassword(payload: IForgotPassword) {
  const { data } = await api.post<unknown>(
    '/users/customers/password-forget',
    payload
  );
  return data;
}
