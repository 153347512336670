import { useRef, useCallback } from 'react';

export interface IUseHandleBufferingProps {
  loadingFunction: (loading: boolean) => void;
}

export const useHandleBuffering = (
  loadingFunction: (loading: boolean) => void
) => {
  const waitingTimeout = useRef<NodeJS.Timeout | null>(null);

  const onBufferStart = useCallback(() => {
    waitingTimeout.current = setTimeout(() => {
      loadingFunction(true);
    }, 300);
  }, [loadingFunction]);

  const onBufferEnd = useCallback(() => {
    if (waitingTimeout.current) {
      clearTimeout(waitingTimeout.current);
    }
    loadingFunction(false);
  }, [loadingFunction]);

  return { onBufferStart, onBufferEnd };
};
