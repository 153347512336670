import styled from 'styled-components/native';

import { verticalScale, horizontalScale } from '~/utils/layout';
import {
  VERTICAL_SAFE_ZONE,
  HORIZONTAL_SAFE_ZONE,
  SPACING_FROM_SIDE_MENU,
} from '~/utils/constants';

export const ContentRow = styled.View`
  flex: 1;
  flex-direction: row;
`;

export const Sidebar = styled.View`
  width: 31.5%;
  padding: ${VERTICAL_SAFE_ZONE}px ${SPACING_FROM_SIDE_MENU}px;
  background-color: ${({ theme }) => theme.colors.search.sidebarBackground};
`;

export const ScrollArea = styled.ScrollView.attrs({
  contentContainerStyle: {
    paddingVertical: VERTICAL_SAFE_ZONE,
    paddingLeft: horizontalScale(48),
    paddingRight: HORIZONTAL_SAFE_ZONE,
    flexGrow: 1,
  },
})`
  width: 68.5%;
`;

export const EmptyListContainer = styled.View`
  margin-top: ${verticalScale(300)}px;
  justify-content: center;
  align-items: center;
`;

export const EmptyListText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(32)}px;
  margin: ${verticalScale(20)}px 0 ${verticalScale(32)}px;
`;
