import React from 'react';
import { FaBatteryQuarter, FaBatteryHalf, FaBatteryFull } from 'react-icons/fa';
import { useTheme } from 'styled-components/native';

import { BatteryIconProps } from './Battery.types';

export const BatteryIcon = (props: BatteryIconProps) => {
  const { colors } = useTheme();
  const { level, color = colors.text.default, size = 25 } = props;

  const batteriesIcons = [FaBatteryQuarter, FaBatteryHalf, FaBatteryFull];
  const Icon = batteriesIcons[level - 1];

  return <Icon color={color} size={size * 2} />;
};
