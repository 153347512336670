import { useRef } from 'react';
import { FocusableElement } from '@noriginmedia/react-spatial-navigation';

import { useSetFocusDirection } from '~/hooks/useSetFocusDirection';
import type { FocusableButtonRef } from '~/components/FocusableButton/FocusableButton.types';

import type { SideMenuItemsMap } from '../SideMenu.types';

export interface IUseSideMenuFocusProps {
  setFocus: FocusableElement['setFocus'];
  MENU_ITEMS_MAP: SideMenuItemsMap;
}

export const useSideMenuFocusProps = (props: IUseSideMenuFocusProps) => {
  const { setFocus, MENU_ITEMS_MAP } = props;

  const MAP_KEYS = Object.keys(MENU_ITEMS_MAP) as Array<
    keyof typeof MENU_ITEMS_MAP
  >;
  const FIRST_KEY = MAP_KEYS[0];
  const LAST_KEY = MAP_KEYS[MAP_KEYS.length - 1];

  const FIRST_ITEM = MENU_ITEMS_MAP[FIRST_KEY];
  const LAST_ITEM = MENU_ITEMS_MAP[LAST_KEY];

  const changeUserRef = useRef<FocusableButtonRef>(null);
  const logoutRef = useRef<FocusableButtonRef>(null);

  useSetFocusDirection(changeUserRef, setFocus, {
    nextFocusDown: FIRST_ITEM.innerRef,
  });

  useSetFocusDirection(FIRST_ITEM.innerRef, setFocus, {
    nextFocusUp: changeUserRef,
  });

  useSetFocusDirection(LAST_ITEM.innerRef, setFocus, {
    nextFocusDown: logoutRef,
  });

  useSetFocusDirection(logoutRef, setFocus, {
    nextFocusUp: LAST_ITEM.innerRef,
  });

  return {
    changeUserRef,
    logoutRef,
  };
};
