import styled, { css } from 'styled-components/native';
import { Dimensions, Platform } from 'react-native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { KEYBOARD_WIDTH } from '~/components/Keyboard/Keyboard.styles';

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('screen');

export const Content = styled.View`
  margin: auto 0;
  align-self: center;
  height: ${SCREEN_HEIGHT * 0.5}px;
  width: ${SCREEN_WIDTH * 0.62}px;
  flex-direction: row;
`;

export const LeftColumn = styled.View`
  align-self: stretch;
  width: ${KEYBOARD_WIDTH}px;
`;

// ! [Android] Spatial focus alignment
const TOP = verticalScale(90);
const LEFT = horizontalScale(-50);
export const KeyboardWrapper = styled.View`
  ${() => {
    if (Platform.OS === 'android') {
      return css`
        position: absolute;
        top: ${TOP}px;
        left: ${LEFT}px;
        transform: translateX(${LEFT * -1}px) translateY(${TOP * -1}px);
      `;
    }
  }}
`;

export const StageActions = styled.View`
  flex-direction: row;
`;

export const RightColumn = styled.View`
  flex: 1;
  align-self: stretch;
  margin-left: ${horizontalScale(96)}px;
  justify-content: space-between;
`;

export const Form = styled.View`
  align-items: flex-start;
`;

export const Title = styled.Text`
  font-size: ${verticalScale(36)}px;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
`;

export const FieldWrapper = styled.View`
  align-self: stretch;
  margin-top: ${verticalScale(8)}px;
  margin-bottom: ${verticalScale(16)}px;
`;

export const NavigationArea = styled.View`
  align-self: flex-start;
  margin-top: auto;
`;
