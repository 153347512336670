import React, {
  memo,
  useRef,
  useLayoutEffect,
  useEffect,
  useState,
} from 'react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';

import { isNativeButton } from '../FocusableButton/FocusableButton';

import { AlphanumericLayout } from './components/AlphanumericLayout/AlphanumericLayout';
import { SpecialLayout } from './components/SpecialLayout/SpecialLayout';
import { DiacriticalLayout } from './components/DiacriticalLayout/DiacriticalLayout';
import { DefaultControls } from './components/DefaultControls/DefaultControls';
import { EmailControls } from './components/EmailControls/EmailControls';
import { Container } from './Keyboard.styles';
import { KeyboardLayoutMap } from './Keyboard.types';

import type { FocusableButtonRef } from '../FocusableButton/FocusableButton.types';
import type { IKeyboard } from './Keyboard.types';

export const KeyboardComponent = (props: IKeyboard) => {
  const { type = 'default', text, onChangeText, hasTVPreferredFocus } = props;

  const textRef = useRef(text);
  const firstKeyRef = useRef<FocusableButtonRef>(null);

  const [keyboardLayout, setKeyboardLayout] = useState<KeyboardLayoutMap>(
    KeyboardLayoutMap.Alphanumeric
  );

  useLayoutEffect(() => {
    textRef.current = text;
  }, [text]);

  useLayoutEffect(() => {
    setKeyboardLayout(KeyboardLayoutMap.Alphanumeric);
  }, [type]);

  useEffect(() => {
    if (!firstKeyRef.current) {
      return;
    }

    if (isNativeButton(firstKeyRef.current) && hasTVPreferredFocus) {
      firstKeyRef.current.setNativeProps({
        hasTVPreferredFocus: true,
      });
    }
  }, [hasTVPreferredFocus]);

  return (
    <Container>
      {keyboardLayout === KeyboardLayoutMap.Alphanumeric && (
        <AlphanumericLayout
          textRef={textRef}
          onChangeText={onChangeText}
          firstKeyRef={firstKeyRef}
        />
      )}

      {keyboardLayout === KeyboardLayoutMap.Special && (
        <SpecialLayout textRef={textRef} onChangeText={onChangeText} />
      )}

      {keyboardLayout === KeyboardLayoutMap.Diacritical && (
        <DiacriticalLayout textRef={textRef} onChangeText={onChangeText} />
      )}

      {type === 'email' ? (
        <EmailControls
          textRef={textRef}
          onChangeText={onChangeText}
          keyboardLayout={keyboardLayout}
          updateLayout={setKeyboardLayout}
        />
      ) : (
        <DefaultControls
          textRef={textRef}
          onChangeText={onChangeText}
          keyboardLayout={keyboardLayout}
          updateLayout={setKeyboardLayout}
        />
      )}
    </Container>
  );
};

export const Keyboard = withFocusable()(memo(KeyboardComponent));
