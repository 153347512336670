import styled from 'styled-components/native';

import { verticalScale, horizontalScale } from '~/utils/layout';

export const Container = styled.View`
  z-index: 15;
  position: absolute;
  bottom: ${verticalScale(100)}px;
  left: ${verticalScale(30)}px;
  width: ${horizontalScale(330)}px;
  padding: ${verticalScale(24)}px;
  background-color: ${({ theme }) => theme.colors.popupBackground};
  border-radius: ${verticalScale(8)}px;
  border: ${verticalScale(2)}px solid
    ${({ theme }) => theme.colors.spatialFocus};
`;

export const Title = styled.Text`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  margin-bottom: ${verticalScale(20)}px;
  font-size: ${verticalScale(24)}px;
`;

export const Options = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
