import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';
import { VERTICAL_SAFE_ZONE, HORIZONTAL_SAFE_ZONE } from '~/utils/constants';

export const ScrollArea = styled.ScrollView`
  height: 100%;
`;

export const Content = styled.View`
  padding: ${VERTICAL_SAFE_ZONE}px 0;
`;

export const InstructorContent = styled.View`
  flex-direction: row;
  border-bottom-width: ${verticalScale(1)}px;
  border-color: ${({ theme }) => theme.colors.text.default};
  border-style: solid;
  margin: 0 ${HORIZONTAL_SAFE_ZONE}px;
  padding: ${verticalScale(96)}px 0 ${verticalScale(54)}px;
`;

export const InstructorInfo = styled.View`
  flex: 1;
  margin-left: ${verticalScale(36)}px;
`;

export const InstructorName = styled.Text`
  font-size: ${verticalScale(36)}px;
  color: ${({ theme }) => theme.colors.text.default};
  margin-bottom: ${verticalScale(24)}px;
`;

export const InstructorDescription = styled.Text`
  flex: 1;
  font-size: ${verticalScale(24)}px;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const InstructorPrograms = styled.View`
  margin-top: ${verticalScale(48)}px;
`;
