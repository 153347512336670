import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';
import {
  HORIZONTAL_SAFE_ZONE,
  PROFILE_SCREENS_PADDING,
} from '~/utils/constants';

export const Content = styled.View`
  flex: 1;
  padding-top: ${PROFILE_SCREENS_PADDING}px;
`;

export const Title = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(36)}px;
  padding-left: ${HORIZONTAL_SAFE_ZONE}px;
  margin: ${verticalScale(12)}px 0 ${verticalScale(48)}px;
`;
