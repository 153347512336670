import React, { useEffect } from 'react';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';
import { useRoute, useNavigation } from '@react-navigation/native';

import { Crashlytics } from '~/utils/crashlytics';
import { forgotPassword } from '~/services/auth/authApi';
import { FloatingLogo } from '~/components/FloatingLogo/FloatingLogo';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { ActionButton } from '~/components/ActionButton/ActionButton';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import type {
  RootStackNavigationProp,
  RootStackRouteProp,
} from '~/routes/routes.types';

import {
  Content,
  Title,
  Instructions,
  Email,
  ButtonContainer,
} from './ForgotPassword.styles';

const FOCUS_KEY = 'forgot-password-goback';

const ForgotPasswordComponent = (props: FocusableElement) => {
  const { stealFocus, updateAllSpatialLayouts, setFocus } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const { t } = useTranslation();
  const navigation = useNavigation<RootStackNavigationProp<'ForgotPassword'>>();
  const route = useRoute<RootStackRouteProp<'ForgotPassword'>>();
  const { email } = route.params;

  useEffect(() => {
    setFocus(FOCUS_KEY);
  }, [setFocus]);

  useEffect(() => {
    forgotPassword({ email }).catch((err) => {
      Crashlytics.handleException(err, 'Forgot password');
    });
  }, [email]);

  const handlePress = () => {
    navigation.goBack();
  };

  return (
    <ScreenBackground>
      <FloatingLogo />

      <Content>
        <Title>{t('common.forgot_password', 'Esqueci a senha')}</Title>
        <Instructions>
          {t(
            'forgot_password.instructions_on_email',
            'Enviamos as instruções para você recuperar a sua senha no e-mail:'
          )}
        </Instructions>
        <Email>{email}</Email>

        <ButtonContainer>
          <ActionButton
            text={t('common.go_back', 'Voltar')}
            onPress={handlePress}
            onEnterPress={handlePress}
            hasTVPreferredFocus
            focusKey={FOCUS_KEY}
          />
        </ButtonContainer>
      </Content>
    </ScreenBackground>
  );
};

export const ForgotPassword = withFocusable({ blockNavigationOut: true })(
  ForgotPasswordComponent
);
