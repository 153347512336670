import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';
import { VERTICAL_SAFE_ZONE, BANNER_HEIGHT } from '~/utils/constants';

export const ScrollArea = styled.ScrollView.attrs({
  contentContainerStyle: {
    paddingTop: verticalScale(48),
    paddingBottom: BANNER_HEIGHT,
  },
})`
  height: 100%;
`;

export const BannerWrapper = styled.View`
  background-color: ${({ theme }) => theme.colors.background};
  position: absolute;
  width: 100%;
  z-index: 1;
`;

export const BannerDescription = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(28)}px;
`;

export const FeaturedProgram = styled.View`
  height: ${BANNER_HEIGHT - VERTICAL_SAFE_ZONE}px;
  margin-bottom: ${VERTICAL_SAFE_ZONE / 2}px;
  justify-content: flex-end;
  opacity: 0;
`;
