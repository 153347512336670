import React, { useRef } from 'react';
import {
  Platform,
  StyleSheet,
  TouchableOpacity,
  findNodeHandle,
} from 'react-native';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useTheme } from 'styled-components/native';

import { useMergeRefs } from '~/hooks/useMergedRefs';

import { ButtonWrapper } from './components/ButtonWrapper';
import {
  IconContainer,
  TextButton,
  FB_BORDER_WIDTH,
} from './FocusableButton.styles';

import type {
  IFocusableButton,
  FocusableButtonRef,
} from './FocusableButton.types';

export const isNativeButton = (
  ref: TouchableOpacity | HTMLButtonElement
): ref is TouchableOpacity => {
  return ref && Platform.OS !== 'web';
};

const FocusableButtonComponent = (props: IFocusableButton) => {
  const { colors } = useTheme();

  const {
    text,
    icon,
    textStyle,
    focused,
    style,
    bgColor = 'transparent',
    bgFocusedColor = colors.spatialFocus,
    borderColor = 'transparent',
    focusedBorderColor = colors.text.default,
    textColor = colors.text.default,
    focusedTextColor = colors.text.active,
    children,
    innerRef,
    blockFocusUp,
    blockFocusRight,
    blockFocusDown,
    blockFocusLeft,
    ...rest
  } = props;

  const nodeRef = useRef<FocusableButtonRef>(null);
  const multiRef = useMergeRefs(nodeRef, innerRef);

  const buttonStyle = StyleSheet.create({
    button: {
      borderWidth: FB_BORDER_WIDTH,
      borderStyle: 'solid',
      borderColor: focused ? focusedBorderColor : borderColor,
      backgroundColor: focused ? bgFocusedColor : bgColor,
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      color: focused ? focusedTextColor : textColor,
    },
  });

  const finalButtonStyle = StyleSheet.flatten([buttonStyle.button, style]);
  const finalTextStyle = StyleSheet.flatten([buttonStyle.text, textStyle]);

  return (
    <ButtonWrapper
      ref={multiRef}
      style={finalButtonStyle}
      // @ts-ignore
      nextFocusUp={blockFocusUp ? findNodeHandle(nodeRef.current) : null}
      // @ts-ignore
      nextFocusRight={blockFocusRight ? findNodeHandle(nodeRef.current) : null}
      // @ts-ignore
      nextFocusDown={blockFocusDown ? findNodeHandle(nodeRef.current) : null}
      // @ts-ignore
      nextFocusLeft={blockFocusLeft ? findNodeHandle(nodeRef.current) : null}
      {...rest}
    >
      {typeof children === 'function' ? children(focused) : children}

      {icon && (
        <IconContainer hasAdjacentText={!!text}>
          {typeof icon === 'function' ? icon(focused) : icon}
        </IconContainer>
      )}

      {!!text && <TextButton style={finalTextStyle}>{text}</TextButton>}
    </ButtonWrapper>
  );
};

export const FocusableButton = withFocusable()(FocusableButtonComponent);
