import styled from 'styled-components/native';

import { verticalScale, horizontalScale } from '~/utils/layout';
import { FocusableButton } from '~/components/FocusableButton/FocusableButton';
import { PROFILE_SCREENS_PADDING } from '~/utils/constants';

export const Content = styled.View`
  flex: 1;
  padding-top: ${PROFILE_SCREENS_PADDING}px;
`;

export const Profiles = styled.View`
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${verticalScale(50)}px;
`;

export const Title = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(36)}px;
  margin-bottom: ${verticalScale(48)}px;
`;

export const ProfilesRow = styled.View`
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${verticalScale(72)}px;
`;

export const ProfileContainer = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${horizontalScale(190)}px;
  margin: 0 ${horizontalScale(36)}px;
`;

export const ProfileName = styled.Text`
  margin: ${verticalScale(16)}px 0;
  color: ${({ theme }) => theme.colors.text.default};
  text-align: center;
  font-size: ${verticalScale(24)}px;
`;

export const StyledFocusableAvatar = styled(FocusableButton)`
  border-radius: ${verticalScale(180 / 2)}px;
  width: ${horizontalScale(180)}px;
  height: ${verticalScale(180)}px;
  border: none;
`;

export const OverlayAvatar = styled.View<{ isSelected?: boolean }>`
  background-color: ${({ theme }) => theme.colors.overlay};
  border-radius: ${verticalScale(180 / 2)}px;
  position: absolute;
  height: ${verticalScale(170)}px;
  width: ${verticalScale(170)}px;
  text-align: center;
`;

export const IconContent = styled.View`
  border-radius: 999px;
  background-color: ${({ theme }) => theme.colors.text.default};
  margin: auto;
  padding: ${verticalScale(12)}px;
`;

export const IconContainer = styled.View`
  border: none;
  border-radius: ${verticalScale(180 / 2)}px;
  width: ${horizontalScale(170)}px;
  height: ${verticalScale(170)}px;
  background-color: ${({ theme }) => theme.colors.profile.iconContainer};
  justify-content: center;
  align-items: center;
`;
