import { useState } from 'react';

import { useSession } from '~/contexts/SessionContext/SessionContext';
import { localisationToTrackLanguage } from '~/utils/functions';

export const useSelectedTextTrack = () => {
  const { currentUser } = useSession();

  const state = useState(() => {
    const { active, language } = currentUser!.preferences.captions;
    if (active) {
      return localisationToTrackLanguage(language);
    }
    return '';
  });

  return state;
};
