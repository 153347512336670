import styled from 'styled-components/native';

import { verticalScale, horizontalScale } from '~/utils/layout';

export const FB_BORDER_WIDTH = verticalScale(4);

export const TextButton = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${verticalScale(27)}px;
`;

export const IconContainer = styled.View<{ hasAdjacentText: boolean }>`
  display: flex;
  align-items: center;
  margin-right: ${({ hasAdjacentText }) =>
    hasAdjacentText ? horizontalScale(16) : 0}px;
`;
