import styled from 'styled-components/native';
import { Animated } from 'react-native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { TEXTFIELD_PADDING } from '../../TextField.styles';

const TEXT_CONTAINER_PADDING = horizontalScale(8);

export const Container = styled.View`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  padding-left: ${TEXTFIELD_PADDING - TEXT_CONTAINER_PADDING}px;
  justify-content: center;
  align-items: flex-start;
`;

export const AnimatedTextContainer = styled(Animated.View)`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 0 ${TEXT_CONTAINER_PADDING}px;
`;

export const LabelText = styled.Text<{ hasError?: boolean }>`
  font-size: ${verticalScale(24)}px;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme: { colors }, hasError }) =>
    hasError ? colors.error : colors.text.default};
`;
