import { useMemo } from 'react';

type Direction = 'up' | 'down' | 'right' | 'left';

export type FocusDirections = Direction | Direction[];

export const useBlockFocusDirection = (directions: FocusDirections) => {
  const blockProps = useMemo(() => {
    if (Array.isArray(directions)) {
      return {
        blockFocusRight: directions.includes('right'),
        blockFocusUp: directions.includes('up'),
        blockFocusLeft: directions.includes('left'),
        blockFocusDown: directions.includes('down'),
      };
    }

    return {
      blockFocusRight: directions === 'right',
      blockFocusUp: directions === 'up',
      blockFocusLeft: directions === 'left',
      blockFocusDown: directions === 'down',
    };
  }, [directions]);

  const onArrowPress = (direction: string) => {
    const blockArrowUp = blockProps.blockFocusUp && direction === 'up';
    const blockArrowRight = blockProps.blockFocusRight && direction === 'right';
    const blockArrowDown = blockProps.blockFocusDown && direction === 'down';
    const blockArrowLeft = blockProps.blockFocusLeft && direction === 'left';

    if (blockArrowUp || blockArrowRight || blockArrowDown || blockArrowLeft) {
      return false;
    }

    return true;
  };

  return {
    onArrowPress,
    ...blockProps,
  };
};
