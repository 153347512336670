import React from 'react';
import { FaRegClock } from 'react-icons/fa';
import { useTheme } from 'styled-components/native';

import { VectorIconProps } from '~/assets/icons.types';

export const ClockIcon = (props: VectorIconProps) => {
  const { colors } = useTheme();
  const { color = colors.text.default, size = 25 } = props;

  return <FaRegClock color={color} size={size * 1.5} />;
};
