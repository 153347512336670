import React, { useEffect, useState } from 'react';
import {
  FocusableElement,
  withFocusable,
} from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ActivityIndicator } from 'react-native';
import { useTheme } from 'styled-components/native';

import { FocusItems } from '~/types';
import { Crashlytics } from '~/utils/crashlytics';
import { verticalScale } from '~/utils/layout';
import { FloatingLogo } from '~/components/FloatingLogo/FloatingLogo';
import { deleteProfileById } from '~/services/profiles/profilesApi';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { WarningCircularIcon } from '~/assets/icons/WarningCircularIcon';
import { ActionButton } from '~/components/ActionButton/ActionButton';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import type {
  RootStackNavigationProp,
  RootStackRouteProp,
} from '~/routes/routes.types';

import {
  Content,
  Box,
  IconContent,
  Title,
  SubTitle,
  Description,
  Buttons,
} from './ProfileDelete.styles';

const ICON_SIZE = verticalScale(67);
const LOADING_SIZE = verticalScale(50);

const ProfileDeleteComponent = (props: FocusableElement) => {
  const { setFocus, stealFocus, updateAllSpatialLayouts } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const { t } = useTranslation();
  const { colors } = useTheme();
  const navigation = useNavigation<RootStackNavigationProp<'ProfileDelete'>>();
  const route = useRoute<RootStackRouteProp<'ProfileDelete'>>();
  const { idProfile } = route.params;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deleteProfile = async () => {
    try {
      setIsLoading(true);
      await deleteProfileById(idProfile);
      navigation.navigate('ProfileList');
    } catch (err) {
      setIsLoading(false);
      setFocus(FocusItems.CancelProfileDelete);
      Crashlytics.handleException(err, 'Delete profile');
    }
  };

  useEffect(() => {
    setFocus(FocusItems.CancelProfileDelete);
  }, [setFocus]);

  return (
    <ScreenBackground>
      <FloatingLogo />

      <Content>
        <Box>
          <IconContent>
            <WarningCircularIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              fill={colors.profile.warningIcon}
            />
          </IconContent>

          <Title>{t('profile_delete.title', 'Excluir perfil')}</Title>
          <SubTitle>
            {t(
              'profile_delete.subtitle',
              'Tem certeza que deseja excluir este perfil?'
            )}
          </SubTitle>
          <Description>
            {t(
              'profile_delete.description',
              'Todo o histórico, Minha Lista e atividades do usuário serão perdidos. Essa ação não pode ser desfeita.'
            )}
          </Description>

          <Buttons>
            {isLoading ? (
              <ActivityIndicator
                size={LOADING_SIZE}
                color={colors.spinner.primary}
              />
            ) : (
              <>
                <ActionButton
                  rightGap
                  expanded
                  focusKey={FocusItems.CancelProfileDelete}
                  hasTVPreferredFocus
                  text={t('profile_delete.cancel', 'Cancelar')}
                  onPress={() => navigation.goBack()}
                  onEnterPress={() => navigation.goBack()}
                />
                <ActionButton
                  expanded
                  text={t('profile_delete.confirm', 'Excluir')}
                  onPress={deleteProfile}
                  onEnterPress={deleteProfile}
                />
              </>
            )}
          </Buttons>
        </Box>
      </Content>
    </ScreenBackground>
  );
};

export const ProfileDelete = withFocusable({ blockNavigationOut: true })(
  ProfileDeleteComponent
);
