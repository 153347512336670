import React, { memo } from 'react';

import { HorizontalCardList } from '~/components/HorizontalCardList/HorizontalCardList';

import type { IContentList } from './ContentList.types';

const ContentListComponent = (props: IContentList) => {
  const { contentRows, onRowFocus, onProgramFocus, onProgramSelect } = props;

  return (
    <>
      {contentRows?.map((row) => (
        <HorizontalCardList
          key={row.id}
          name={row.name}
          onBecameFocused={onRowFocus}
          cards={row.cards.map((card) => ({
            id: card.id,
            imageURL: card.imageURL,
            name: card.name,
            programData: card,
            onSelectCard: () => onProgramSelect(card.id),
            onFocus: () => onProgramFocus(card.programData),
          }))}
        />
      ))}
    </>
  );
};

export const ContentList = memo(ContentListComponent);
