import React from 'react';
import { Path, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';
import { verticalScale } from '~/utils/layout';

export const EyeIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const {
    width = verticalScale(44),
    height = verticalScale(30),
    fill = colors.text.default,
  } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 44 30">
      <Path
        d="M22 4C29.58 4 36.34 8.26 39.64 15C36.34 21.74 29.58 26 22 26C14.42 26 7.66 21.74 4.36 15C7.66 8.26 14.42 4 22 4ZM22 0C12 0 3.46 6.22 0 15C3.46 23.78 12 30 22 30C32 30 40.54 23.78 44 15C40.54 6.22 32 0 22 0ZM22 10C24.76 10 27 12.24 27 15C27 17.76 24.76 20 22 20C19.24 20 17 17.76 17 15C17 12.24 19.24 10 22 10ZM22 6C17.04 6 13 10.04 13 15C13 19.96 17.04 24 22 24C26.96 24 31 19.96 31 15C31 10.04 26.96 6 22 6Z"
        fill={fill}
      />
    </Svg>
  );
};
