import AsyncStorage from '@react-native-async-storage/async-storage';

export const storage = {
  async getItem(key: string) {
    try {
      return AsyncStorage.getItem(key);
    } catch (err) {
      return null;
    }
  },

  async setItem(key: string, data: string | object) {
    try {
      const value = typeof data === 'object' ? JSON.stringify(data) : data;
      return AsyncStorage.setItem(key, value);
    } catch (err) {
      return null;
    }
  },

  async removeItem(key: string) {
    try {
      return AsyncStorage.removeItem(key);
    } catch (err) {
      return null;
    }
  },
};
