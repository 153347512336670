import styled, { css } from 'styled-components/native';
import { Animated, ImageBackground, Platform, View } from 'react-native';

import { verticalScale, horizontalScale } from '~/utils/layout';
import { LinearGradient } from '../LinearGradient/LinearGradient';
import {
  HORIZONTAL_SAFE_ZONE,
  SPACING_FROM_SIDE_MENU,
  BANNER_HEIGHT,
} from '~/utils/constants';

import type { IBannerImage, IBannerLogo } from './Banner.types';

export const Container = styled.View`
  height: ${BANNER_HEIGHT}px;
  overflow: hidden;
`;

export const StyledLinearGradient = styled(LinearGradient)<
  Pick<IBannerLogo, 'isWithingProgram'>
>`
  padding-top: ${verticalScale(120)}px;
  padding-right: ${HORIZONTAL_SAFE_ZONE}px;
  padding-bottom: ${verticalScale(40)}px;
  padding-left: ${({ isWithingProgram }) =>
    isWithingProgram ? HORIZONTAL_SAFE_ZONE : SPACING_FROM_SIDE_MENU}px;
  align-items: flex-start;
  position: relative;
`;

const BannerImageElement = Platform.OS === 'web' ? View : ImageBackground;

const webStyles = css<IBannerImage>`
  background-image: url(${({ source }) => source.uri});
  background-position-y: top;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const BannerImage = styled(BannerImageElement)<IBannerImage>`
  flex: 1;
  ${Platform.OS === 'web' && webStyles};
`;

const LOGO_HEIGHT = verticalScale(124);

export const BannerLogo = styled(Animated.Image)<IBannerLogo>`
  height: ${LOGO_HEIGHT}px;
  width: ${({ aspectRatio = 0 }) => LOGO_HEIGHT * aspectRatio}px;
`;

export const LabelsRow = styled.View`
  flex-direction: row;
  margin: ${verticalScale(32)}px 0;
`;

export const Label = styled.View`
  margin-right: ${horizontalScale(48)}px;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(28)}px;
  margin-left: ${horizontalScale(16)}px;
`;
