import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';
import { FocusableButton } from '../FocusableButton/FocusableButton';

export const Container = styled(FocusableButton).attrs({
  bgFocusedColor: 'transparent',
})`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-width: 0px;
`;

const BOX_SIZE = verticalScale(36);

export const Box = styled.View<{ focused?: boolean; checked?: boolean }>`
  width: ${BOX_SIZE}px;
  height: ${BOX_SIZE}px;
  margin-right: ${verticalScale(20)}px;
  border-color: ${({ theme: { colors }, focused }) =>
    focused ? colors.spatialFocus : colors.text.default};
  border-width: ${({ checked }) => (checked ? 0 : verticalScale(4))}px;
  border-radius: ${verticalScale(4)}px;
`;

export const Label = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(24)}px;
`;
