import { useRef, useCallback } from 'react';

import { trackFullWatchedContent } from '~/services/programs/programsApi';
import type { IVideoPlayerProps } from '~/screens/Program/Program.types';

export interface IUseTrackFullWatchedContentProps
  extends Omit<IVideoPlayerProps, 'sources'> {}

export const useTrackFullWatchedContent = (
  props: IUseTrackFullWatchedContentProps
) => {
  const { programId, mediaId } = props;
  const watchedFullContentRef = useRef(false);

  const handleFullWatchedContent = useCallback(
    (progress: number) => {
      if (mediaId && !watchedFullContentRef.current && progress >= 75) {
        trackFullWatchedContent({ mediaId, programId });
        watchedFullContentRef.current = true;
      }
    },
    [mediaId, programId]
  );

  return { handleFullWatchedContent };
};
