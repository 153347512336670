import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { SVGIconProps } from '../icons.types';

export const WarningCircularIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const {
    width = horizontalScale(40),
    height = verticalScale(40),
    fill = colors.text.default,
  } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 68 68" fill={fill}>
      <Path
        d="M30.666 44H37.3327V50.6667H30.666V44ZM30.666 17.3333H37.3327V37.3333H30.666V17.3333ZM33.966 0.666656C15.566 0.666656 0.666016 15.6 0.666016 34C0.666016 52.4 15.566 67.3333 33.966 67.3333C52.3994 67.3333 67.3327 52.4 67.3327 34C67.3327 15.6 52.3994 0.666656 33.966 0.666656ZM33.9994 60.6667C19.266 60.6667 7.33268 48.7333 7.33268 34C7.33268 19.2667 19.266 7.33332 33.9994 7.33332C48.7327 7.33332 60.666 19.2667 60.666 34C60.666 48.7333 48.7327 60.6667 33.9994 60.6667Z"
        fill={fill}
      />
    </Svg>
  );
};
