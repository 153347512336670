import styled from 'styled-components/native';

import { verticalScale, horizontalScale } from '~/utils/layout';

export const Content = styled.View`
  flex-direction: row;
  border: ${verticalScale(1)}px solid
    ${({ theme }) => theme.colors.text.default};
  border-radius: ${verticalScale(8)}px;
  margin: ${verticalScale(48)}px auto 0;
  padding: ${verticalScale(35)}px ${horizontalScale(30)}px;
  width: ${horizontalScale(1080)}px;
`;

export const IconContent = styled.View`
  width: ${horizontalScale(200)}px;
  margin-right: ${verticalScale(30)}px;
  align-items: center;
  justify-content: center;
`;

export const TextContent = styled.View`
  flex: 1;
  align-items: flex-start;
`;

export const TitleHeader = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(36)}px;
`;

export const Message = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(27)}px;
  margin-top: ${verticalScale(16)}px;
`;
