export enum FocusItems {
  Search = 'search-button',
  LoginInput = 'login-input',
  PasswordInput = 'password-input',
  LoginSubmitButton = 'login-submit-button',
  KnowMore = 'know-more',
  Presentation = 'presentation',
  HowWorks = 'how-works',
  PlayerBackward = 'player-backward',
  PlayerForward = 'player-forward',
  PlayerPlayPause = 'player-play-pause',
  ButtonPtBR = 'button-pt-br',
  ConfirmLogout = 'confirm-logout',
  Exit = 'Exit',
  CloseButton = 'close-button',
  CancelProfileDelete = 'cancel-profile-delete',
}
