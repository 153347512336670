import React from 'react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '~/components/ActionButton/ActionButton';

import { Container, FilterGroup, Label } from './Filter.styles';

import type { IFilter } from './Filter.types';

const FilterComponent = (props: IFilter) => {
  const { label, options, selectedOptions, onSelect } = props;
  const { t } = useTranslation();

  return (
    <Container>
      <Label>{label}</Label>

      <FilterGroup>
        {options.map((option) => {
          const isFilterActive = selectedOptions.has(option.id);

          return (
            <ActionButton
              key={option.id}
              onPress={() => onSelect(option.id)}
              onEnterPress={() => onSelect(option.id)}
              text={option.getLabel(t)}
              selected={isFilterActive}
              rightGap
              bottomGap
            />
          );
        })}
      </FilterGroup>
    </Container>
  );
};

export const Filter = withFocusable()(FilterComponent);
