import styled, { css } from 'styled-components/native';

import { verticalScale } from '~/utils/layout';
import { FocusableButton } from '../FocusableButton/FocusableButton';

import type { IActionButton } from './ActionButton.types';

const BUTTON_GAP = verticalScale(16);

export const ActionButton = styled(FocusableButton).attrs<IActionButton>(
  ({ theme: { colors }, selected }) => ({
    borderColor: colors.text.default,
    focusedBorderColor: selected ? colors.text.default : colors.spatialFocus,
    textColor: selected ? colors.text.default : undefined,
    bgColor: selected ? colors.backgroundSelectedButton : undefined,
  })
)<IActionButton>`
  font-family: ${({ theme }) => theme.fonts.regular};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  padding: ${verticalScale(14)}px ${verticalScale(20)}px;
  margin-right: ${({ rightGap }) => (rightGap ? BUTTON_GAP : 0)}px;
  margin-bottom: ${({ bottomGap }) => (bottomGap ? BUTTON_GAP : 0)}px;
  border-radius: ${verticalScale(8)}px;
  border-width: ${verticalScale(1)}px;
  ${({ expanded }) => {
    if (expanded) {
      return css`
        flex: 1;
      `;
    }
  }};
`;
