import { Dispatch, SetStateAction } from 'react';
import { FocusableElement } from '@noriginmedia/react-spatial-navigation';

import type { IKeyboardTemplate } from './components/KeyboardTemplate/KeyboardTemplate.types';

export interface IKeyboard extends FocusableElement {
  text: string;
  onChangeText: (text: string) => void;
  hasTVPreferredFocus?: boolean;
  type?: 'default' | 'email';
}

export interface IKeyboardGap {
  hasGap?: boolean;
}

export interface IKeyboardKey extends IKeyboardGap {
  columns?: number | 'auto';
  selected?: boolean;
}

export enum KeyboardLayoutMap {
  Alphanumeric = 'alphanumeric',
  Special = 'special',
  Diacritical = 'diacritical',
}

export interface IKeyboardLayout
  extends Omit<IKeyboardTemplate, 'template' | 'hasGap'> {}

export interface IKeyboardControls extends IKeyboardLayout {
  updateLayout: Dispatch<SetStateAction<KeyboardLayoutMap>>;
  keyboardLayout: KeyboardLayoutMap;
}
