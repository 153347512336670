import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';

export const PlusIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const { width = 22, height = 22, fill = colors.text.default } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 22 22">
      <Path
        fill={fill}
        fillRule="evenodd"
        d="M21.4999 12.5H12.4999V21.5H9.49988V12.5H0.499878V9.5H9.49988V0.5H12.4999V9.5H21.4999V12.5Z"
      />
    </Svg>
  );
};
