import React from 'react';

import { baseAssetsUrl } from '~/configs/env';
import { Avatar } from '~/components/Avatar/Avatar';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';

import { useAvatarList } from './hooks/useAvatarList';
import {
  Container,
  ListTitle,
  AvatarRow,
  AvatarContent,
  StyledFocusableButton,
} from './AvatarList.styles';

import type { IAvatarList } from './AvatarList.types';

export const AvatarList = (props: IAvatarList) => {
  const { title, data, setAvatar, focusKey, hasTVPreferredFocus } = props;

  const lastItemProps = useBlockFocusDirection('right');
  const { getScrollViewProps, handleAvatarFocus } = useAvatarList();

  return (
    <Container>
      <ListTitle>{title}</ListTitle>

      <AvatarRow {...getScrollViewProps()}>
        {data?.map((avatar, index) => {
          const isLastItem = index === data.length - 1;

          return (
            <AvatarContent key={index}>
              <StyledFocusableButton
                focusKey={index === 0 ? focusKey : undefined}
                hasTVPreferredFocus={
                  index === 0 ? hasTVPreferredFocus : undefined
                }
                onEnterPress={() => setAvatar?.(avatar)}
                onPress={() => setAvatar?.(avatar)}
                onBecameFocused={(element) => {
                  handleAvatarFocus({
                    element,
                    avatarIndex: index,
                  });
                }}
                {...(isLastItem ? { ...lastItemProps } : {})}
              >
                <Avatar image={`${baseAssetsUrl}/${avatar}`} fallbackImage />
              </StyledFocusableButton>
            </AvatarContent>
          );
        })}
      </AvatarRow>
    </Container>
  );
};
