import { Event } from '@sentry/react';

const ignoredErrors = [
  'Network Error',
  'The play() request was interrupted by a call to pause()',
  'The play() request was interrupted by a new load request',
];

export const checkIfErrorIsIgnored = (event: Event) => {
  return event.exception?.values?.some((exceptionValue) => {
    const { value } = exceptionValue || {};
    if (value) {
      return ignoredErrors.some((ignoredError) =>
        value.toLowerCase().includes(ignoredError.toLowerCase())
      );
    }
    return false;
  });
};
