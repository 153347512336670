import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';

export const Container = styled.TouchableOpacity`
  width: 50%;
`;

export const Title = styled.Text<{ focused: boolean }>`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ focused, theme }) =>
    focused ? theme.colors.selectedItem : theme.colors.text.default};
  font-size: ${verticalScale(44)}px;
  font-weight: bold;
  margin: ${verticalScale(32)}px 0 ${verticalScale(20)}px;
`;
