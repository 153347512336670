import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { FocusableTitle } from '../FocusableTitle/FocusableTitle';

import {
  Container,
  HeaderText,
  TextDescription,
  Topic,
  TopicMarker,
  TopicText,
} from './How.styles';
import { IHow } from './How.types';

export const How = ({ data, onBecameFocused }: IHow) => {
  const { t } = useTranslation();

  return (
    <Container>
      <HeaderText>{t('program.how_it_works', 'Como Funciona')}</HeaderText>
      {data &&
        data.map(({ title, description, topics }) => (
          <Fragment key={title}>
            <FocusableTitle onBecameFocused={onBecameFocused}>
              {title}
            </FocusableTitle>
            <TextDescription>{description}</TextDescription>
            {topics &&
              topics.map((topic) => (
                <Topic key={topic}>
                  <TopicMarker />
                  <TopicText>{topic.replace(/\n/g, '')}</TopicText>
                </Topic>
              ))}
          </Fragment>
        ))}
    </Container>
  );
};
