import React, { useEffect, useState } from 'react';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ActivityIndicator } from 'react-native';
import { useTheme } from 'styled-components/native';

import { Crashlytics } from '~/utils/crashlytics';
import { FloatingLogo } from '~/components/FloatingLogo/FloatingLogo';
import { Keyboard } from '~/components/Keyboard/Keyboard';
import { TextField } from '~/components/TextField/TextField';
import { Avatar } from '~/components/Avatar/Avatar';
import { BackButton } from '~/components/BackButton/BackButton';
import { addProfile } from '~/services/profiles/profilesApi';
import { baseAssetsUrl } from '~/configs/env';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { verticalScale } from '~/utils/layout';
import { ActionButton } from '~/components/ActionButton/ActionButton';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import type {
  RootStackNavigationProp,
  RootStackRouteProp,
} from '~/routes/routes.types';

import {
  Content,
  AvatarContent,
  StyledFocusableAvatar,
  Title,
  SubTitle,
  Form,
  LeftColumn,
  RightColumn,
  FormActions,
  FormLoading,
} from './ProfileAdd.styles';

const FOCUS_KEY = 'profile-add-keyboard';
const AVATAR_DEFALUT = 'uploads/imagensUsuarios/avatar_gray.png';
const LOADING_SIZE = verticalScale(50);

const ProfileComponent = (props: FocusableElement) => {
  const { setFocus, stealFocus, updateAllSpatialLayouts } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const { t } = useTranslation();
  const { colors } = useTheme();
  const blockHorizontalFocusProps = useBlockFocusDirection(['left', 'right']);
  const navigation = useNavigation<RootStackNavigationProp<'ProfileAdd'>>();
  const route = useRoute<RootStackRouteProp<'ProfileAdd'>>();
  const { avatar } = route.params;

  const [name, setName] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitForm = async () => {
    if (isLoading) {
      return;
    }

    if (!name) {
      setError(t('profile_add.error', 'Insira seu nome para salvar'));
      return;
    }
    try {
      setError('');
      setIsLoading(true);
      await addProfile({
        name,
        avatar: avatar ? avatar : AVATAR_DEFALUT,
      });
      navigation.navigate('ProfileList');
    } catch (err) {
      setError(t('profile_add.error_on_add_profile', 'Erro ao cadastrar'));
      setIsLoading(false);
      Crashlytics.handleException(err, 'Add profile');
    }
  };

  const updateAvatar = () =>
    navigation.navigate('ProfileAvatar', { pageRequest: 'ProfileAdd' });

  useEffect(() => {
    setFocus(FOCUS_KEY);
  }, [setFocus]);

  return (
    <ScreenBackground>
      <Content>
        <BackButton blockFocusDirections={['right']} />

        <FloatingLogo />

        <AvatarContent>
          <Title>{t('profile_add.title', 'Editar perfil')}</Title>
          <StyledFocusableAvatar
            onEnterPress={updateAvatar}
            onPress={updateAvatar}
            {...blockHorizontalFocusProps}
          >
            <Avatar
              altText={'Queima Diaria'}
              image={`${baseAssetsUrl}/${avatar ? avatar : AVATAR_DEFALUT}`}
            />
          </StyledFocusableAvatar>
          <SubTitle>{t('profile_add.change_avatar', 'Trocar avatar')}</SubTitle>
        </AvatarContent>

        <Form>
          <LeftColumn>
            <Keyboard
              text={name}
              onChangeText={setName}
              focusKey={FOCUS_KEY}
              hasTVPreferredFocus
            />
          </LeftColumn>

          <RightColumn>
            <TextField
              placeholder={t('profile_add.placeholder', 'Nome')}
              value={name}
              error={error}
            />

            <FormActions>
              <ActionButton
                expanded
                rightGap
                text={t('profile_add.cancel', 'Cancelar')}
                onEnterPress={() => navigation.goBack()}
                onPress={() => navigation.goBack()}
              />
              <ActionButton
                expanded
                text={t('profile_add.save', 'Salvar')}
                onEnterPress={submitForm}
                onPress={submitForm}
              />
            </FormActions>
          </RightColumn>

          <FormLoading>
            {isLoading && (
              <ActivityIndicator
                size={LOADING_SIZE}
                color={colors.spinner.primary}
              />
            )}
          </FormLoading>
        </Form>
      </Content>
    </ScreenBackground>
  );
};

export const ProfileAdd = withFocusable({ blockNavigationOut: true })(
  ProfileComponent
);
