import React, { memo } from 'react';

import { Card } from '../Card/Card';
import { useSideMenuOnScreen } from '~/hooks/useSideMenuOnScreen';

import {
  CardContainer,
  CardList,
  Container,
  Subtitle,
  Title,
} from './GridCardList.styles';

import type { IGridCardList } from './GridCardList.types';

const GridCardListComponent = (props: IGridCardList) => {
  const {
    name,
    isContentBlocked,
    cards,
    onBecameFocused,
    columns,
    focusKey,
    hasTVPreferredFocus,
    customCardWidth,
  } = props;

  const sideMenuOnScreen = useSideMenuOnScreen();

  return (
    <Container>
      <Title>{name}</Title>
      <CardList>
        {cards.map((card, index) => (
          <CardContainer key={card.id} index={index} columns={columns}>
            <Card
              customWidth={customCardWidth}
              dense={sideMenuOnScreen}
              focusKey={index === 0 ? focusKey : undefined}
              hasTVPreferredFocus={
                index === 0 ? hasTVPreferredFocus : undefined
              }
              isContentBlocked={isContentBlocked}
              imageURL={card.imageURL}
              onSelectCard={card.onSelectCard}
              onBecameFocused={onBecameFocused}
              timesWatched={card.timesWatched}
              name={card.name}
              badge={card.badge}
              blockFocusDirections={
                index === cards.length - 1 ? 'right' : undefined
              }
            />
            {!!card.subtitle && <Subtitle>{card.subtitle}</Subtitle>}
          </CardContainer>
        ))}
      </CardList>
    </Container>
  );
};

export const GridCardList = memo(GridCardListComponent);
