import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { SVGIconProps } from '../icons.types';

export const TvOffIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const {
    width = horizontalScale(16),
    height = verticalScale(8),
    fill = colors.text.default,
  } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 43 43">
      <Path
        d="M41.5999 33L38.9999 30.3C38.9999 30.2333 38.9999 30.1833 38.9999 30.15C38.9999 30.1167 38.9999 30.0667 38.9999 30V6H14.6999L10.6999 2H38.9999C40.0999 2 41.0419 2.39133 41.8259 3.174C42.6086 3.958 42.9999 4.9 42.9999 6V30C42.9999 30.6 42.8832 31.15 42.6499 31.65C42.4166 32.15 42.0666 32.6 41.5999 33ZM39.9999 42.6L31.3999 34H30.9999V38H14.9999V34H6.9999C5.8999 34 4.95857 33.6087 4.1759 32.826C3.3919 32.042 2.9999 31.1 2.9999 30V6C2.9999 5.93333 2.9999 5.86666 2.9999 5.8C2.9999 5.73333 2.9999 5.66666 2.9999 5.6L0.399902 3L3.1999 0.199997L42.7999 39.8L39.9999 42.6ZM27.2999 30L6.9999 9.7V30H27.2999Z"
        fill={fill}
      />
    </Svg>
  );
};
