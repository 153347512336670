import React from 'react';
import { Svg, Path, G } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';

export const HelpIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const { width = 42, height = 42, fill = colors.text.default } = props;

  return (
    <Svg height={height} width={width} viewBox={'0 0 42 42'}>
      <G fill="none" fillRule="evenodd">
        <Path d="M-4-3h49.327v49.327H-4z" />
        <Path
          fill={fill}
          d="M21.12.654C9.775.654.567 9.862.567 21.207S9.775 41.76 21.12 41.76s20.553-9.208 20.553-20.553S32.465.654 21.12.654zm0 36.995c-9.064 0-16.442-7.378-16.442-16.442S12.056 4.764 21.12 4.764s16.442 7.379 16.442 16.443c0 9.064-7.378 16.442-16.442 16.442zm-2.055-8.221h4.11v4.11h-4.11v-4.11zm3.309-20.47a8.225 8.225 0 0 0-9.105 5.733c-.37 1.193.534 2.405 1.788 2.405h.411c.843 0 1.521-.596 1.809-1.377.657-1.83 2.61-3.083 4.727-2.63 1.952.41 3.391 2.322 3.227 4.315-.206 2.755-3.33 3.35-5.036 5.92 0 .02-.02.02-.02.04-.02.042-.041.062-.062.104-.185.308-.37.657-.514 1.027-.02.062-.061.103-.082.165-.02.04-.02.082-.041.143-.247.7-.411 1.542-.411 2.57h4.11c0-.864.227-1.583.576-2.2.041-.061.062-.123.103-.185.164-.287.37-.554.575-.801.02-.02.041-.062.062-.082.205-.247.431-.473.678-.7 1.973-1.87 4.645-3.39 4.09-7.316-.493-3.576-3.309-6.597-6.885-7.132z"
        />
      </G>
    </Svg>
  );
};
