import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { useTheme } from 'styled-components/native';

import { LeftIcon } from '~/assets/icons/Left/Left';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';
import type { RootStackParams } from '~/routes/routes.types';

import { StyledBackButton } from './BackButton.styles';

import type { IBackButton } from './BackButton.types';

export const BackButton = (props: IBackButton) => {
  const {
    style,
    handleFocus,
    blockFocusDirections,
    hasTVPreferredFocus,
    focusKey,
    hidden,
    customAction,
  } = props;
  const { colors } = useTheme();
  const navigation = useNavigation<StackNavigationProp<RootStackParams>>();
  const backButtonProps = useBlockFocusDirection(blockFocusDirections || []);

  const handlePress = () => {
    if (customAction) {
      customAction();
    } else {
      navigation.goBack();
    }
  };

  return (
    <StyledBackButton
      style={style}
      focusKey={focusKey}
      hasTVPreferredFocus={hasTVPreferredFocus}
      focusedBorderColor="transparent"
      onEnterPress={handlePress}
      onPress={handlePress}
      onBecameFocused={handleFocus}
      hidden={hidden}
      {...backButtonProps}
      icon={(focused) => (
        <LeftIcon color={focused ? colors.text.active : undefined} />
      )}
    />
  );
};
