import React from 'react';
import { useTheme } from 'styled-components/native';

import { StyledFocusableButton, GradientButtonBg } from './SideMenuItem.styles';

import type { ISideMenuItem } from './SideMenuItem.types';

export const SideMenuItem = (props: ISideMenuItem) => {
  const { colors } = useTheme();
  const {
    onFocus,
    onBlur,
    onPress,
    text,
    icon,
    isMenuExpanded,
    isCurrentRoute,
    focusable,
    ...rest
  } = props;

  return (
    <StyledFocusableButton
      isMenuExpanded={isMenuExpanded}
      onBecameFocused={onFocus}
      onBecameBlurred={onBlur}
      onPress={onPress}
      onEnterPress={onPress}
      text={isMenuExpanded ? text : undefined}
      textColor={isCurrentRoute ? colors.text.default : colors.sideMenu.icon}
      icon={icon}
      focusable={focusable}
      {...rest}
    >
      {(focused) => focused && isMenuExpanded && <GradientButtonBg />}
    </StyledFocusableButton>
  );
};
