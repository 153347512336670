import React from 'react';
import { useTranslation } from 'react-i18next';

import { UnlockIcon } from '~/assets/icons/UnlockIcon';
import { verticalScale } from '~/utils/layout';

import {
  Content,
  IconContent,
  TextContent,
  TitleHeader,
  Message,
} from './SignatureMessage.styles';

const ICON_SIZE = verticalScale(96);

export const SignatureMessage = () => {
  const { t } = useTranslation();

  return (
    <Content>
      <IconContent>
        <UnlockIcon height={ICON_SIZE} width={ICON_SIZE} />
      </IconContent>
      <TextContent>
        <TitleHeader>
          {t('program.signature_message_title', 'Seja Assinante')}
        </TitleHeader>
        <Message>
          {t(
            'program.signature_message_text',
            'Desbloqueie esse e mais de 30 programas'
          )}
        </Message>
        <Message>
          {t(
            'program.signature_message_contact',
            'Entre em contato pelo e-mail: suporte@queimadiaria.com.br'
          )}
        </Message>
      </TextContent>
    </Content>
  );
};
