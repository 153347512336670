import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator } from 'react-native';
import { useTheme } from 'styled-components/native';

import { verticalScale } from '~/utils/layout';
import { useNetwork } from '~/contexts/NetworkContext/NetworkContext';
import { ScreenBackground } from '../ScreenBackground/ScreenBackground';

import { Content, Title, Subtitle } from './NetworkStatus.styles';
import { NetworkStatusProps } from './NetworkStatus.types';

const ICON_SIZE = verticalScale(50);

export const NetworkStatus = ({ children }: NetworkStatusProps) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { isConnected } = useNetwork();

  return (
    <ScreenBackground>
      {isConnected ? (
        children
      ) : (
        <Content>
          <Title>
            {t(
              'common.network_error',
              'Ops! Ocorreu um erro com sua conexão e não estamos conseguindo nos conectar.'
            )}
          </Title>

          <Subtitle>{t('common.retrying', 'Tentando novamente...')}</Subtitle>
          <ActivityIndicator size={ICON_SIZE} color={colors.spinner.primary} />
        </Content>
      )}
    </ScreenBackground>
  );
};
