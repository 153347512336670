import { Dimensions } from 'react-native';

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('screen');

// Default guideline sizes are based on Full HD screens (1920x1080)
const GUIDELINE_BASE_WIDTH = 1920;
const GUIDELINE_BASE_HEIGHT = 1080;

/**
 * Vertical scaling based on screen height
 * @param height Desired height in pixels on a Full HD screen
 */
export const verticalScale = (height: number | string) => {
  const numericHeight = Number(height);
  if (isNaN(numericHeight)) {
    return numericHeight;
  }

  const ratio = SCREEN_HEIGHT / GUIDELINE_BASE_HEIGHT;
  return numericHeight * ratio;
};

/**
 * Horizontal scaling based on screen width
 * @param width Desired width in pixels on a Full HD screen
 */
export const horizontalScale = (width: number | string) => {
  const numericWidth = Number(width);
  if (isNaN(numericWidth)) {
    return numericWidth;
  }

  const ratio = SCREEN_WIDTH / GUIDELINE_BASE_WIDTH;
  return numericWidth * ratio;
};
