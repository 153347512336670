import styled from 'styled-components/native';
import { Platform } from 'react-native';

export const Container = styled.View`
  position: ${Platform.OS === 'web' ? 'fixed' : 'absolute'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111111;
`;
