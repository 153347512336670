import React, { useRef, useLayoutEffect, useState, useCallback } from 'react';
import { Animated, LayoutChangeEvent } from 'react-native';

import { verticalScale } from '~/utils/layout';

import { Container, AnimatedTextContainer, LabelText } from './Label.styles';

import type { ILabel } from './Label.types';

const SCALE = 2 / 3;
const TRANSLATION_Y = -verticalScale(44);
const TRANSLATION_X_RATIO = SCALE / 4;

export const Label = (props: ILabel) => {
  const { children, fieldValue, hasError } = props;

  const opacity = useRef(new Animated.Value(fieldValue ? 0 : 1)).current;
  const transform = useRef(new Animated.Value(fieldValue ? 1 : 0)).current;
  const isTransformedRef = useRef(!!fieldValue);

  const [textWidth, setTextWidth] = useState<number>(0);

  const animateLabelTo = useCallback(
    (value: number) => {
      isTransformedRef.current = Boolean(value);
      Animated.timing(transform, {
        toValue: value,
        duration: 150,
        useNativeDriver: true,
      }).start();
    },
    [transform]
  );

  const onTextLayoutChange = (event: LayoutChangeEvent) => {
    setTextWidth(event.nativeEvent.layout.width);
    Animated.timing(opacity, {
      toValue: 1,
      duration: 150,
      useNativeDriver: true,
    }).start();
  };

  useLayoutEffect(() => {
    if (fieldValue === undefined) {
      return;
    }

    if (fieldValue.length >= 1 && !isTransformedRef.current) {
      animateLabelTo(1);
    } else if (fieldValue.length === 0) {
      animateLabelTo(0);
    }
  }, [fieldValue, animateLabelTo]);

  const animatedLabelContainer = {
    opacity: opacity.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
    }),
    transform: [
      {
        translateX: transform.interpolate({
          inputRange: [0, 1],
          outputRange: [0, -(textWidth * TRANSLATION_X_RATIO)],
        }),
      },
      {
        translateY: transform.interpolate({
          inputRange: [0, 1],
          outputRange: [0, TRANSLATION_Y],
        }),
      },
      {
        scale: transform.interpolate({
          inputRange: [0, 1],
          outputRange: [1, SCALE],
        }),
      },
    ],
  };

  return (
    <Container>
      <AnimatedTextContainer style={{ ...animatedLabelContainer }}>
        <LabelText hasError={hasError} onLayout={onTextLayoutChange}>
          {children}
        </LabelText>
      </AnimatedTextContainer>
    </Container>
  );
};
