import styled from 'styled-components/native';
import { StyleSheet, Platform } from 'react-native';

import { verticalScale } from '~/utils/layout';

export const InputLabel = styled.Text`
  margin-bottom: ${verticalScale(12)}px;
  font-size: ${verticalScale(46)}px;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
`;

export const { input } = StyleSheet.create({
  input: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 4,
    borderWidth: Platform.OS === 'web' ? 3 : 2,
  },
});

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(32)}px;
  margin: ${verticalScale(16)}px 0 ${verticalScale(32)}px;
`;
