import React, { useState, memo, useMemo, useCallback } from 'react';

import { KeyboardTemplate } from '../KeyboardTemplate/KeyboardTemplate';

import { generateAlphanumericKeys } from './generateAlphanumericKeys';

import type { IKeyboardLayout } from '../../Keyboard.types';

const AlphanumericLayoutComponent = (props: IKeyboardLayout) => {
  const [letterCase, setLetterCase] = useState<'upper' | 'lower'>('lower');

  const toggleCase = useCallback(() => {
    setLetterCase((state) => {
      if (state === 'lower') {
        return 'upper';
      }
      return 'lower';
    });
  }, []);

  const template = useMemo(
    () => generateAlphanumericKeys({ letterCase, toggleCase }),
    [letterCase, toggleCase]
  );

  return <KeyboardTemplate {...props} template={template} hasGap />;
};

export const AlphanumericLayout = memo(AlphanumericLayoutComponent);
