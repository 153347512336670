import React from 'react';
import { useTheme } from 'styled-components/native';

import { CheckboxIcon } from '~/assets/icons/CheckboxIcon';

import { Container, Box, Label } from './Checkbox.styles';

import type { ICheckbox } from './Checkbox.types';

export const Checkbox = (props: ICheckbox) => {
  const { label, onPress, checked, hasTVPreferredFocus, ...rest } = props;

  const { colors } = useTheme();

  const handlePress = () => {
    onPress?.(!checked);
  };

  return (
    <Container
      onPress={handlePress}
      onEnterPress={handlePress}
      hasTVPreferredFocus={hasTVPreferredFocus}
      {...rest}
    >
      {(focused) => (
        <>
          <Box focused={focused} checked={checked}>
            {checked && (
              <CheckboxIcon fill={focused ? colors.spatialFocus : undefined} />
            )}
          </Box>
          <Label>{label}</Label>
        </>
      )}
    </Container>
  );
};
