import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';
import { HORIZONTAL_SAFE_ZONE, VERTICAL_SAFE_ZONE } from '~/utils/constants';

export const ScrollArea = styled.ScrollView`
  height: 100%;
`;

export const Content = styled.View`
  flex: 1;
`;

export const VideoListContainer = styled.View`
  flex: 1;
  padding: ${verticalScale(48)}px 0 ${VERTICAL_SAFE_ZONE}px;
`;

export const NextLectureContainer = styled.View`
  position: absolute;
  top: ${VERTICAL_SAFE_ZONE}px;
  right: ${HORIZONTAL_SAFE_ZONE}px;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const NextLectureTitle = styled.Text`
  font-size: ${verticalScale(32)}px;
  margin-bottom: ${verticalScale(18)}px;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const NextLectureName = styled.Text`
  font-size: ${verticalScale(24)}px;
  margin-top: ${verticalScale(16)}px;
  color: ${({ theme }) => theme.colors.text.default};
`;
