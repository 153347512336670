import React, { useState, useEffect } from 'react';
import { Platform } from 'react-native';
import { useRoute } from '@react-navigation/native';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';

import { BackButton } from '~/components/BackButton/BackButton';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import { ActionButton } from '~/components/ActionButton/ActionButton';
import { useScrollPostion } from '~/hooks/useScrollPostion';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import type { RootStackRouteProp } from '~/routes/routes.types';

import { How } from './components/How/How';
import { Who } from './components/Who/Who';
import { ScrollArea, Content, Menu } from './MoreInfo.styles';

const FOCUS_KEY = 'more-info-how-works';

const MoreInfoComponent = (props: FocusableElement) => {
  const { setFocus, stealFocus, updateAllSpatialLayouts } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const route = useRoute<RootStackRouteProp<'MoreInfo'>>();
  const { how, who } = route.params;

  const { t } = useTranslation();
  const { scrollRef, scrollProps, handleElementFocus, scrollToTop } =
    useScrollPostion();

  const [selectedInfo, setSelectedInfo] = useState<string>('HOW');

  useEffect(() => {
    if (Platform.OS === 'web' && setFocus) {
      setFocus(FOCUS_KEY);
    }
  }, [setFocus]);

  return (
    <ScreenBackground>
      <ScrollArea ref={scrollRef as any} {...scrollProps}>
        <BackButton handleFocus={scrollToTop} />

        <Menu>
          <ActionButton
            rightGap
            focusKey={FOCUS_KEY}
            hasTVPreferredFocus
            text={t('program.how_it_works', 'Como Funciona')}
            onBecameFocused={(event) => {
              setSelectedInfo('HOW');
              scrollToTop(event);
            }}
          />
          <ActionButton
            text={t('program.who_will_help', 'Quem vai te ajudar')}
            onBecameFocused={(event) => {
              setSelectedInfo('WHO');
              scrollToTop(event);
            }}
          />
        </Menu>

        <Content>
          {selectedInfo === 'HOW' ? (
            <How data={how} onBecameFocused={handleElementFocus} />
          ) : (
            <Who data={who} />
          )}
        </Content>
      </ScrollArea>
    </ScreenBackground>
  );
};

export const MoreInfo = withFocusable({ blockNavigationOut: true })(
  MoreInfoComponent
);
