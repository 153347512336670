import { Platform } from 'react-native';
import styled from 'styled-components/native';

export const RedText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: red;
  margin-top: ${() => (Platform.OS === 'web' ? '6px' : '2px')};
  font-size: ${() => (Platform.OS === 'web' ? '16px' : '14px')};
`;
export const WhiteText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: white;
  margin-top: ${() => (Platform.OS === 'web' ? '6px' : '2px')};
  font-size: ${() => (Platform.OS === 'web' ? '16px' : '14px')};
`;
