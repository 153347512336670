import React, { useCallback, useEffect, useState } from 'react';
import {
  FocusableElement,
  withFocusable,
} from '@noriginmedia/react-spatial-navigation';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { Crashlytics } from '~/utils/crashlytics';
import { fetchAllInstructors } from '~/services/instructors/instructorsApi';
import { fetchDefaultAvatars } from '~/services/profiles/profilesApi';
import { BackButton } from '~/components/BackButton/BackButton';
import { FloatingLogo } from '~/components/FloatingLogo/FloatingLogo';
import { LoadingIndicator } from '~/components/LoadingIndicator/LoadingIndicator';
import { ScreenError } from '~/components/ScreenError/ScreenError';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import type { IInstructorResponse } from '~/services/instructors/instructorsApi.types';
import type { IAvatarResponse } from '~/services/profiles/profilesApi.types';
import type {
  RootStackNavigationProp,
  RootStackRouteProp,
} from '~/routes/routes.types';

import { AvatarList } from './components/AvatarList/AvatarList';
import { Content, Title } from './ProfileAvatar.styles';

const FOCUS_KEY = 'first-avatar';

const ProfileAvatarComponent = (props: FocusableElement) => {
  const { setFocus } = props;
  const { t } = useTranslation();
  const navigation = useNavigation<RootStackNavigationProp<'ProfileAvatar'>>();
  const route = useRoute<RootStackRouteProp<'ProfileAvatar'>>();
  const { pageRequest } = route.params;

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [avatars, setAvatars] = useState<IAvatarResponse[]>();
  const [instructors, setInstructors] = useState<IInstructorResponse[]>();

  const updateAvatar = (avatar: string) =>
    navigation.navigate(pageRequest, { avatar });

  const fetchData = useCallback(async () => {
    try {
      setError(false);
      setLoading(true);

      const [avatarResponse, instructorsResponse] = await Promise.all([
        fetchDefaultAvatars(),
        fetchAllInstructors(),
      ]);
      setAvatars(avatarResponse);
      setInstructors(instructorsResponse);
      setLoading(false);
      setFocus(FOCUS_KEY);
    } catch (err) {
      setError(true);
      Crashlytics.handleException(err, 'Profile Avatar loading');
    }
  }, [setFocus]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (error) {
    return (
      <ScreenError
        message={t('common.error_occurred', 'Ocorreu um erro')}
        retry={fetchData}
      />
    );
  }

  return (
    <ScreenBackground>
      <Content>
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : (
          <>
            <BackButton />

            <FloatingLogo />

            <Title>{t('profile_avatar.title', 'Imagem do perfil')}</Title>

            <AvatarList
              focusKey={FOCUS_KEY}
              hasTVPreferredFocus
              setAvatar={updateAvatar}
              data={avatars?.map(({ avatar }) => avatar)}
              title={t('profile_avatar.subtitle_1', 'Padrão')}
            />

            <AvatarList
              setAvatar={updateAvatar}
              data={instructors?.map(({ linkImage }) => linkImage)}
              title={t('profile_avatar.subtitle_2', 'Treinadores')}
            />
          </>
        )}
      </Content>
    </ScreenBackground>
  );
};

export const ProfileAvatar = withFocusable({ blockNavigationOut: true })(
  ProfileAvatarComponent
);
