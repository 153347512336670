import React, { useCallback, useEffect, useRef } from 'react';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import type { SideMenuNavigationProp } from '~/routes/routes.types';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { useFavoritePrograms } from '~/contexts/MyListContext/MyListContext';
import { fetchAllFavoritePrograms } from '~/services/favoritePrograms/favoriteProgramsApi';
import { Crashlytics } from '~/utils/crashlytics';
import { GridCardList } from '~/components/GridCardList/GridCardList';
import { useScrollPostion } from '~/hooks/useScrollPostion';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';
import { FloatingLogo } from '~/components/FloatingLogo/FloatingLogo';
import { useSideMenu } from '~/contexts/SideMenuContext/SideMenuContext';
import { ActionButton } from '~/components/ActionButton/ActionButton';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';

import {
  ScrollArea,
  ProgramsContainer,
  EmptyList,
  EmptyListTitle,
  EmptyListText,
  ButtonContainer,
} from './MyList.styles';

const FIRST_ITEM_KEY = 'my-list-first-program';
const CTA_BUTTON_KEY = 'my-list-cta-button';

const MyListComponent = (props: FocusableElement) => {
  const { setFocus, stealFocus, updateAllSpatialLayouts } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const { t } = useTranslation();
  const navigation = useNavigation<SideMenuNavigationProp<'MyList'>>();
  const { favoritePrograms, loadFavoriteList } = useFavoritePrograms();
  const { scrollRef, scrollProps, handleElementFocus } = useScrollPostion();
  const callToActionProps = useBlockFocusDirection(['up', 'down', 'right']);
  const { disableSideMenuFocus } = useSideMenu();
  const firstRender = useRef(true);

  const syncData = useCallback(async () => {
    try {
      const favsResponse = await fetchAllFavoritePrograms();
      loadFavoriteList(favsResponse.map((favorite) => favorite.program[0]));
    } catch (err) {
      Crashlytics.handleException(err, 'Sync favorites programs');
    }
  }, [loadFavoriteList]);

  useEffect(() => {
    syncData();
  }, [syncData]);

  useFocusEffect(
    useCallback(() => {
      if (favoritePrograms.length === 0) {
        setFocus(CTA_BUTTON_KEY);
      } else if (firstRender.current) {
        firstRender.current = false;
        setFocus(FIRST_ITEM_KEY);
      }
    }, [favoritePrograms.length, setFocus])
  );

  const handleSelectProgram = (id: number) => {
    navigation.navigate('Program', { id });
  };

  const handleCallToAction = () => {
    disableSideMenuFocus();
    setTimeout(() => {
      navigation.navigate('SideMenuNavigator', { screen: 'Home' });
    }, 100);
  };

  return (
    <ScreenBackground>
      <FloatingLogo />

      {favoritePrograms.length > 0 ? (
        <ScrollArea ref={scrollRef as any} {...scrollProps}>
          <ProgramsContainer>
            <GridCardList
              focusKey={FIRST_ITEM_KEY}
              hasTVPreferredFocus
              columns={4}
              name={t('common.my_list', 'Minha Lista')}
              onBecameFocused={handleElementFocus}
              cards={favoritePrograms.map((card) => ({
                id: card.id,
                name: card.name,
                imageURL: card.imageCard,
                onSelectCard: () => handleSelectProgram(card.id),
              }))}
            />
          </ProgramsContainer>
        </ScrollArea>
      ) : (
        <EmptyList>
          <EmptyListTitle>
            {t('my_list.empty_title', 'Inclua treinos na lista')}
          </EmptyListTitle>
          <EmptyListText>
            {t(
              'my_list.empty_text_one',
              'Você ainda não salvou nada na lista.'
            )}
          </EmptyListText>
          <EmptyListText>
            {t(
              'my_list.empty_text_two',
              'Acesse os programas e adicione seus preferidos!'
            )}
          </EmptyListText>
          <ButtonContainer>
            <ActionButton
              hasTVPreferredFocus
              focusKey={CTA_BUTTON_KEY}
              text={t('my_list.empty_call_to_action', 'Explorar programas')}
              onPress={handleCallToAction}
              onEnterPress={handleCallToAction}
              {...callToActionProps}
            />
          </ButtonContainer>
        </EmptyList>
      )}
    </ScreenBackground>
  );
};

export const MyList = withFocusable()(MyListComponent);
