import React from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar } from '~/components/Avatar/Avatar';
import { baseAssetsUrl } from '~/configs/env';

import {
  Container,
  Content,
  HeaderText,
  ContentAvatar,
  TextDescription,
  TitleDescription,
} from './Who.styles';
import { IWho } from './Who.types';

const BASE_URL = baseAssetsUrl + '/';

export const Who = ({ data }: IWho) => {
  const { linkImage, name, description } = data;
  const { t } = useTranslation();

  return (
    <Container>
      <HeaderText>
        {t('program.who_will_help', 'Quem vai te ajudar')}
      </HeaderText>
      <Content>
        <ContentAvatar>
          <Avatar size={300} altText={name} image={`${BASE_URL + linkImage}`} />
        </ContentAvatar>
        <TitleDescription>{name}</TitleDescription>
        <TextDescription>{description}</TextDescription>
      </Content>
    </Container>
  );
};
