import styled from 'styled-components/native';

import { verticalScale, horizontalScale } from '~/utils/layout';
import {
  HORIZONTAL_SAFE_ZONE,
  SPACING_FROM_SIDE_MENU,
  CARD_DIMENSIONS,
} from '~/utils/constants';
import type { ISideMenuOnScreen } from '~/hooks/useSideMenuOnScreen';

export const Container = styled.View`
  width: 100%;
`;

export const Title = styled.Text<ISideMenuOnScreen>`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  padding-left: ${({ sideMenuOnScreen }) =>
    sideMenuOnScreen ? SPACING_FROM_SIDE_MENU : HORIZONTAL_SAFE_ZONE}px;
  font-size: ${verticalScale(36)}px;
`;

export const Subtitle = styled.Text<{ dense?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(27)}px;
  margin-top: ${verticalScale(16)}px;
  width: ${({ dense }) =>
    dense ? CARD_DIMENSIONS.denseWidth : CARD_DIMENSIONS.width}px;
`;

export const CardList = styled.ScrollView.attrs<ISideMenuOnScreen>(
  ({ sideMenuOnScreen }) => ({
    contentContainerStyle: {
      paddingTop: verticalScale(24),
      paddingBottom: verticalScale(48),
      paddingRight: HORIZONTAL_SAFE_ZONE,
      paddingLeft: sideMenuOnScreen
        ? SPACING_FROM_SIDE_MENU
        : HORIZONTAL_SAFE_ZONE,
    },
  })
)<ISideMenuOnScreen>``;

export const SEPARATOR_SIZE = horizontalScale(48);

export const CardContainer = styled.View<{ isLastCard: boolean }>`
  margin-right: ${({ isLastCard }) => (isLastCard ? 0 : SEPARATOR_SIZE)}px;
`;
