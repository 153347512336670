import React from 'react';
import Svg, { G, Rect } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';

export const PauseIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const { width = 27, height = 39, fill = colors.text.default } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 27 39">
      <G fill={fill} fillRule="evenodd">
        <Rect width="8" height="39" rx="4" />
        <Rect width="8" height="39" x="19" rx="4" />
      </G>
    </Svg>
  );
};
