import { useEffect, useCallback } from 'react';

import { CommonKeyMap, TizenKeyMap } from '~/utils/controllerKeyMap';

interface IUseMediaButtonsProps {
  isPlaying: boolean;
  togglePlayPause: () => void;
  onMediaButtonPress: () => void;
}

export const useMediaButtons = (props: IUseMediaButtonsProps) => {
  const { isPlaying, togglePlayPause, onMediaButtonPress } = props;

  const handleMediaButtonPress = useCallback(() => {
    togglePlayPause();
    onMediaButtonPress();
  }, [onMediaButtonPress, togglePlayPause]);

  useEffect(() => {
    const handleKeyDown = (event: globalThis.KeyboardEvent) => {
      const keyPressed = event?.which || event?.keyCode;

      switch (keyPressed) {
        case TizenKeyMap.MediaPlayPause:
          handleMediaButtonPress();
          return;
        case CommonKeyMap.MediaPlay:
          if (!isPlaying) {
            handleMediaButtonPress();
          }
          return;
        case CommonKeyMap.MediaPause:
          if (isPlaying) {
            handleMediaButtonPress();
          }
          return;
      }
    };

    if (window.tizen) {
      document.body.addEventListener('keydown', handleKeyDown);
    } else {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (window.tizen) {
        document.body.removeEventListener('keydown', handleKeyDown);
      } else {
        document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [handleMediaButtonPress, isPlaying]);
};
