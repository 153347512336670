import React from 'react';
import { Svg, Path, G } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';

export const SearchIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const { width = 32, height = 32, fill = colors.text.default } = props;

  return (
    <Svg width={width} height={height} viewBox={'0 0 44 44'}>
      <G fill="none" fillRule="evenodd">
        <Path d="M-8-8h64v64H-8z" />
        <Path
          fill={fill}
          d="M33.333 29.333h-2.106l-.747-.72a17.334 17.334 0 0 0 3.947-14.24C33.173 6.96 26.987 1.04 19.52.133 8.24-1.253-1.253 8.24.133 19.52c.907 7.467 6.827 13.653 14.24 14.907a17.334 17.334 0 0 0 14.24-3.947l.72.747v2.106l11.334 11.334a2.817 2.817 0 0 0 3.973 0 2.817 2.817 0 0 0 0-3.974l-11.307-11.36zm-16 0c-6.64 0-12-5.36-12-12s5.36-12 12-12 12 5.36 12 12-5.36 12-12 12z"
        />
      </G>
    </Svg>
  );
};
