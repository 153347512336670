import type {
  IBannerResponse,
  IProgramsResponse,
  IExternalProgram,
} from '~/services/programs/programsApi.types';
import { getSources } from '~/utils/functions';

import type { IHomeBannerState } from '../Home.types';

type Program = {
  isFeatured: boolean;
  data: IBannerResponse | IExternalProgram;
};

export const formatBannerData = (program: Program): IHomeBannerState => {
  const { isFeatured, data } = program;

  const streamMetadata = (data as any)?.streamMetadata;

  return {
    isFeatured,
    id: data.id,
    description: data.description,
    bannerURL: data.imageBanner,
    logoNameURL: data.imageLogo,
    level: data.levels.name,
    levelId: data.levels.id,
    objective: data.objectives.name,
    duration: data.metadata.meanDuration,
    previewSources: streamMetadata
      ? getSources({
          streamMetadataHls: streamMetadata?.hls,
          streamMetadataHd: streamMetadata?.hd,
        })
      : undefined,
  };
};

export const formatProgramsResponse = (
  data: IProgramsResponse[],
  type: 'recommended' | 'all_programs'
) => {
  return data
    .filter((category) => category.programs.length > 0)
    .map((category) => ({
      id: category.id + type,
      name: category.name,
      cards: category.programs.map((program) => ({
        id: program.id,
        imageURL: program.imageCard,
        name: program.name,
        programData: program,
      })),
    }));
};
