import styled, { css } from 'styled-components/native';

import { verticalScale, horizontalScale } from '~/utils/layout';
import { FocusableButton } from '../FocusableButton/FocusableButton';

import type { IKeyboardGap, IKeyboardKey } from './Keyboard.types';

const KEYBOARD_ROWS = 10;
const KEYBOARD_GAP = verticalScale(4);

const MIN_WIDTH = horizontalScale(52);
export const KEYBOARD_WIDTH =
  MIN_WIDTH * KEYBOARD_ROWS + KEYBOARD_GAP * (KEYBOARD_ROWS - 1);

export const Container = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  width: ${KEYBOARD_WIDTH}px;
`;

export const KeyRow = styled.View<IKeyboardGap>`
  flex-direction: row;
  width: ${KEYBOARD_WIDTH}px;
  margin-bottom: ${({ hasGap }) => (hasGap ? KEYBOARD_GAP : 0)}px;
`;

export const KeyboardKey = styled(FocusableButton).attrs<IKeyboardKey>(
  ({ theme: { colors } }) => ({
    textStyle: { fontSize: verticalScale(18) },
    textColor: colors.keyboard.keyFocused,
    focusedTextColor: colors.keyboard.keyFocused,
    borderColor: colors.keyboard.keyFocused,
    bgColor: colors.keyboard.keyBackground,
    bgFocusedColor: colors.keyboard.spatialFocus,
  })
)<IKeyboardKey>`
  font-family: ${({ theme }) => theme.fonts.regular};
  margin-right: ${({ hasGap }) => (hasGap ? KEYBOARD_GAP : 0)}px;
  border-width: ${({ selected }) => (selected ? verticalScale(1) : 0)}px;
  border-radius: ${verticalScale(4)}px;
  height: ${verticalScale(44)}px;
  ${({ columns = 1 }) => {
    if (columns === 'auto') {
      return css`
        flex: 1;
        min-width: ${MIN_WIDTH}px;
      `;
    }

    return css`
      width: ${columns * MIN_WIDTH + (columns - 1) * KEYBOARD_GAP}px;
    `;
  }}
`;
