import { RefObject } from 'react';
import {
  Platform,
  ReturnKeyTypeOptions,
  TextInput,
  TextInputProps,
} from 'react-native';
import { FocusableElement } from '@noriginmedia/react-spatial-navigation';

export enum FocusIn {
  StartIcon = 'start-icon',
  Input = 'input',
  EndIcon = 'end-icon',
}

export interface CustomTextInput extends TextInput {
  getNode: () => number | null;
}

export type FocusableInputRef = CustomTextInput | HTMLInputElement;

export interface IFocusableInputProps extends TextInputProps {
  focusColor?: string;
  returnKeyType?: ReturnKeyTypeOptions;
  type?: string;
  secureTextEntry?: boolean;
  onSubmitEditing?: () => void | Promise<void>;
  error?: string;
  name?: string;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  onClickEndIcon?: () => void;
  onClickStartIcon?: () => void;
  innerRef?: RefObject<FocusableInputRef>;
}

export interface IFocusableInput
  extends IFocusableInputProps,
    FocusableElement {}

export interface ITextField {
  focused: boolean;
  focusColor: string;
  startIcon: boolean;
  endIcon: boolean;
}

export interface IconContainer {
  focused: boolean;
  focusColor: string;
}

export const isNativeInput = (
  ref: FocusableInputRef
): ref is CustomTextInput => {
  return ref && Platform.OS !== 'web';
};
