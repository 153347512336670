import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';
import {
  VERTICAL_SAFE_ZONE,
  HORIZONTAL_SAFE_ZONE,
  SPACING_FROM_SIDE_MENU,
  FLOATING_LOGO_HEIGHT,
} from '~/utils/constants';

export const ScrollArea = styled.ScrollView`
  height: 100%;
`;

export const ProgramsContainer = styled.View`
  padding: ${FLOATING_LOGO_HEIGHT}px ${HORIZONTAL_SAFE_ZONE}px
    ${VERTICAL_SAFE_ZONE}px ${SPACING_FROM_SIDE_MENU}px;
`;

export const EmptyList = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const EmptyListTitle = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(36)}px;
  margin-bottom: ${verticalScale(40)}px;
`;

export const EmptyListText = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(24)}px;
`;

export const ButtonContainer = styled.Text`
  margin-top: ${verticalScale(48)}px;
`;
