import React from 'react';
import { Path, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';
import { verticalScale } from '~/utils/layout';

export const EyeSlashIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const {
    width = verticalScale(44),
    height = verticalScale(40),
    fill = colors.text.default,
  } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 44 40" fill="none">
      <Path
        d="M22 8.00002C29.58 8.00002 36.34 12.26 39.64 19C38.46 21.44 36.8 23.54 34.82 25.24L37.64 28.06C40.42 25.6 42.62 22.52 44 19C40.54 10.22 32 4.00002 22 4.00002C19.46 4.00002 17.02 4.40002 14.72 5.14002L18.02 8.44002C19.32 8.18002 20.64 8.00002 22 8.00002ZM19.86 10.28L24 14.42C25.14 14.92 26.06 15.84 26.56 16.98L30.7 21.12C30.86 20.44 30.98 19.72 30.98 18.98C31 14.02 26.96 10 22 10C21.26 10 20.56 10.1 19.86 10.28ZM2.02 3.74002L7.38 9.10003C4.12 11.66 1.54 15.06 0 19C3.46 27.78 12 34 22 34C25.04 34 27.96 33.42 30.64 32.36L37.48 39.2L40.3 36.38L4.84 0.900024L2.02 3.74002ZM17.02 18.74L22.24 23.96C22.16 23.98 22.08 24 22 24C19.24 24 17 21.76 17 19C17 18.9 17.02 18.84 17.02 18.74ZM10.22 11.94L13.72 15.44C13.26 16.54 13 17.74 13 19C13 23.96 17.04 28 22 28C23.26 28 24.46 27.74 25.54 27.28L27.5 29.24C25.74 29.72 23.9 30 22 30C14.42 30 7.66 25.74 4.36 19C5.76 16.14 7.8 13.78 10.22 11.94Z"
        fill={fill}
      />
    </Svg>
  );
};
