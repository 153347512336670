import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import {
  SideMenuProvider,
  useSideMenu,
} from '~/contexts/SideMenuContext/SideMenuContext';
import { SideMenu } from '~/components/SideMenu/SideMenu';
import { SIDEMENU_WIDTH } from '~/components/SideMenu/SideMenu.styles';
import { Home } from '~/screens/Home/Home';
import { Search } from '~/screens/Search/Search';
import { MyList } from '~/screens/MyList/MyList';
import { Help } from '~/screens/Help/Help';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';

import type { SideMenuParams } from './routes.types';

const Tab = createBottomTabNavigator<SideMenuParams>();

const HomePage = () => <Home />;
const SearchPage = () => <Search />;
const MyListPage = () => <MyList />;
const HelpPage = () => <Help />;

export const SideMenuRoutes = () => {
  const { isSideMenuEnabled } = useSideMenu();

  return (
    <ScreenBackground>
      <Tab.Navigator
        tabBar={(props) => (
          <SideMenu focusable={isSideMenuEnabled} {...props} />
        )}
        sceneContainerStyle={{ marginLeft: SIDEMENU_WIDTH }}
        screenOptions={{ unmountOnBlur: true }}
        backBehavior="none"
      >
        <Tab.Screen name="Home" component={HomePage} />
        <Tab.Screen name="Search" component={SearchPage} />
        <Tab.Screen name="MyList" component={MyListPage} />
        <Tab.Screen name="Help" component={HelpPage} />
      </Tab.Navigator>
    </ScreenBackground>
  );
};

export const SideMenuNavigator = () => (
  <SideMenuProvider>
    <SideMenuRoutes />
  </SideMenuProvider>
);
