import React, { useState, useRef, createRef, useCallback } from 'react';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useTheme } from 'styled-components/native';
import { StackActions, useIsFocused } from '@react-navigation/native';

import { baseAssetsUrl } from '~/configs/env';
import { HomeIcon } from '~/assets/icons/HomeIcon';
import { SearchIcon } from '~/assets/icons/SearchIcon';
import { PlusIcon } from '~/assets/icons/PlusIcon';
import { HelpIcon } from '~/assets/icons/HelpIcon';
import { verticalScale } from '~/utils/layout';
import { useSession } from '~/contexts/SessionContext/SessionContext';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';
import { useBackHandler } from '~/hooks/useBackHandler';
import { Avatar } from '../Avatar/Avatar';
import { isNativeButton } from '../FocusableButton/FocusableButton';

import { useSideMenuFocusProps } from './hooks/useSideMenuFocusProps';
import { SideMenuItem } from './components/SideMenuItem/SideMenuItem';
import { SideMenuFooter } from './components/SideMenuFooter/SideMenuFooter';
import {
  Container,
  GradientOverlay,
  Header,
  StyledFocusableButton,
  Username,
  Content,
} from './SideMenu.styles';

import type {
  ISideMenu,
  SideMenuItemsMap,
  SideMenuRouteNames,
} from './SideMenu.types';

const ICON_SIZE = verticalScale(30);

export const MENU_ITEMS_MAP: SideMenuItemsMap = {
  Home: {
    focusKey: 'side-menu-home',
    innerRef: createRef(),
    getLabel: (t) => t('common.home', 'Home'),
    Icon: HomeIcon,
  },
  Search: {
    focusKey: 'side-menu-search',
    innerRef: createRef(),
    getLabel: (t) => t('common.search', 'Buscar'),
    Icon: SearchIcon,
  },
  MyList: {
    focusKey: 'side-menu-my_list',
    innerRef: createRef(),
    getLabel: (t) => t('common.my_list', 'Minha Lista'),
    Icon: PlusIcon,
  },
  Help: {
    focusKey: 'side-menu-help',
    innerRef: createRef(),
    getLabel: (t) => t('common.help', 'Ajuda'),
    Icon: HelpIcon,
  },
};

export const SideMenuComponent = (props: ISideMenu) => {
  const {
    setFocus,
    navigation,
    state,
    focusable: isMenuFocusable,
    navigateByDirection,
  } = props;

  const { t } = useTranslation();
  const { colors } = useTheme();
  const { currentUser } = useSession();
  const isNavigatorFocused = useIsFocused();

  const changeUserProps = useBlockFocusDirection('up');
  const { changeUserRef, logoutRef } = useSideMenuFocusProps({
    setFocus,
    MENU_ITEMS_MAP,
  });

  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const blurTimeout = useRef<NodeJS.Timeout | null>(null);
  const activeRouteRef = useRef<SideMenuRouteNames | null>(null);

  const enableCustomBackButton =
    isNavigatorFocused &&
    ((Platform.OS !== 'web' && !isMenuExpanded) ||
      (Platform.OS === 'web' && isMenuExpanded));

  useBackHandler(
    useCallback(() => {
      if (!activeRouteRef.current) {
        return;
      }

      const targetItem = MENU_ITEMS_MAP[activeRouteRef.current];
      const ref = targetItem.innerRef;

      if (Platform.OS === 'web') {
        navigateByDirection('right');
      } else if (ref.current !== null && isNativeButton(ref.current)) {
        ref.current?.setNativeProps({
          hasTVPreferredFocus: true,
        });
      }
    }, [navigateByDirection]),
    enableCustomBackButton
  );

  const onItemFocus = () => {
    if (blurTimeout.current) {
      clearTimeout(blurTimeout.current);
      blurTimeout.current = null;
    }
    setIsMenuExpanded(true);
  };

  const onItemBlur = () => {
    blurTimeout.current = setTimeout(() => {
      setIsMenuExpanded(false);
    }, 50);
  };

  const changeUser = () => {
    if (
      currentUser?.multipleProfile?.name === 'Parent' ||
      currentUser?.multipleProfile?.name === 'Child'
    ) {
      navigation.dispatch(StackActions.replace('ProfileList'));
    }
  };

  const handleNavigationTo = (routeName: SideMenuRouteNames) => {
    setIsMenuExpanded(false);
    navigation.navigate(routeName);
  };

  const handleIconColor = (focused: boolean, isCurrentRoute: boolean) => {
    if (focused && isMenuExpanded) {
      return colors.spatialFocus;
    }
    if (isCurrentRoute) {
      return colors.text.default;
    }
    return colors.sideMenu.icon;
  };

  const handleItemFocusState = (isCurrentRoute: boolean) => {
    if (isMenuFocusable && !isMenuExpanded && isCurrentRoute) {
      return true;
    }
    if (isMenuFocusable && isMenuExpanded) {
      return true;
    }
    return false;
  };

  return (
    <Container isMenuExpanded={isMenuExpanded}>
      {isMenuExpanded && <GradientOverlay />}

      <Header isMenuExpanded={isMenuExpanded}>
        <StyledFocusableButton
          focusable={isMenuExpanded}
          onBecameFocused={onItemFocus}
          onBecameBlurred={onItemBlur}
          onPress={changeUser}
          onEnterPress={changeUser}
          innerRef={changeUserRef}
          {...changeUserProps}
        >
          <Avatar
            image={
              currentUser?.image
                ? `${baseAssetsUrl}/${currentUser?.image}`
                : undefined
            }
            altText={currentUser?.name || '-'}
            size={48}
          />
        </StyledFocusableButton>
        <Username numberOfLines={2}>{currentUser?.name}</Username>
      </Header>

      <Content>
        {state.routes.map((route, index) => {
          const isCurrentRoute = state.index === index;
          const routeName = route.name as SideMenuRouteNames;
          const targetItem = MENU_ITEMS_MAP[routeName];
          if (isCurrentRoute) {
            activeRouteRef.current = routeName;
          }

          const Icon = targetItem.Icon;
          const isItemFocusable = handleItemFocusState(isCurrentRoute);
          const onPress = () => {
            if (!isCurrentRoute) {
              handleNavigationTo(routeName);
            }
          };

          return (
            <SideMenuItem
              key={route.key}
              focusable={isItemFocusable}
              focusKey={targetItem.focusKey}
              innerRef={targetItem.innerRef}
              isMenuExpanded={isMenuExpanded}
              onFocus={onItemFocus}
              onBlur={onItemBlur}
              onPress={onPress}
              text={targetItem.getLabel(t)}
              isCurrentRoute={isCurrentRoute}
              icon={(focused) => (
                <Icon
                  fill={handleIconColor(focused, isCurrentRoute)}
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                />
              )}
            />
          );
        })}
      </Content>

      <SideMenuFooter
        setFocus={setFocus}
        isMenuExpanded={isMenuExpanded}
        onItemFocus={onItemFocus}
        onItemBlur={onItemBlur}
        iconSize={ICON_SIZE}
        innerRef={logoutRef}
      />
    </Container>
  );
};

export const SideMenu = withFocusable({
  forgetLastFocusedChild: true,
})(SideMenuComponent);
