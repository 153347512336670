import { useRef } from 'react';
import {
  Platform,
  ScrollView,
  findNodeHandle,
  Dimensions,
  UIManager,
  LayoutChangeEvent,
} from 'react-native';

import { SEPARATOR_SIZE } from '../HorizontalCardList.styles';
import {
  HORIZONTAL_SAFE_ZONE,
  SPACING_FROM_SIDE_MENU,
  CARD_DIMENSIONS,
} from '~/utils/constants';

import type {
  ICalculateOffsetX,
  OnScrollEvent,
  IHandleCardFocus,
} from './useHorizontalCardList.types';

const { width: SCREEN_WIDTH } = Dimensions.get('screen');

const calculateOffsetX = (props: ICalculateOffsetX): number | null => {
  const { target, cardIndex, denseThreshold, visibleWidth } = props;

  const cardViewBox =
    (denseThreshold ? CARD_DIMENSIONS.denseWidth : CARD_DIMENSIONS.width) +
    SEPARATOR_SIZE;

  const leftThreshold = denseThreshold
    ? SPACING_FROM_SIDE_MENU
    : HORIZONTAL_SAFE_ZONE;

  if (target < leftThreshold) {
    const offsetX = cardViewBox * cardIndex;
    return offsetX;
  }

  const visibleCards = Math.floor(visibleWidth / cardViewBox);
  const rightThreshold = HORIZONTAL_SAFE_ZONE;

  if (target > visibleWidth - rightThreshold) {
    const positionIndex = cardIndex - (visibleCards - 1);
    const offsetX = cardViewBox * positionIndex;
    return offsetX;
  }

  return null;
};

export const useHorizontalCardList = (denseThreshold?: boolean) => {
  const scrollRef = useRef<ScrollView>(null);
  const scrollPositionRef = useRef<number>(0);
  const visibleWidth = useRef(SCREEN_WIDTH);

  const onScroll = ({ nativeEvent }: OnScrollEvent) => {
    scrollPositionRef.current = nativeEvent.contentOffset.x;
  };

  const onLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    visibleWidth.current = nativeEvent.layout.width || visibleWidth.current;
  };

  const handleCardFocus = (props: IHandleCardFocus) => {
    const { element, cardIndex } = props;

    if (Platform.OS === 'web') {
      const offsetX = calculateOffsetX({
        target: element.left,
        cardIndex,
        denseThreshold,
        visibleWidth: visibleWidth.current,
      });

      if (offsetX !== null) {
        scrollRef.current?.scrollTo({
          animated: true,
          y: 0,
          x: offsetX,
        });
      }

      return;
    }

    const elementNode = findNodeHandle(element.node as any);
    const scrollNode = findNodeHandle(scrollRef.current);

    if (elementNode === null || scrollNode === null) {
      return;
    }

    UIManager.measureLayout(
      elementNode,
      scrollNode,
      () => {},
      (left) => {
        const target = left - scrollPositionRef.current;
        const offsetX = calculateOffsetX({
          target,
          cardIndex,
          denseThreshold,
          visibleWidth: visibleWidth.current,
        });

        if (offsetX !== null) {
          scrollRef.current?.scrollTo({
            animated: true,
            y: 0,
            x: offsetX,
          });
        }
      }
    );
  };

  return {
    handleCardFocus,
    getScrollViewProps: () => ({
      ref: scrollRef as any,
      onLayout,
      onScroll,
      scrollEventThrottle: 16,
      horizontal: true,
      showsHorizontalScrollIndicator: false,
    }),
  };
};
