import { useRef, useState, useCallback, useEffect } from 'react';

export const useInactivity = (timeForInactivity = 5000) => {
  const [isUserActive, setIsUserActive] = useState<boolean>(false);
  const timer = useRef<NodeJS.Timeout | null>(null);

  const handleActivity = useCallback(() => {
    if (timer?.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      setIsUserActive(false);
    }, timeForInactivity);

    setIsUserActive(true);
  }, [timeForInactivity]);

  useEffect(() => {
    const throttledMouseMove = () => {
      let waiting = false;
      return () => {
        if (!waiting) {
          handleActivity();
          waiting = true;
          setTimeout(() => {
            waiting = false;
          }, 1000);
        }
      };
    };

    const callback = throttledMouseMove();
    document.addEventListener('mousemove', callback);

    return () => {
      document.removeEventListener('mousemove', callback);
    };
  }, [handleActivity]);

  useEffect(() => {
    const handleKeyboardEvent = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowLeft':
        case 'ArrowRight':
        case 'ArrowUp':
        case 'ArrowDown':
        case 'Enter':
          handleActivity();
          break;
      }
    };

    document.addEventListener('keydown', handleKeyboardEvent, false);

    return () => {
      document.removeEventListener('keydown', handleKeyboardEvent, false);
    };
  }, [handleActivity]);

  useEffect(() => {
    return () => {
      if (timer?.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return { isUserActive, handleCustomActivity: handleActivity };
};
