/**
 * Colors using only RGB format
 *
 * @link https://www.figma.com/file/F63Ebh6CqqT81OCrxCFDhr/(NOVO)-Style-Guide?node-id=2292%3A27583
 */
export const palette = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  whiteOpacity: {
    20: 'rgba(255, 255, 255, 0.2)',
  },
  gray: {
    50: 'rgb(250, 250, 250)',
    100: 'rgb(245, 245, 245)',
    200: 'rgb(238, 238, 238)',
    300: 'rgb(224, 224, 224)',
    400: 'rgb(189, 189, 189)',
    500: 'rgb(158, 158, 158)',
    600: 'rgb(117, 117, 117)',
    700: 'rgb(97, 97, 97)',
    800: 'rgb(66, 66, 66)',
    900: 'rgb(33, 33, 33)',
  },
  primary: {
    25: 'rgb(255, 246, 236)',
    100: 'rgb(255, 236, 211)',
    200: 'rgb(255, 212, 165)',
    300: 'rgb(255, 181, 109)',
    400: 'rgb(255, 138, 50)',
    500: 'rgb(255, 105, 10)',
    600: 'rgb(255, 79, 0)',
    700: 'rgb(204, 54, 2)',
    800: 'rgb(161, 43, 11)',
    900: 'rgb(130, 38, 12)',
  },
  error: {
    50: 'rgb(255, 239, 242)',
    100: 'rgb(255, 220, 226)',
    200: 'rgb(255, 191, 203)',
    300: 'rgb(255, 146, 166)',
    400: 'rgb(255, 84, 115)',
    500: 'rgb(255, 31, 72)',
    600: 'rgb(255, 0, 46)',
    700: 'rgb(219, 0, 40)',
    800: 'rgb(176, 0, 32)',
    900: 'rgb(148, 8, 33)',
  },
  alert: {
    100: 'rgb(254, 240, 199)',
    200: 'rgb(254, 223, 137)',
    300: 'rgb(254, 200, 75)',
    400: 'rgb(253, 176, 34)',
    500: 'rgb(247, 144, 9)',
    600: 'rgb(220, 104, 3)',
    700: 'rgb(181, 71, 8)',
    800: 'rgb(147, 55, 13)',
    900: 'rgb(122, 46, 14)',
  },
  success: {
    50: 'rgb(241, 252, 243)',
    100: 'rgb(223, 249, 230)',
    200: 'rgb(192, 242, 206)',
    300: 'rgb(143, 230, 167)',
    400: 'rgb(86, 210, 121)',
    500: 'rgb(48, 183, 87)',
    600: 'rgb(36, 161, 72)',
    700: 'rgb(30, 119, 56)',
    800: 'rgb(29, 94, 49)',
    900: 'rgb(25, 78, 42)',
  },
  link: {
    50: 'rgb(239, 245, 255)',
    100: 'rgb(157, 195, 255)',
    200: 'rgb(116, 170, 255)',
    300: 'rgb(76, 145, 255)',
    400: 'rgb(35, 121, 255)',
    500: 'rgb(0, 97, 249)',
    600: 'rgb(0, 75, 193)',
    700: 'rgb(0, 53, 137)',
    800: 'rgb(0, 31, 81)',
    900: 'rgb(0, 10, 25)',
  },
};
