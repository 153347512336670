import { api } from '~/configs/api';

import type {
  IProgramsResponse,
  IBannerResponse,
  INextLectureResponse,
  IProgramResponse,
  IBonusProgram,
  ISearchProgramsResponse,
  ISearchProgramsParams,
  IFullWatchedContentMetadata,
  IClosedCaptionsResponse,
} from './programsApi.types';

export const fetchBanner = async () => {
  const { data } = await api.get<IBannerResponse[]>('/programs/programs/', {
    params: {
      filter: {
        where: { publicated: true, typeProgramId: 1 },
        order: 'releaseDate desc',
        limit: '1',
        include: [
          { relation: 'tags', scope: { fields: ['name', 'expressionId'] } },
          {
            relation: 'levels',
            scope: { fields: ['name', 'expressionId'] },
          },
          {
            relation: 'objectives',
            scope: { fields: ['name', 'expressionId'] },
          },
          {
            relation: 'typePrograms',
            scope: { fields: ['name', 'expressionId'] },
          },
          {
            relation: 'instructors',
            scope: { fields: ['id', 'name', 'description', 'linkImage'] },
          },
        ],
      },
    },
  });
  return data;
};

export const fetchAllPrograms = async () => {
  const { data } = await api.get<IProgramsResponse[]>('/programs/categories', {
    params: {
      filter: {
        fields: ['id', 'name', 'expressionId'],
        include: [
          {
            relation: 'programs',
            scope: {
              where: { publicated: true, typeProgramId: { inq: [1, 5] } },
              fields: [
                'id',
                'alias',
                'name',
                'description',
                'legacyId',
                'imageCard',
                'imageVertical',
                'imageBanner',
                'imageLogo',
                'typeProgramId',
                'objectiveId',
                'levelId',
                'metadata',
              ],
              order: 'releaseDate desc',
              include: [
                {
                  relation: 'instructors',
                  scope: { fields: ['id', 'name'] },
                },
                {
                  relation: 'levels',
                  scope: { fields: ['name', 'expressionId'] },
                },
                {
                  relation: 'objectives',
                  scope: { fields: ['name', 'expressionId'] },
                },
              ],
            },
          },
        ],
      },
    },
  });
  return data;
};

export const fetchRecommended = async () => {
  const { data } = await api.get<IProgramsResponse[]>(
    '/programs/programs/new-recommended'
  );
  return data;
};

export const fetchProgram = async (id: string) => {
  const filter = {
    include: [
      'levels',
      'objectives',
      'instructors',
      {
        relation: 'pages',
        scope: {
          include: [
            {
              relation: 'modules',
              scope: {
                fields: ['id', 'name'],
                include: [
                  {
                    relation: 'contents',
                    scope: {
                      fields: ['id', 'name', 'metadata', 'streamMetadata'],
                      order: 'order asc',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  };
  const { data } = await api.get<IProgramResponse>(`/programs/programs/${id}`, {
    params: { filter },
  });
  return data;
};

export const fetchProgramBonus = async (id: string) => {
  const filterParam = {
    where: {
      programId: id,
    },
    fields: ['bonusId'],
    include: ['bonus'],
  };
  const filterAsJson = JSON.stringify(filterParam);
  const encoded = encodeURIComponent(filterAsJson);

  const { data } = await api.get<IBonusProgram[]>(
    `/programs/bonusPrograms?filter=${encoded}`
  );
  return data;
};

export const fetchNextLecture = async (id: number) => {
  const { data } = await api.get<INextLectureResponse>(
    `/programs/userMidiaOrder/next/${id}`
  );
  return data;
};

export const trackFullWatchedContent = async ({
  mediaId,
  programId,
}: IFullWatchedContentMetadata) => {
  const analyticsData = {
    contentId: mediaId,
    programId,
    metadata: {
      device: 'tv',
      platform: 'app-tv',
      playerType: 'default',
      downloaded: false,
      connection: 'online',
    },
  };

  api.post('/programs/contentwatched/log', analyticsData);
};

export const fetchClosedCaptions = async (mediaId: number) => {
  const { data } = await api.get<IClosedCaptionsResponse | undefined>(
    `/programs/contentcaptions/${mediaId}`
  );
  return data;
};

export const searchPrograms = async (
  params: ISearchProgramsParams
): Promise<ISearchProgramsResponse[]> => {
  let { searchText, objectivesFilter, levelsFilter } = params;
  const { data } = await api.get(
    '/filterprograms/loger/search-programs/filtered',
    {
      params: {
        freeSearch: searchText,
        objectives: objectivesFilter,
        levels: levelsFilter,
      },
    }
  );

  return data;
};
