import React from 'react';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components/native';

import '../configs/i18n';
import { theme } from '../theme/theme';

import { SessionProvider } from './SessionContext/SessionContext';
import { MyListProvider } from './MyListContext/MyListContext';
import { NetworkProvider } from './NetworkContext/NetworkContext';
import { BackButtonProvider } from './BackButtonContext/BackButtonContext';
import { DeviceLimitProvider } from './DeviceLimitContext/DeviceLimitContext';

export const AppProviders: React.FC = ({ children }) => {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <NetworkProvider>
          <DeviceLimitProvider>
            <SessionProvider>
              <MyListProvider>
                <BackButtonProvider>{children}</BackButtonProvider>
              </MyListProvider>
            </SessionProvider>
          </DeviceLimitProvider>
        </NetworkProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
};
