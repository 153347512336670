import styled from 'styled-components/native';
import { Dimensions } from 'react-native';

import { verticalScale } from '~/utils/layout';
import { FocusableButton } from '~/components/FocusableButton/FocusableButton';
import { HORIZONTAL_SAFE_ZONE } from '~/utils/constants';

const { width: SCREEN_WIDTH } = Dimensions.get('screen');

export const Container = styled.View`
  width: 100%;
  margin-bottom: ${verticalScale(48)}px;
`;

export const ListTitle = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(24)}px;
  padding-left: ${HORIZONTAL_SAFE_ZONE}px;
  margin-bottom: ${verticalScale(32)}px;
`;

export const AvatarRow = styled.ScrollView.attrs({
  contentContainerStyle: {
    flexGrow: 1,
    paddingLeft: HORIZONTAL_SAFE_ZONE,
    paddingRight: SCREEN_WIDTH - HORIZONTAL_SAFE_ZONE * 2,
  },
})``;

export const AVATAR_SEPARATOR_SIZE = verticalScale(48);

export const AvatarContent = styled.View`
  margin-right: ${AVATAR_SEPARATOR_SIZE}px;
`;

export const StyledFocusableButton = styled(FocusableButton).attrs(
  ({ theme: { colors } }) => ({
    focusedBorderColor: colors.spatialFocus,
  })
)`
  border-radius: ${verticalScale(170) / 2}px;
`;
