import { buildKeysRow } from '../KeyboardTemplate/buildKeysRow';

const SPECIALS_1 = '`˜!@#$%ˆ&*';
const SPECIALS_2 = '()-_=+[]{}';
const SPECIALS_3 = '\\|;:‘“,.<>';
const SPECIALS_4 = '/?¡¿ªº₵€₤¥';

export const generateSpecialKeys = () => {
  return [
    buildKeysRow(SPECIALS_1),
    buildKeysRow(SPECIALS_2),
    buildKeysRow(SPECIALS_3),
    buildKeysRow(SPECIALS_4),
  ];
};
