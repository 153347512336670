import { useContext, useEffect } from 'react';

import { BackButtonContext } from '~/contexts/BackButtonContext/BackButtonContext';

export const useBackHandler = (customHandler: () => void, isEnabled = true) => {
  const { updateHandler } = useContext(BackButtonContext);

  useEffect(() => {
    updateHandler(
      isEnabled
        ? () => {
            customHandler();
            return true;
          }
        : null
    );

    return () => {
      updateHandler(null);
    };
  }, [isEnabled, customHandler, updateHandler]);
};
