import React from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import { initNavigation } from '@noriginmedia/react-spatial-navigation';
import { initialize } from 'react-native-clarity';
import 'react-native-gesture-handler';

import { Crashlytics } from '~/utils/crashlytics';
import { useSession } from '~/contexts/SessionContext/SessionContext';
import { Login } from '~/screens/Login/Login';
import { LoginByPhone } from '~/screens/LoginByPhone/LoginByPhone';
import { ForgotPassword } from '~/screens/ForgotPassword/ForgotPassword';
import { Program } from '~/screens/Program/Program';
import { Player } from '~/screens/Player/Player';
import { MoreInfo } from '~/screens/MoreInfo/MoreInfo';
import { SplashScreen } from '~/screens/SplashScreen/SplashScreen';
import { Instructor } from '~/screens/Instructor/Instructor';
import { ProfileList } from '~/screens/ProfileList/ProfileList';
import { ProfileManage } from '~/screens/ProfileManage/ProfileManage';
import { ProfileAdd } from '~/screens/ProfileAdd/ProfileAdd';
import { ProfileEdit } from '~/screens/ProfileEdit/ProfileEdit';
import { ProfileAvatar } from '~/screens/ProfileAvatar/ProfileAvatar';
import { ProfileDelete } from '~/screens/ProfileDelete/ProfileDelete';
import { DeviceDisconnected } from '~/screens/DeviceDisconnected/DeviceDisconnected';
import { DeviceLimit } from '~/screens/DeviceLimit/DeviceLimit';
// import { Live } from '~/screens/Live/Live';

import { isReadyRef, navigationRef, routeNameRef } from './rootNavigation';
import { SideMenuNavigator } from './SideMenuNavigator';

import type { RootStackParams } from './routes.types';

const Stack = createStackNavigator<RootStackParams>();

initNavigation({ nativeMode: Platform.OS !== 'web' });

const LoginPage = () => <Login />;
const LoginByPhonePage = () => <LoginByPhone />;
const ForgotPasswordPage = () => <ForgotPassword />;
const ProgramPage = () => <Program />;
const PlayerPage = () => <Player />;
const MoreInfoPage = () => <MoreInfo />;
const InstructorPage = () => <Instructor />;
const ProfileListPage = () => <ProfileList />;
const ProfileManagePage = () => <ProfileManage />;
const ProfileAddPage = () => <ProfileAdd />;
const ProfileEditPage = () => <ProfileEdit />;
const ProfileAvatarPage = () => <ProfileAvatar />;
const ProfileDeletePage = () => <ProfileDelete />;
const DeviceDisconnectedPage = () => <DeviceDisconnected />;
const DeviceLimitPage = () => <DeviceLimit />;
// const LivePage = () => <Live />;

export const Routes = () => {
  const { currentUser } = useSession();

  if (currentUser === undefined) {
    return <SplashScreen />;
  }
  if (Platform.OS === 'android') {
    initialize('h0ry31xvww');
  }
  return (
    <NavigationContainer
      ref={navigationRef}
      onReady={() => {
        isReadyRef.current = true;
        Crashlytics.setupNavigation(navigationRef);
      }}
      onStateChange={() => {
        const previousRouteName = routeNameRef.current || undefined;
        const currentRouteName =
          navigationRef?.current?.getCurrentRoute()?.name;

        if (previousRouteName !== currentRouteName) {
          Crashlytics.handleNavigation({
            from: previousRouteName,
            to: currentRouteName,
          });
        }

        routeNameRef.current = currentRouteName;
      }}
    >
      <Stack.Navigator headerMode="none">
        {currentUser === null ? (
          <>
            <Stack.Screen name="Login" component={LoginPage} />
            <Stack.Screen name="LoginByPhone" component={LoginByPhonePage} />
            <Stack.Screen
              name="ForgotPassword"
              component={ForgotPasswordPage}
            />
          </>
        ) : (
          <>
            {(currentUser.multipleProfile?.name === 'Parent' ||
              currentUser.multipleProfile?.name === 'Child') && (
              <>
                <Stack.Screen name="ProfileList" component={ProfileListPage} />
                <Stack.Screen
                  name="ProfileManage"
                  component={ProfileManagePage}
                />
                <Stack.Screen name="ProfileAdd" component={ProfileAddPage} />
                <Stack.Screen name="ProfileEdit" component={ProfileEditPage} />
                <Stack.Screen
                  name="ProfileAvatar"
                  component={ProfileAvatarPage}
                />
                <Stack.Screen
                  name="ProfileDelete"
                  component={ProfileDeletePage}
                />
              </>
            )}
            <Stack.Screen
              name="SideMenuNavigator"
              component={SideMenuNavigator}
            />
            <Stack.Screen name="Program" component={ProgramPage} />
            <Stack.Screen name="Player" component={PlayerPage} />
            <Stack.Screen name="MoreInfo" component={MoreInfoPage} />
            <Stack.Screen name="Instructor" component={InstructorPage} />
            <Stack.Screen
              name="DeviceDisconnected"
              component={DeviceDisconnectedPage}
            />
            <Stack.Screen name="DeviceLimit" component={DeviceLimitPage} />
            {/* <Stack.Screen name="Live" component={LivePage} /> */}
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};
