import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { SVGIconProps } from '../icons.types';

export const WarningIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const {
    width = horizontalScale(40),
    height = verticalScale(40),
    fill = colors.text.default,
  } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 40 40">
      <Path
        d="M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM22 30H18V26H22V30ZM22 22H18V10H22V22Z"
        fill={fill}
      />
    </Svg>
  );
};
