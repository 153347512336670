import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';

export const LockIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const { width = 64, height = 64, fill = colors.text.default } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 64 64">
      <G fill="none" fillRule="evenodd">
        <Path d="M0 0h64v64H0z" />
        <Path
          fill={fill}
          d="M50.601 57.042c0 .818-.61 1.429-1.429 1.429H14.959c-.819 0-1.43-.61-1.43-1.43V31.285c0-.816.611-1.43 1.43-1.43H49.172c.819 0 1.43.614 1.43 1.43v25.758zM19.252 18.407a12.813 12.813 0 1 1 25.627 0v8.584H19.252v-8.584zm29.92 8.584H47.74v-8.584c0-8.65-6.973-15.664-15.61-15.733v-.008l-.065.002L32 2.666v.008c-8.635.069-15.608 7.082-15.608 15.733v8.584h-1.432c-2.352 0-4.294 1.939-4.294 4.293v25.758c0 2.352 1.942 4.29 4.294 4.29H49.172c2.352 0 4.29-1.938 4.29-4.29V31.284c0-2.354-1.938-4.293-4.29-4.293z"
        />
      </G>
    </Svg>
  );
};
