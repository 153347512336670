import { palette } from './palette';
import { withTransparency } from './withTransparency';

export const theme = {
  colors: {
    overlay: withTransparency(palette.black, 0.72),
    background: palette.black,
    backgroundSelectedButton: palette.whiteOpacity[20],
    itemBackground: palette.gray[900],
    selectedItem: palette.primary[600],
    popupBackground: palette.gray[900],
    spatialFocus: palette.white,
    error: palette.error[400],
    howItWorksTopic: palette.primary[600],
    avatarFallbackBackground: palette.primary[600],
    splashscreen: {
      background: palette.primary[600],
      icon: palette.white,
    },
    text: {
      default: palette.white,
      active: palette.gray[900],
    },
    spinner: {
      primary: palette.primary[600],
      secondary: palette.white,
    },
    badge: {
      default: palette.primary[600],
      success: palette.success[600],
    },
    progress: {
      background: palette.gray[300],
      elapsed: palette.primary[600],
    },
    keyboard: {
      keyBackground: palette.gray[900],
      keyFocused: palette.white,
      spatialFocus: palette.gray[700],
    },
    stage: {
      active: palette.primary[600],
      highlight: palette.primary[600],
    },
    sideMenu: {
      icon: palette.gray[600],
      itemGradient: withTransparency(palette.white, 0.2),
    },
    profile: {
      iconContainer: palette.gray[600],
      addIcon: palette.gray[600],
      warningIcon: palette.gray[500],
      focusedEditIcon: palette.primary[600],
    },
    search: {
      sidebarBackground: palette.gray[900],
      placeholder: palette.gray[600],
    },
    countdown: {
      gradientStart: 'rgba(253, 152, 104, 0.72)',
      gradientEnd: 'rgba(252, 88, 10, 0.72)',
    },
  },
  fonts: {
    regular: 'GraphikRegular',
    medium: 'GraphikMedium',
    bold: 'GraphikBold',
  },
};
