import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { SVGIconProps } from '../icons.types';

export const CheckboxIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const {
    width = horizontalScale(36),
    height = verticalScale(36),
    fill = colors.text.default,
  } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 36 36" fill="none">
      <Path
        d="M32 0H4C1.78 0 0 1.8 0 4V32C0 34.2 1.78 36 4 36H32C34.22 36 36 34.2 36 32V4C36 1.8 34.22 0 32 0ZM14 28L4 18L6.82 15.18L14 22.34L29.18 7.16L32 10L14 28Z"
        fill={fill}
      />
    </Svg>
  );
};
