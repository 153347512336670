import React, { useState, useCallback, useEffect } from 'react';
import { useRoute, useNavigation } from '@react-navigation/native';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';

import { useScrollPostion } from '~/hooks/useScrollPostion';
import { Crashlytics } from '~/utils/crashlytics';
import { baseAssetsUrl } from '~/configs/env';
import { HorizontalCardList } from '~/components/HorizontalCardList/HorizontalCardList';
import { ScreenError } from '~/components/ScreenError/ScreenError';
import {
  fetchInstructor,
  fetchInstructorPrograms,
} from '~/services/instructors/instructorsApi';
import {
  IInstructorResponse,
  IInstructorProgramResponse,
} from '~/services/instructors/instructorsApi.types';
import { FocusItems } from '~/types';
import { LoadingIndicator } from '~/components/LoadingIndicator/LoadingIndicator';
import { Avatar } from '~/components/Avatar/Avatar';
import { BackButton } from '~/components/BackButton/BackButton';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import type {
  RootStackNavigationProp,
  RootStackRouteProp,
} from '~/routes/routes.types';

import {
  ScrollArea,
  Content,
  InstructorContent,
  InstructorInfo,
  InstructorName,
  InstructorDescription,
  InstructorPrograms,
} from './Instructor.styles';

export const InstructorComponent = (props: FocusableElement) => {
  const { setFocus, stealFocus, updateAllSpatialLayouts } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const navigation = useNavigation<RootStackNavigationProp<'Instructor'>>();
  const route = useRoute<RootStackRouteProp<'Instructor'>>();
  const { id } = route.params;

  const { t } = useTranslation();
  const { scrollRef, scrollProps, handleElementFocus, scrollToTop } =
    useScrollPostion();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [instructor, setInstructor] = useState<IInstructorResponse>();
  const [instructorPrograms, setInstructorPrograms] = useState<
    IInstructorProgramResponse[]
  >([]);

  const handleSelectProgram = (programID: number) => {
    navigation.navigate('Program', { id: programID });
  };

  const fetchData = useCallback(async () => {
    try {
      setError(false);
      setLoading(true);
      const [instructorResponse, instructorProgramsResponse] =
        await Promise.all([fetchInstructor(id), fetchInstructorPrograms(id)]);

      setInstructor(instructorResponse);
      setInstructorPrograms(
        instructorProgramsResponse.filter(
          (category) => category.programs.length > 0
        )
      );
    } catch (err) {
      setError(true);
      Crashlytics.handleException(err, 'Instructor initial loading');
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
    setFocus(FocusItems.CloseButton);
  }, [fetchData, setFocus]);

  if (error) {
    return (
      <ScreenError
        message={t('home.error', 'Não foi possível buscar os programas.')}
        retry={fetchData}
      />
    );
  }

  return (
    <ScreenBackground>
      <LoadingIndicator loading={loading} />

      <ScrollArea ref={scrollRef as any} {...scrollProps}>
        <BackButton
          handleFocus={scrollToTop}
          focusKey={FocusItems.CloseButton}
          hasTVPreferredFocus
          blockFocusDirections={'left'}
        />

        <Content>
          {instructor && (
            <InstructorContent>
              <Avatar
                altText={instructor.name}
                image={`${baseAssetsUrl}/${instructor?.linkImage}`}
              />
              <InstructorInfo>
                <InstructorName>{instructor.name}</InstructorName>
                <InstructorDescription>
                  {instructor.description}
                </InstructorDescription>
              </InstructorInfo>
            </InstructorContent>
          )}

          <InstructorPrograms>
            {instructorPrograms?.map(
              (program) =>
                program.programs && (
                  <HorizontalCardList
                    key={`program-${program.levelId}`}
                    name={program.levelName}
                    onBecameFocused={handleElementFocus}
                    cards={program.programs.map((p) => ({
                      id: p.id,
                      imageURL: p.imageCard,
                      name: p.name,
                      onSelectCard: () => handleSelectProgram(p.id),
                    }))}
                  />
                )
            )}
          </InstructorPrograms>
        </Content>
      </ScrollArea>
    </ScreenBackground>
  );
};

export const Instructor = withFocusable({ blockNavigationOut: true })(
  InstructorComponent
);
