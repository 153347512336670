import React, { useState } from 'react';
import { useTheme } from 'styled-components/native';

import { EyeIcon } from '~/assets/icons/EyeIcon';
import { EyeSlashIcon } from '~/assets/icons/EyeSlashIcon';

import { Label } from './components/Label/Label';
import {
  Container,
  Row,
  Field,
  Outline,
  Input,
  StyledActionButton,
  ErrorText,
} from './TextField.styles';

import type { ITextField } from './TextField.types';

export const TextField = (props: ITextField) => {
  const { value, placeholder, secureTextEntry, error, passwordToggleProps } =
    props;
  const { colors } = useTheme();
  const [isTextSecure, setIsTextSecure] = useState(secureTextEntry);

  const toggleSecurity = () => {
    setIsTextSecure((state) => !state);
  };

  const handleIconColor = (focused: boolean) =>
    focused ? colors.text.active : colors.text.default;

  const hasError = Boolean(error);

  return (
    <Container>
      <Row>
        <Field>
          <Outline hasError={hasError} />

          <Label fieldValue={value} hasError={hasError}>
            {placeholder}
          </Label>

          <Input
            value={value}
            secureTextEntry={isTextSecure}
            editable={false}
            autoCapitalize="none"
            autoCorrect={false}
            underlineColorAndroid="transparent"
            spellCheck={false}
            caretHidden
          />
        </Field>

        {secureTextEntry && (
          <StyledActionButton
            onPress={toggleSecurity}
            onEnterPress={toggleSecurity}
            icon={(focused) =>
              isTextSecure ? (
                <EyeIcon fill={handleIconColor(focused)} />
              ) : (
                <EyeSlashIcon fill={handleIconColor(focused)} />
              )
            }
            {...passwordToggleProps}
          />
        )}
      </Row>

      {hasError && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};
