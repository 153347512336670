import { Ref, RefCallback, MutableRefObject } from 'react';

export const useMergeRefs =
  <T = any>(...refs: (Ref<T> | undefined)[]): RefCallback<T> =>
  (element: T) =>
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref && typeof ref === 'object') {
        (ref as MutableRefObject<T | null>).current = element;
      }
    });
