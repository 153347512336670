import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';

export const HomeIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const { width = 40, height = 34, fill = colors.text.default } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 40 34" fill="none">
      <Path
        d="M20 5.38L30 14.38V30H26V18H14V30H10V14.38L20 5.38ZM20 0L0 18H6V34H18V22H22V34H34V18H40L20 0Z"
        fill={fill}
      />
    </Svg>
  );
};
