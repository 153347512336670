import React, { createContext, useState, useCallback, useContext } from 'react';

import { IExternalProgram } from '~/services/programs/programsApi.types';

interface IMyListContext {
  favoritePrograms: IExternalProgram[];
  loadFavoriteList: (initialList: IExternalProgram[]) => void;
  addFavorite: (program: IExternalProgram) => void;
  removeFavorite: (id: number) => void;
}

const MyListContext = createContext({} as IMyListContext);

export const useFavoritePrograms = () => useContext(MyListContext);

export const MyListProvider: React.FC = ({ children }) => {
  const [favoritePrograms, setFavoritePrograms] = useState<IExternalProgram[]>(
    []
  );

  const loadFavoriteList = useCallback((initialList: IExternalProgram[]) => {
    setFavoritePrograms(initialList);
  }, []);

  const addFavorite = useCallback(
    (program: IExternalProgram) => {
      const newList = [...favoritePrograms];
      newList.push(program);
      setFavoritePrograms(newList);
    },
    [favoritePrograms]
  );

  const removeFavorite = useCallback((id: number) => {
    setFavoritePrograms((state) =>
      state.filter((program) => program.id !== id)
    );
  }, []);

  return (
    <MyListContext.Provider
      value={{
        favoritePrograms,
        loadFavoriteList,
        addFavorite,
        removeFavorite,
      }}
    >
      {children}
    </MyListContext.Provider>
  );
};
