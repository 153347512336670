import React from 'react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';

import { Card } from '../Card/Card';
import { useSideMenuOnScreen } from '~/hooks/useSideMenuOnScreen';
import type { FocusDirections } from '~/hooks/useBlockFocusDirection';

import {
  Container,
  Subtitle,
  Title,
  CardList,
  CardContainer,
} from './HorizontalCardList.styles';
import { useHorizontalCardList } from './hooks/useHorizontalCardList';

import type { IHorizontalCardList } from './HorizontalCardList.types';

const HorizontalCardListComponent = (props: IHorizontalCardList) => {
  const { name, isContentBlocked, cards } = props;

  const sideMenuOnScreen = useSideMenuOnScreen();

  const { getScrollViewProps, handleCardFocus } =
    useHorizontalCardList(sideMenuOnScreen);

  return (
    <Container>
      <Title sideMenuOnScreen={sideMenuOnScreen}>{name}</Title>
      <CardList sideMenuOnScreen={sideMenuOnScreen} {...getScrollViewProps()}>
        {cards.map((card, index) => {
          let blockFocusDirections: FocusDirections | undefined;
          const isLastCard = index === cards.length - 1;

          if (isLastCard) {
            blockFocusDirections = 'right';
          } else if (index === 0 && !sideMenuOnScreen) {
            blockFocusDirections = 'left';
          }

          return (
            <CardContainer isLastCard={isLastCard} key={card.id}>
              <Card
                dense={sideMenuOnScreen}
                name={card.name}
                badge={card.badge}
                imageURL={card.imageURL}
                isContentBlocked={isContentBlocked}
                onSelectCard={card.onSelectCard}
                onBecameFocused={(element) => {
                  card.onFocus?.(element);
                  handleCardFocus({
                    element,
                    cardIndex: index,
                  });
                }}
                timesWatched={card.timesWatched}
                blockFocusDirections={blockFocusDirections}
              />
              {!!card.subtitle && (
                <Subtitle dense={sideMenuOnScreen}>{card.subtitle}</Subtitle>
              )}
            </CardContainer>
          );
        })}
      </CardList>
    </Container>
  );
};

export const HorizontalCardList = withFocusable()(HorizontalCardListComponent);
