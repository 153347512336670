import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';

export const Container = styled.View`
  margin-bottom: ${verticalScale(32)}px;
`;

export const Label = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(28)}px;
  margin-bottom: ${verticalScale(24)}px;
`;

export const FilterGroup = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
