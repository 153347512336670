import React, { useEffect, useState } from 'react';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import { FloatingLogo } from '~/components/FloatingLogo/FloatingLogo';

import { HelpContent } from './components/HelpContent/HelpContent';
import { MenuButtonKeys } from './Help.types';
import {
  Column,
  ColumnLeft,
  ColumnRight,
  Content,
  HelpTitle,
  StyledFocusableButton,
} from './Help.styles';

const MENU_BUTTONS = [
  {
    key: MenuButtonKeys.CUSTOMER_SUPPORT,
    getLabel: (t: TFunction) =>
      t('help.customer_support', 'Suporte ao cliente'),
  },
  {
    key: MenuButtonKeys.ACCESS_VALIDITY,
    getLabel: (t: TFunction) => t('help.access_validity', 'Validade do acesso'),
  },
  {
    key: MenuButtonKeys.CANCEL_SUBSCRIPTION,
    getLabel: (t: TFunction) =>
      t('help.cancel_subscription', 'Cancelar assinatura'),
  },
  {
    key: MenuButtonKeys.MANAGE_DEVICES,
    getLabel: (t: TFunction) =>
      t('help.manage_devices', 'Gerenciar dispositivos'),
  },
  {
    key: MenuButtonKeys.CALORIE_EXPENDITURE,
    getLabel: (t: TFunction) => t('help.calorie_expenditure', 'Gasto Calórico'),
  },
  {
    key: MenuButtonKeys.PLATFORM_ACCESS,
    getLabel: (t: TFunction) =>
      t('help.platform_access', 'Acesso à Plataforma'),
  },
  {
    key: MenuButtonKeys.APP_VERSION,
    getLabel: (t: TFunction) => t('help.app_version', 'Versão do aplicativo'),
  },
];

const FIRST_MENU_KEY = MENU_BUTTONS[0].key;

const HelpComponent = ({ setFocus }: FocusableElement) => {
  const { t } = useTranslation();

  const [selectedContent, setSelectedContent] =
    useState<MenuButtonKeys>(FIRST_MENU_KEY);

  useEffect(() => {
    if (Platform.OS === 'web' && setFocus) {
      setFocus(FIRST_MENU_KEY);
    }
  }, [setFocus]);

  return (
    <ScreenBackground>
      <FloatingLogo />

      <Content>
        <HelpTitle>{t('common.help', 'Ajuda')}</HelpTitle>

        <Column>
          <ColumnLeft>
            {MENU_BUTTONS.map((item) => (
              <StyledFocusableButton
                key={item.key}
                focusKey={item.key}
                hasTVPreferredFocus={item.key === FIRST_MENU_KEY}
                onBecameFocused={() => setSelectedContent(item.key)}
                text={item.getLabel(t)}
              />
            ))}
          </ColumnLeft>

          <ColumnRight>
            <HelpContent menuKey={selectedContent} />
          </ColumnRight>
        </Column>
      </Content>
    </ScreenBackground>
  );
};

export const Help = withFocusable()(HelpComponent);
