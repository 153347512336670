import styled from 'styled-components/native';

import { verticalScale, horizontalScale } from '~/utils/layout';
import { ActionButton } from '../ActionButton/ActionButton';

const BORDER_WIDTH = verticalScale(1);
const BORDER_RADIUS = verticalScale(4);

export const TEXTFIELD_PADDING = verticalScale(24);

export const Container = styled.View`
  padding-top: ${verticalScale(12)}px;
`;

export const Row = styled.View`
  flex-direction: row;
`;

export const Field = styled.View`
  flex: 1;
  position: relative;
  border-radius: ${BORDER_RADIUS}px;
`;

export const Outline = styled.View<{ hasError?: boolean }>`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  border-width: ${BORDER_WIDTH}px;
  border-radius: ${BORDER_RADIUS}px;
  border-color: ${({ theme: { colors }, hasError }) =>
    hasError ? colors.error : colors.text.active};
`;

const INPUT_HEIGHT = verticalScale(88);

export const Input = styled.TextInput`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(24)}px;
  padding: ${TEXTFIELD_PADDING}px;
  height: ${INPUT_HEIGHT}px;
`;

export const StyledActionButton = styled(ActionButton)`
  height: ${INPUT_HEIGHT}px;
  width: ${INPUT_HEIGHT}px;
  margin-left: ${horizontalScale(8)}px;
  border-width: ${BORDER_WIDTH}px;
  border-radius: ${BORDER_RADIUS}px;
`;

export const ErrorText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.error};
  font-size: ${verticalScale(16)}px;
  margin-top: ${verticalScale(12)}px;
`;
