import React from 'react';
import styled from 'styled-components';
import { Dimensions } from 'react-native';

const { width: SCREEN_WIDTH } = Dimensions.get('screen');

const WebOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: ${SCREEN_WIDTH}px;
`;

export const PopupOverlay: React.FC = ({ children }) => {
  return <WebOverlay>{children}</WebOverlay>;
};
