import styled from 'styled-components/native';

import { verticalScale, horizontalScale } from '~/utils/layout';

export const Content = styled.View`
  margin: auto 0;
  align-self: center;
  justify-content: center;
  align-items: center;
  padding: ${verticalScale(48)}px;
  background-color: ${({ theme }) => theme.colors.popupBackground};
  border: ${verticalScale(4)}px solid ${({ theme }) => theme.colors.error};
  width: ${horizontalScale(846)}px;
  border-radius: ${verticalScale(8)}px;
`;

export const ErrorTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(36)}px;
  margin-top: ${verticalScale(32)}px;
`;

export const ErrorMessage = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(24)}px;
  margin: ${verticalScale(32)}px 0;
`;
