import React from 'react';

import { MenuButtonKeys } from '../../Help.types';
import {
  CustomerSupport,
  AccessValidity,
  CancelSubscription,
  ManageDevices,
  CalorieExpenditure,
  PlatformAccess,
  AppVersion,
} from '../ContentData/ContentData';

interface HelpContentProps {
  menuKey: MenuButtonKeys;
}

export const HelpContent = ({ menuKey }: HelpContentProps) => {
  switch (menuKey) {
    case MenuButtonKeys.CUSTOMER_SUPPORT: {
      return <CustomerSupport />;
    }
    case MenuButtonKeys.ACCESS_VALIDITY: {
      return <AccessValidity />;
    }
    case MenuButtonKeys.CANCEL_SUBSCRIPTION: {
      return <CancelSubscription />;
    }
    case MenuButtonKeys.MANAGE_DEVICES: {
      return <ManageDevices />;
    }
    case MenuButtonKeys.CALORIE_EXPENDITURE: {
      return <CalorieExpenditure />;
    }
    case MenuButtonKeys.PLATFORM_ACCESS: {
      return <PlatformAccess />;
    }
    case MenuButtonKeys.APP_VERSION: {
      return <AppVersion />;
    }
    default:
      return null;
  }
};
