import React, { memo, useMemo, useCallback } from 'react';

import { KeyboardTemplate } from '../KeyboardTemplate/KeyboardTemplate';
import { KeyboardLayoutMap } from '../../Keyboard.types';

import { generateDefaultKeys } from './generateDefaultKeys';

import type { IKeyboardControls } from '../../Keyboard.types';

const DefaultControlsComponent = (props: IKeyboardControls) => {
  const { textRef, onChangeText, keyboardLayout, updateLayout } = props;

  const toggleSpecialLayout = useCallback(() => {
    updateLayout((prevLayout) =>
      prevLayout !== KeyboardLayoutMap.Special
        ? KeyboardLayoutMap.Special
        : KeyboardLayoutMap.Alphanumeric
    );
  }, [updateLayout]);

  const toggleDiacriticalLayout = useCallback(() => {
    updateLayout((prevLayout) =>
      prevLayout !== KeyboardLayoutMap.Diacritical
        ? KeyboardLayoutMap.Diacritical
        : KeyboardLayoutMap.Alphanumeric
    );
  }, [updateLayout]);

  const handleSpace = useCallback(() => {
    const newText = textRef.current + ' ';
    onChangeText(newText);
  }, [onChangeText, textRef]);

  const handleBackspace = useCallback(() => {
    const newText = textRef.current.slice(0, -1);
    onChangeText(newText);
  }, [onChangeText, textRef]);

  const template = useMemo(
    () =>
      generateDefaultKeys({
        keyboardLayout,
        toggleSpecialLayout,
        toggleDiacriticalLayout,
        handleSpace,
        handleBackspace,
      }),
    [
      keyboardLayout,
      toggleSpecialLayout,
      toggleDiacriticalLayout,
      handleSpace,
      handleBackspace,
    ]
  );

  return <KeyboardTemplate {...props} template={template} />;
};

export const DefaultControls = memo(DefaultControlsComponent);
