import { isReadyRef, navigationRef } from '~/routes/rootNavigation';

export const backButtonHandler = () => {
  if (!isReadyRef?.current || !navigationRef?.current) {
    return;
  }

  const canGoBack = navigationRef.current.canGoBack();

  if (canGoBack) {
    navigationRef.current.goBack();
    return;
  }

  if (window.tizen) {
    window.tizen.application.getCurrentApplication().exit();
  } else if (webOS.platform?.tv) {
    webOS.platformBack();
  }
};
