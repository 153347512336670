import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';

export const Content = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(36)}px;
  text-align: center;
`;

export const Subtitle = styled.Text`
  text-align: center;
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(27)}px;
  margin: ${verticalScale(36)}px 0 ${verticalScale(24)}px;
`;
