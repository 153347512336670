import { Dimensions } from 'react-native';
import styled, { css } from 'styled-components/native';

import { FocusableButton } from '../FocusableButton/FocusableButton';
import { HORIZONTAL_SAFE_ZONE, VERTICAL_SAFE_ZONE } from '~/utils/constants';
import { verticalScale } from '~/utils/layout';

import type { BackButtonContainer } from './BackButton.types';

const { width: SCREEN_WIDTH } = Dimensions.get('screen');

export const StyledBackButton = styled(FocusableButton)<BackButtonContainer>`
  z-index: 1000;
  position: absolute;
  top: ${VERTICAL_SAFE_ZONE}px;
  left: ${HORIZONTAL_SAFE_ZONE}px;
  border-radius: ${Math.max(verticalScale(4), 4)}px;
  ${({ hidden }) => {
    if (hidden) {
      return css`
        transform: translateY(-${SCREEN_WIDTH}px);
      `;
    }
  }}
`;
