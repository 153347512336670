import styled from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';

export const Content = styled.View`
  justify-content: center;
  flex: 1;
`;

export const Box = styled.View`
  align-items: center;
  margin: 0 auto;
  padding: ${horizontalScale(54)}px ${verticalScale(96)}px;
  width: ${verticalScale(876)}px;
`;

export const IconContent = styled.View`
  margin-bottom: ${verticalScale(20)}px;
`;

const BaseText = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
`;

export const Title = styled(BaseText)`
  font-size: ${verticalScale(32)}px;
  margin-bottom: ${verticalScale(16)}px;
  text-align: center;
`;

export const Description = styled(BaseText)`
  font-size: ${verticalScale(24)}px;
  line-height: ${verticalScale(36)}px;
  margin: 0 auto ${verticalScale(48)}px auto;
  text-align: center;
`;

export const Buttons = styled.View`
  flex-direction: row;
  justify-content: center;
  width: ${verticalScale(550)}px;
`;
