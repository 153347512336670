import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';

export const ErrorContainer = styled.View`
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const ErrorText = styled.Text`
  width: 50%;
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(36)}px;
  text-align: center;
  margin-bottom: ${verticalScale(60)}px;
`;
