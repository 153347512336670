import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';
import { verticalScale } from '~/utils/layout';

export const ShiftIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const {
    width = verticalScale(16),
    height = verticalScale(16),
    fill = colors.text.default,
  } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 16 16">
      <Path
        d="M0 8L1.41 9.41L7 3.83V16H9V3.83L14.58 9.42L16 8L8 0L0 8Z"
        fill={fill}
      />
    </Svg>
  );
};
