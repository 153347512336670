import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';

export const ForwardIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const { width = 47, height = 57, fill = colors.text.default } = props;

  return (
    <Svg width={width} height={height} viewBox="0 -960 960 960">
      <Path
        fill={fill}
        fillRule="nonzero"
        d="M281-310v-50h121v-56h-82v-49h82v-57H281v-49h127q18.7 0 31.35 12.65Q452-545.7 452-527v173q0 18.7-12.65 31.35Q426.7-310 408-310H281Zm272 0q-18.7 0-31.35-12.65Q509-335.3 509-354v-173q0-18.7 12.65-31.35Q534.3-571 553-571h83q18.7 0 31.35 12.65Q680-545.7 680-527v173q0 18.7-12.65 31.35Q654.7-310 636-310h-83Zm6-50h71v-162h-71v162ZM480-80q-75 0-140.5-28T225-185q-49-49-77-114.5T120-440q0-75 28-140.5T225-695q49-49 114.5-77T480-800h21l-78-78 41-41 147 147-147 147-41-41 74-74h-17q-125.357 0-212.679 87.321Q180-565.357 180-440t87.321 212.679Q354.643-140 480-140t212.679-87.321Q780-314.643 780-440h60q0 75-28 140.5T735-185q-49 49-114.5 77T480-80Z "
      />
    </Svg>
  );
};
