import { RefObject } from 'react';
import { Platform } from 'react-native';
import { NavigationContainerRef } from '@react-navigation/native';

import { SentryClient, sentryRoutingInstrumentation } from '~/configs/sentry';

interface CrashlyticsUser {
  id: string;
  email: string;
}

interface CrashlyticsNavigation {
  from: string | undefined;
  to: string | undefined;
}

export const Crashlytics = {
  setUser(user: CrashlyticsUser | null) {
    SentryClient.setUser(user);
  },
  handleException(error: any, context: string) {
    SentryClient.captureException(error, (scope) => {
      scope.setTag('error.context', context);
      return scope;
    });
  },
  setupNavigation(navigationRef: RefObject<NavigationContainerRef>) {
    // Only used for native navigation
    if (sentryRoutingInstrumentation) {
      sentryRoutingInstrumentation.registerNavigationContainer(navigationRef);
    }
  },
  handleNavigation({ from, to }: CrashlyticsNavigation) {
    // Custom breadcrumb for web navigation only, native is handled by setupNavigation above
    if (Platform.OS === 'web') {
      SentryClient.addBreadcrumb({
        type: 'navigation',
        category: 'navigation',
        message: `Navigation to ${to}`,
        data: {
          from,
          to,
        },
      });
    }
  },
};
