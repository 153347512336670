import { IKeyboardTemplateKey } from './KeyboardTemplate.types';

type SimpleKeyboardKey = Pick<IKeyboardTemplateKey, 'symbol'>;

type BuildKeysRow = (
  schema: string,
  letterCase?: 'upper' | 'lower'
) => SimpleKeyboardKey[];

export const ALPHANUMERIC_SYMBOL = 'a b c';
export const SPECIAL_SYMBOL = '! # $';
export const DIACRITICAL_SYMBOL = 'à á â';

export const buildKeysRow: BuildKeysRow = (schema, letterCase) => {
  if (letterCase === 'upper') {
    return schema.split('').map((key) => ({ symbol: key.toUpperCase() }));
  }

  return schema.split('').map((key) => ({ symbol: key }));
};
