import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { SVGIconProps } from '../icons.types';

export const EditIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const {
    width = horizontalScale(26),
    height = verticalScale(26),
    fill = colors.text.default,
  } = props;

  return (
    <Svg width={width} height={height} fill={fill} viewBox="0 0 26 26">
      <Path
        d="M15.9174 8.77833L17.2207 10.0817L4.38569 22.9167H3.08236V21.6133L15.9174 8.77833ZM21.0174 0.25C20.6632 0.25 20.2949 0.391667 20.0257 0.660833L17.4332 3.25333L22.7457 8.56583L25.3382 5.97333C25.8907 5.42083 25.8907 4.52833 25.3382 3.97583L22.0232 0.660833C21.7399 0.3775 21.3857 0.25 21.0174 0.25ZM15.9174 4.76917L0.249023 20.4375V25.75H5.56152L21.2299 10.0817L15.9174 4.76917Z"
        fill={fill}
      />
    </Svg>
  );
};
