import { useState, useEffect, useCallback } from 'react';

import { fetchClosedCaptions } from '~/services/programs/programsApi';
import type { IClosedCaption } from '~/services/programs/programsApi.types';

export const useFetchClosedCaptions = (mediaId: number | undefined) => {
  const [closedCaptions, setClosedCaptions] = useState<
    IClosedCaption[] | undefined
  >();

  const fetchData = useCallback(async () => {
    if (!mediaId) {
      setClosedCaptions([]);
      return;
    }

    try {
      const data = await fetchClosedCaptions(mediaId);
      setClosedCaptions(data ? data.vtt : []);
    } catch (err) {
      setClosedCaptions([]);
    }
  }, [mediaId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { closedCaptions };
};
