import styled, { css } from 'styled-components/native';
import { Animated } from 'react-native';

import { verticalScale, horizontalScale } from '~/utils/layout';
import { HORIZONTAL_SAFE_ZONE, VERTICAL_SAFE_ZONE } from '~/utils/constants';
import { FocusableButton } from '~/components/FocusableButton/FocusableButton';

import type { IPlayerControlsButton } from './PlayerControls.types';

export const PLAYER_CONTROLS_HEIGHT = verticalScale(180);
export const PLAYER_CONTROLS_ICON_SIZE = verticalScale(45);
export const PLAYER_CONTROLS_SPACING = horizontalScale(24);

export const Container = styled(Animated.View)`
  flex-direction: row;
  padding: ${verticalScale(35)}px ${HORIZONTAL_SAFE_ZONE}px
    ${VERTICAL_SAFE_ZONE}px;
  height: ${PLAYER_CONTROLS_HEIGHT}px;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.overlay};
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
`;

type DisableSpacing = Pick<IPlayerControlsButton, 'disableSpacing'>;

export const StyledFocusableButton = styled(FocusableButton)<DisableSpacing>`
  flex-direction: row;
  border-width: 0px;
  border-radius: ${verticalScale(8)}px;
  height: ${verticalScale(85)}px;
  margin-right: ${({ disableSpacing }) =>
    disableSpacing ? 0 : PLAYER_CONTROLS_SPACING}px;
  ${({ text }) =>
    text
      ? css`
          padding: 0 ${horizontalScale(24)}px;
        `
      : css`
          width: ${horizontalScale(85)}px;
        `}
`;

const PROGRESS_HEIGHT = verticalScale(10);
const PROGRESS_BORDER_RADIUS = verticalScale(4);

export const ProgressContainer = styled.View`
  height: ${PROGRESS_HEIGHT}px;
  background-color: ${({ theme: { colors } }) => colors.progress.background};
  border-radius: ${PROGRESS_BORDER_RADIUS}px;
  flex: 1;
`;

export const WatchedProgress = styled.View<{ progress: number }>`
  height: ${PROGRESS_HEIGHT}px;
  background-color: ${({ theme: { colors } }) => colors.progress.elapsed};
  border-radius: ${PROGRESS_BORDER_RADIUS}px;
  width: ${({ progress }) => progress}%;
`;

export const RemainingTime = styled.Text`
  font-size: ${verticalScale(24)}px;
  color: ${({ theme: { colors } }) => colors.text.default};
  margin-left: ${PLAYER_CONTROLS_SPACING}px;
  min-width: ${horizontalScale(75)}px;
`;
