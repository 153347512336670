import React, { useRef, useEffect } from 'react';
import { useTheme } from 'styled-components/native';
import { Animated } from 'react-native';

import {
  PLAYER_CONTROLS_HEIGHT,
  PLAYER_CONTROLS_ICON_SIZE,
  Container,
  StyledFocusableButton,
  ProgressContainer,
  WatchedProgress,
  RemainingTime,
} from './PlayerControls.styles';

import type {
  IPlayerControls,
  IPlayerControlsButton,
  IPlayerControlsProgress,
  IPlayerControlsRemainingTime,
} from './PlayerControls.types';

const PlayerControls = (props: IPlayerControls) => {
  const { children, isActive } = props;
  const animatedValue = useRef(new Animated.Value(isActive ? 0 : 1)).current;

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: isActive ? 0 : 1,
      duration: 150,
      useNativeDriver: true,
    }).start();
  }, [animatedValue, isActive]);

  const transform = [
    {
      translateY: animatedValue.interpolate({
        inputRange: [0, 1],
        outputRange: [0, PLAYER_CONTROLS_HEIGHT],
      }),
    },
  ];

  return <Container style={{ transform }}>{children}</Container>;
};

const PlayerButton = (props: IPlayerControlsButton) => {
  const {
    Icon,
    text,
    disableSpacing,
    onPress,
    focusKey,
    innerRef,
    hasTVPreferredFocus,
    onFocus,
    iconScaling = 1,
  } = props;

  const { colors } = useTheme();

  return (
    <StyledFocusableButton
      text={text}
      innerRef={innerRef}
      focusKey={focusKey}
      hasTVPreferredFocus={hasTVPreferredFocus}
      onPress={onPress}
      onEnterPress={onPress}
      onBecameFocused={onFocus}
      disableSpacing={disableSpacing}
      icon={
        Icon
          ? (focused) => (
              <Icon
                fill={focused ? colors.text.active : colors.text.default}
                height={PLAYER_CONTROLS_ICON_SIZE * iconScaling}
                width={PLAYER_CONTROLS_ICON_SIZE * iconScaling}
              />
            )
          : undefined
      }
    />
  );
};

const PlayerProgressBar = (props: IPlayerControlsProgress) => {
  const { progress } = props;
  return (
    <ProgressContainer>
      <WatchedProgress progress={progress} />
    </ProgressContainer>
  );
};

const PlayerRemainingTime = (props: IPlayerControlsRemainingTime) => {
  const { children } = props;
  return <RemainingTime>{children ? `-${children}` : ''}</RemainingTime>;
};

PlayerControls.Button = PlayerButton;
PlayerControls.ProgressBar = PlayerProgressBar;
PlayerControls.RemainingTime = PlayerRemainingTime;

export { PlayerControls };
