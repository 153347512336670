import styled from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { FocusableButton } from '~/components/FocusableButton/FocusableButton';
import { LinearGradient } from '~/components/LinearGradient/LinearGradient';

import type { IExpandedSideMenu } from '../../SideMenu.types';

export const StyledFocusableButton = styled(FocusableButton).attrs(
  ({ theme: { colors } }) => ({
    focusedTextColor: colors.spatialFocus,
    focusedBorderColor: 'transparent',
    bgFocusedColor: 'transparent',
    textStyle: {
      zIndex: 10,
      marginLeft: horizontalScale(20),
      fontSize: verticalScale(18),
    },
  })
)<IExpandedSideMenu>`
  font-family: ${({ theme }) => theme.fonts.regular};
  padding: ${verticalScale(26)}px ${verticalScale(30)}px ${verticalScale(26)}px
    ${verticalScale(38)}px;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 0px;
  border-width: 0px;
  align-items: center;
  position: relative;
`;

export const GradientButtonBg = styled(LinearGradient).attrs(
  ({ theme: { colors } }) => ({
    colors: [colors.sideMenu.itemGradient, 'transparent'],
    direction: 'horizontal',
  })
)`
  border-color: ${({ theme: { colors } }) => colors.selectedItem};
  border-left-width: ${horizontalScale(8)}px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;
