import React, { useCallback, useState } from 'react';
import {
  FocusableElement,
  withFocusable,
} from '@noriginmedia/react-spatial-navigation';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';

import { Crashlytics } from '~/utils/crashlytics';
import { Avatar } from '~/components/Avatar/Avatar';
import { authProfile, fetchProfiles } from '~/services/profiles/profilesApi';
import { baseAssetsUrl } from '~/configs/env';
import {
  IProfile,
  IProfileResponse,
} from '~/services/profiles/profilesApi.types';
import { FloatingLogo } from '~/components/FloatingLogo/FloatingLogo';
import { LoadingIndicator } from '~/components/LoadingIndicator/LoadingIndicator';
import { ScreenError } from '~/components/ScreenError/ScreenError';
import { trackLogin } from '~/services/auth/authApi';
import { useSession } from '~/contexts/SessionContext/SessionContext';
import { PlusIcon } from '~/assets/icons/PlusIcon';
import { verticalScale } from '~/utils/layout';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { ActionButton } from '~/components/ActionButton/ActionButton';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import type { RootStackNavigationProp } from '~/routes/routes.types';

import {
  Content,
  Profiles,
  Title,
  ProfilesRow,
  ProfileContainer,
  ProfileName,
  StyledFocusableAvatar,
  IconContainer,
} from './ProfileList.styles';

const FOCUS_KEY = 'manage-profiles';
const PLUS_ICON_SIZE = verticalScale(30);

const ProfileListComponent = (props: FocusableElement) => {
  const { setFocus, stealFocus, updateAllSpatialLayouts } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const { t } = useTranslation();
  const { onLoginSuccess, persistedEmail } = useSession();
  const navigation = useNavigation<RootStackNavigationProp<'ProfileList'>>();
  const { colors } = useTheme();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [profiles, setProfiles] = useState<IProfileResponse>();

  const getProfiles = useCallback(async () => {
    setError(false);
    setLoading(true);

    try {
      setProfiles(await fetchProfiles());
      setLoading(false);
      setFocus(FOCUS_KEY);
    } catch (err) {
      setError(true);
      Crashlytics.handleException(err, 'Profiles loading');
    }
  }, [setFocus]);

  useFocusEffect(
    useCallback(() => {
      getProfiles();
    }, [getProfiles])
  );

  const handleSelectProfile = async (profileId: number) => {
    try {
      setLoading(true);
      const { id, userId } = await authProfile({ customerId: profileId });
      await trackLogin({ sessionId: id, userId, method: 'typing' });
      await onLoginSuccess({
        userToken: id,
        email: persistedEmail,
      });
      navigation.replace('SideMenuNavigator', { screen: 'Home' });
    } catch (err) {
      setLoading(false);
      Crashlytics.handleException(err, 'Selected profile login');
    }
  };

  const handleProfileManage = () => navigation.navigate('ProfileManage');

  const handleAddProfile = () => navigation.navigate('ProfileAdd', {});

  if (error) {
    return (
      <ScreenError
        message={t('common.error_occurred', 'Ocorreu um erro')}
        retry={getProfiles}
      />
    );
  }

  return (
    <ScreenBackground>
      <Content>
        <LoadingIndicator loading={loading} />

        {profiles && !loading && (
          <>
            <FloatingLogo />

            <Profiles>
              <Title>{t('profile_list.title', 'Quem vai treinar hoje?')}</Title>
              <ProfilesRow>
                {profiles?.parentProfile && (
                  <ProfileContainer>
                    <StyledFocusableAvatar
                      onPress={() =>
                        handleSelectProfile(profiles?.parentProfile?.id)
                      }
                      onEnterPress={() =>
                        handleSelectProfile(profiles?.parentProfile?.id)
                      }
                    >
                      <Avatar
                        altText={profiles?.parentProfile?.name}
                        image={`${baseAssetsUrl}/${profiles?.parentProfile?.avatar}`}
                      />
                    </StyledFocusableAvatar>
                    <ProfileName numberOfLines={2}>
                      {profiles?.parentProfile?.name}
                    </ProfileName>
                  </ProfileContainer>
                )}

                {profiles?.childProfiles &&
                  profiles?.childProfiles.map(
                    (profile: IProfile, index: number) => (
                      <ProfileContainer key={index}>
                        <StyledFocusableAvatar
                          onPress={() => handleSelectProfile(profile.id)}
                          onEnterPress={() => handleSelectProfile(profile.id)}
                        >
                          <Avatar
                            altText={profile.name}
                            image={`${baseAssetsUrl}/${profile.avatar}`}
                          />
                        </StyledFocusableAvatar>
                        <ProfileName numberOfLines={2}>
                          {profile.name}
                        </ProfileName>
                      </ProfileContainer>
                    )
                  )}

                {profiles?.childProfiles &&
                  profiles?.childProfiles?.length <
                    Number(profiles?.numberOfChildProfiles) && (
                    <ProfileContainer>
                      <StyledFocusableAvatar
                        onPress={handleAddProfile}
                        onEnterPress={handleAddProfile}
                      >
                        <IconContainer>
                          <PlusIcon
                            width={PLUS_ICON_SIZE}
                            height={PLUS_ICON_SIZE}
                            fill={colors.text.default}
                          />
                        </IconContainer>
                      </StyledFocusableAvatar>
                      <ProfileName numberOfLines={2}>
                        {t('profile_list.add_profile', 'Adicionar perfil')}
                      </ProfileName>
                    </ProfileContainer>
                  )}
              </ProfilesRow>

              <ActionButton
                focusKey={FOCUS_KEY}
                text={t('profile_list.manage_profiles', 'Gerenciar Perfis')}
                hasTVPreferredFocus
                onEnterPress={handleProfileManage}
                onPress={handleProfileManage}
              />
            </Profiles>
          </>
        )}
      </Content>
    </ScreenBackground>
  );
};

export const ProfileList = withFocusable({ blockNavigationOut: true })(
  ProfileListComponent
);
