import { useCallback } from 'react';

import { Crashlytics } from '~/utils/crashlytics';
import { useSession } from '~/contexts/SessionContext/SessionContext';
import { updateUserPreferences } from '~/services/user/userApi';
import type { IUserPreferences } from '~/services/user/userApi.types';

export const useUpdateCaptionPreference = () => {
  const { currentUser, setUserPreferences } = useSession();

  const updateCaptionPreference = useCallback(
    async (language: string | null) => {
      const userPreferences = currentUser!.preferences;
      const updatedCaptions: IUserPreferences['captions'] = {
        active: language !== null,
        language: language || userPreferences.captions.language,
      };

      if (
        updatedCaptions.active === userPreferences.captions.active &&
        updatedCaptions.language === userPreferences.captions.language
      ) {
        return;
      }

      try {
        await updateUserPreferences({
          language: userPreferences.language,
          captions: updatedCaptions,
          playerQuality: userPreferences.playerQuality,
        });
        setUserPreferences({ captions: updatedCaptions });
      } catch (err) {
        Crashlytics.handleException(err, 'Update user captions preference');
      }
    },
    [currentUser, setUserPreferences]
  );

  return { updateCaptionPreference };
};
