import styled from 'styled-components/native';

import { HORIZONTAL_SAFE_ZONE, FLOATING_LOGO_HEIGHT } from '~/utils/constants';

export const Container = styled.View`
  height: ${FLOATING_LOGO_HEIGHT}px;
  padding: 0 ${HORIZONTAL_SAFE_ZONE}px;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
`;
