import React, { useEffect } from 'react';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';
import { ActivityIndicator } from 'react-native';

import { CastIcon } from '~/assets/icons/CastIcon';
import { verticalScale } from '~/utils/layout';
import { FloatingLogo } from '~/components/FloatingLogo/FloatingLogo';
import { ActionButton } from '~/components/ActionButton/ActionButton';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import type { RootStackNavigationProp } from '~/routes/routes.types';

import {
  Content,
  Instructions,
  StyledStage,
  Title,
  IconContainer,
  ButtonContainer,
  QRCodeCard,
  QRCodeHelper,
  StyledQRCode,
  StyledStageWithIcon,
} from './LoginByPhone.styles';
import { useQRCodeLogin } from './hooks/useQRCodeLogin';

const FOCUS_KEY = 'login-by-phone-goback';
const LOADING_SIZE = verticalScale(50);

const LoginByPhoneComponent = (props: FocusableElement) => {
  const { stealFocus, updateAllSpatialLayouts, setFocus } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const { t } = useTranslation();
  const { colors } = useTheme();
  const navigation = useNavigation<RootStackNavigationProp<'LoginByPhone'>>();
  const { qrCodeValue, loading } = useQRCodeLogin();

  useEffect(() => {
    setFocus(FOCUS_KEY);
  }, [setFocus]);

  const handlePress = () => {
    navigation.goBack();
  };

  return (
    <ScreenBackground>
      <FloatingLogo />

      <Content>
        <Instructions>
          <Title>
            {t(
              'login_by_phone.title',
              'Para acessar usando o celular, siga as instruções:'
            )}
          </Title>

          <StyledStageWithIcon>
            <StyledStage number={1} variant="highlight">
              {t(
                'login_by_phone.step_one',
                'Acesse o app da Queima Diária e toque no ícone'
              )}
            </StyledStage>
            <IconContainer>
              <CastIcon fill={colors.background} />
            </IconContainer>
          </StyledStageWithIcon>

          <StyledStage number={2} variant="highlight">
            {t(
              'login_by_phone.step_two',
              'Toque em “Escanear código” e aponte a câmera para o QR Code ao lado'
            )}
          </StyledStage>

          <ButtonContainer>
            <ActionButton
              text={t('common.go_back', 'Voltar')}
              onPress={handlePress}
              onEnterPress={handlePress}
              hasTVPreferredFocus
              focusKey={FOCUS_KEY}
            />
          </ButtonContainer>
        </Instructions>

        <QRCodeCard>
          {loading ? (
            <ActivityIndicator
              size={LOADING_SIZE}
              color={colors.spinner.primary}
            />
          ) : (
            <>
              <StyledQRCode value={qrCodeValue} />
              <QRCodeHelper>
                {t('login_by_phone.step_three', 'Ou digite o código:')}
                {` ${qrCodeValue}`}
              </QRCodeHelper>
            </>
          )}
        </QRCodeCard>
      </Content>
    </ScreenBackground>
  );
};

export const LoginByPhone = withFocusable({ blockNavigationOut: true })(
  LoginByPhoneComponent
);
