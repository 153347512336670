import styled, { css } from 'styled-components';

import type { ITextField, IconContainer } from './FocusableInput.types';

export const Container = styled.div`
  margin-bottom: 16px;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

type Placeholder = { placeholderTextColor?: string };

export const TextInput = styled.input<ITextField & Placeholder>`
  background-color: white;
  border: 4px solid
    ${({ focused, focusColor }) => (focused ? focusColor : 'transparent')};
  padding: 0 5%;
  font-size: 1.5rem;
  width: ${({ endIcon, startIcon }) =>
    endIcon && !startIcon ? 'calc(100% - 3rem)' : '100%'};
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${({ placeholderTextColor }) => placeholderTextColor};
  }
`;

export const StartIconContainer = styled.button<IconContainer>`
  width: 3rem;
  justify-content: center;
  background-color: white;
  display: flex;
  align-items: center;
  height: 3rem;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  ${({ focused, focusColor }) =>
    focused &&
    css`
      border: 4px solid ${focusColor};
    `}
`;

export const EndIconContainer = styled.button<IconContainer>`
  width: 5rem;
  justify-content: center;
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 6rem;
  position: absolute;
  right: 0;
  top: 4px;
  z-index: 100;
  border-radius: 10px;
  border: 4px solid
    ${({ focused, focusColor }) => (focused ? focusColor : 'transparent')};
`;
