import styled from 'styled-components/native';
import { Animated } from 'react-native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { VERTICAL_SAFE_ZONE, HORIZONTAL_SAFE_ZONE } from '~/utils/constants';
import { FocusableButton } from '~/components/FocusableButton/FocusableButton';

import type { ISelectedCaption } from './CaptionsMenu.types';

export const CAPTIONS_MENU_WIDTH = horizontalScale(480);

export const Container = styled(Animated.View)`
  z-index: 5;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: ${CAPTIONS_MENU_WIDTH}px;
  padding: ${VERTICAL_SAFE_ZONE}px ${HORIZONTAL_SAFE_ZONE}px;
  background-color: ${({ theme: { colors } }) => colors.overlay};
`;

export const Title = styled.Text`
  color: ${({ theme: { colors } }) => colors.text.default};
  font-size: ${verticalScale(24)}px;
`;

export const Separator = styled.Text`
  background-color: ${({ theme: { colors } }) => colors.text.default};
  height: ${Math.max(verticalScale(1), 1)}px;
  margin: ${verticalScale(32)}px 0;
`;

export const StyledFocusableButton = styled(
  FocusableButton
).attrs<ISelectedCaption>(({ isSelected }) => ({
  textStyle: {
    textAlign: 'left',
    fontSize: verticalScale(32),
    fontWeight: isSelected ? '700' : '500',
  },
}))<ISelectedCaption>`
  flex-direction: row;
  justify-content: flex-start;
  border-width: 0px;
  border-radius: ${verticalScale(8)}px;
  padding: ${verticalScale(12)}px ${horizontalScale(24)}px;
  margin-bottom: ${verticalScale(16)}px;
`;
