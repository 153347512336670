import React, { useEffect, memo, useCallback, useMemo, useRef } from 'react';
import { Platform, Animated } from 'react-native';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';

import { CheckIcon } from '~/assets/icons/CheckIcon';
import { localisationToTrackLanguage } from '~/utils/functions';
import { useBackHandler } from '~/hooks/useBackHandler';
import { useTVEventHandler } from '~/hooks/useTVEventHandler';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';
import { isNativeButton } from '~/components/FocusableButton/FocusableButton';
import type { IClosedCaption } from '~/services/programs/programsApi.types';

import {
  Container,
  Title,
  Separator,
  StyledFocusableButton,
  CAPTIONS_MENU_WIDTH,
} from './CaptionsMenu.styles';

import type { ICaptionsMenu } from './CaptionsMenu.types';

const FOCUS_KEY = 'closed-captions-menu-first-item';

const CaptionsMenuComponent = (props: ICaptionsMenu) => {
  const {
    selectedLanguage,
    closedCaptions,
    onSelect,
    setFocus,
    triggerFocusKey,
    triggerInnerRef,
    closeMenu,
  } = props;

  const { t } = useTranslation();
  const blockFocusLeftProps = useBlockFocusDirection('left');
  const lastButtonProps = useBlockFocusDirection(['down', 'left']);

  const animatedValue = useRef(new Animated.Value(0)).current;
  const transform = [
    {
      translateX: animatedValue.interpolate({
        inputRange: [0, 1],
        outputRange: [CAPTIONS_MENU_WIDTH, 0],
      }),
    },
  ];

  const noLanguageIsEnabled = useMemo(() => {
    if (selectedLanguage === null) {
      return true;
    }

    const languageIsAvailable = closedCaptions?.some((closedCaption) => {
      const trackLanguage = localisationToTrackLanguage(closedCaption.language);
      return trackLanguage === selectedLanguage;
    });

    return !languageIsAvailable;
  }, [closedCaptions, selectedLanguage]);

  const renderCheckIcon = (shouldRender: boolean) =>
    shouldRender ? <CheckIcon /> : null;

  const closeAndSetTriggerFocus = useCallback(() => {
    Animated.timing(animatedValue, {
      toValue: 0,
      duration: 100,
      useNativeDriver: true,
    }).start(() => {
      closeMenu();

      if (Platform.OS === 'web') {
        setFocus?.(triggerFocusKey);
      } else if (
        triggerInnerRef.current &&
        isNativeButton(triggerInnerRef.current)
      ) {
        triggerInnerRef.current.setNativeProps({
          hasTVPreferredFocus: true,
        });
      }
    });
  }, [animatedValue, closeMenu, setFocus, triggerFocusKey, triggerInnerRef]);

  const handleSelect = (item: IClosedCaption | null) => {
    closeAndSetTriggerFocus();
    onSelect(item);
  };

  useEffect(() => {
    if (Platform.OS !== 'web') {
      return;
    }

    const handleKeyboardEvent = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        closeAndSetTriggerFocus();
      }
    };

    document.addEventListener('keyup', handleKeyboardEvent);

    return () => {
      document.removeEventListener('keyup', handleKeyboardEvent);
    };
  }, [closeAndSetTriggerFocus]);

  useTVEventHandler((event) => {
    if (event.eventType === 'left' && event.eventKeyAction === 1) {
      closeAndSetTriggerFocus();
    }
  });

  useBackHandler(closeAndSetTriggerFocus);

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: 1,
      duration: 150,
      useNativeDriver: true,
    }).start();
  }, [animatedValue]);

  useEffect(() => {
    setFocus(FOCUS_KEY);
  }, [setFocus]);

  return (
    <Container style={{ transform }}>
      <Title>Legendas</Title>
      <Separator />

      {closedCaptions?.map((closedCaption, index) => {
        const trackLanguage = localisationToTrackLanguage(
          closedCaption.language
        );
        const isSelected = selectedLanguage === trackLanguage;

        return (
          <StyledFocusableButton
            key={closedCaption.id}
            text={closedCaption.title}
            onPress={() => handleSelect(closedCaption)}
            onEnterPress={() => handleSelect(closedCaption)}
            icon={() => renderCheckIcon(isSelected)}
            isSelected={isSelected}
            focusKey={index === 0 ? FOCUS_KEY : undefined}
            hasTVPreferredFocus={index === 0}
            {...blockFocusLeftProps}
          />
        );
      })}

      <StyledFocusableButton
        text={t('common.disabled', 'Desativado')}
        onPress={() => handleSelect(null)}
        onEnterPress={() => handleSelect(null)}
        icon={() => renderCheckIcon(noLanguageIsEnabled)}
        isSelected={noLanguageIsEnabled}
        {...lastButtonProps}
      />
    </Container>
  );
};

export const CaptionsMenu = withFocusable()(memo(CaptionsMenuComponent));
