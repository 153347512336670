/**
 * Name of supported keys inside the App
 * @link https://developer.samsung.com/smarttv/develop/guides/user-interaction/remote-control.html
 */
const APP_SUPPORTED_KEYS = ['MediaPlayPause', 'MediaPlay', 'MediaPause'];

export const registerTizenKeys = () => {
  if (window.tizen) {
    const supportedKeys = window.tizen.tvinputdevice.getSupportedKeys();

    const supportedKeyNamesMap = supportedKeys.reduce(
      (acc: Record<string, string>, inputDeviceKey) => {
        const keyName = inputDeviceKey.name;
        acc[keyName] = keyName;
        return acc;
      },
      {}
    );

    const keyNames: string[] = [];

    APP_SUPPORTED_KEYS.forEach((keyName) => {
      if (supportedKeyNamesMap[keyName]) {
        keyNames.push(keyName);
      }
    });

    window.tizen.tvinputdevice.registerKeyBatch(keyNames);
  }
};
