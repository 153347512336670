import React from 'react';
import { Animated, StyleProp, ViewStyle } from 'react-native';

import { FullLogo } from '~/assets/icons/FullLogo';

import { Container } from './FloatingLogo.styles';

interface IFloatingLogo {
  style?: Animated.WithAnimatedValue<StyleProp<ViewStyle>>;
}

export const FloatingLogo = ({ style }: IFloatingLogo) => {
  return (
    <Container>
      <Animated.View style={style}>
        <FullLogo />
      </Animated.View>
    </Container>
  );
};
