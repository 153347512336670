import React, { useState, useLayoutEffect, useRef } from 'react';

import { getNameInitials } from '~/utils/functions';

import { Image, Fallback } from './Avatar.styles';

import type { AvatarProps } from './Avatar.types';

export const Avatar = (props: AvatarProps) => {
  const { size = 170, image, fallbackImage, altText = '' } = props;

  const [fallbackText, setFallbackText] = useState(() => {
    if (!image && altText) {
      return getNameInitials(altText);
    }
    return '';
  });
  const [imgError, setImgError] = useState<boolean>(false);
  const lastImageUrl = useRef<string | undefined>('');

  const handleImageError = () => {
    if (altText) {
      const initials = getNameInitials(altText);
      setFallbackText(initials);
    }
    setImgError(true);
  };

  useLayoutEffect(() => {
    if (lastImageUrl.current) {
      setFallbackText('');
      setImgError(false);
    }
    lastImageUrl.current = image;
  }, [image]);

  return (
    <Image
      size={size}
      source={
        imgError && fallbackImage
          ? require('../../assets/img/fallbackAvatar.png')
          : {
              uri: image,
            }
      }
      onError={handleImageError}
      hasFallbackText={!!fallbackText}
    >
      {!!fallbackText && <Fallback size={size}>{fallbackText}</Fallback>}
    </Image>
  );
};
