import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';

import { FocusItems } from '~/types';
import { PowerOffIcon } from '~/assets/icons/PowerOffIcon';
import { useSession } from '~/contexts/SessionContext/SessionContext';
import { isNativeButton } from '~/components/FocusableButton/FocusableButton';
import { PopupConfirmation } from '~/components/PopupConfirmation/PopupConfirmation';
import { SideMenuItem } from '../SideMenuItem/SideMenuItem';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';

import type { ISideMenuFooter } from './SideMenuFooter.types';

export const SideMenuFooter = (props: ISideMenuFooter) => {
  const {
    setFocus,
    isMenuExpanded,
    onItemFocus,
    onItemBlur,
    iconSize,
    innerRef: logoutRef,
  } = props;

  const { t } = useTranslation();
  const { colors } = useTheme();
  const blockDownProps = useBlockFocusDirection('down');

  const { handleLogout } = useSession();
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const toggleLogoutPopup = () => {
    if (showPopup) {
      if (logoutRef?.current && isNativeButton(logoutRef.current)) {
        logoutRef.current.setNativeProps({
          hasTVPreferredFocus: true,
        });
      } else {
        setFocus(FocusItems.Exit);
      }
      setShowPopup(false);
    } else {
      setFocus(FocusItems.ConfirmLogout);
      setShowPopup(true);
    }
  };

  const handleIconColor = (focused: boolean) => {
    if (focused) {
      return colors.spatialFocus;
    }
    if (isMenuExpanded) {
      return colors.text.default;
    }
    return colors.sideMenu.icon;
  };

  return (
    <>
      <SideMenuItem
        focusable={isMenuExpanded}
        isMenuExpanded={isMenuExpanded}
        onFocus={onItemFocus}
        onBlur={onItemBlur}
        onPress={toggleLogoutPopup}
        text={t('common.logout', 'Sair')}
        icon={(focused) => (
          <PowerOffIcon
            fill={handleIconColor(focused)}
            width={iconSize}
            height={iconSize}
          />
        )}
        innerRef={logoutRef}
        focusKey={FocusItems.Exit}
        {...blockDownProps}
      />

      {showPopup && (
        <PopupConfirmation
          handleLogout={handleLogout}
          handleCancel={toggleLogoutPopup}
          onItemFocus={onItemFocus}
          onItemBlur={onItemBlur}
        />
      )}
    </>
  );
};
