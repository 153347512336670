import styled from 'styled-components/native';

import { VERTICAL_SAFE_ZONE, HORIZONTAL_SAFE_ZONE } from '~/utils/constants';
import { verticalScale } from '~/utils/layout';

export const ScrollArea = styled.ScrollView`
  height: 100%;
`;

export const Menu = styled.View`
  flex-direction: row;
  justify-content: center;
  padding-top: ${VERTICAL_SAFE_ZONE}px;
`;

export const Content = styled.View`
  padding: ${verticalScale(50)}px ${HORIZONTAL_SAFE_ZONE}px
    ${VERTICAL_SAFE_ZONE}px;
`;
