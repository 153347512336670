import { useState, useCallback, useRef } from 'react';

import { useInactivity } from '~/hooks/useInactivity';
import type { FocusableButtonRef } from '~/components/FocusableButton/FocusableButton.types';

const CAPTIONS_FOCUS_KEY = 'player-controls-captions-trigger';

export const usePlayerControls = () => {
  const [captionsMenuOpened, setCaptionsMenuOpened] = useState(false);

  const { isUserActive, handleCustomActivity } = useInactivity();
  const captionsTriggerRef = useRef<FocusableButtonRef>(null);

  const openCaptionsMenu = useCallback(() => {
    setCaptionsMenuOpened(true);
  }, []);

  const closeCaptionsMenu = useCallback(() => {
    setCaptionsMenuOpened(false);
  }, []);

  const getCaptionsTriggerProps = useCallback(
    () => ({
      focusKey: CAPTIONS_FOCUS_KEY,
      innerRef: captionsTriggerRef,
      onPress: openCaptionsMenu,
    }),
    [openCaptionsMenu]
  );

  const getCaptionsMenuProps = useCallback(
    () => ({
      triggerFocusKey: CAPTIONS_FOCUS_KEY,
      triggerInnerRef: captionsTriggerRef,
      closeMenu: closeCaptionsMenu,
    }),
    [closeCaptionsMenu]
  );

  return {
    isControlsActive: !captionsMenuOpened && isUserActive,
    captionsMenuOpened,
    handleCustomActivity,
    getCaptionsTriggerProps,
    getCaptionsMenuProps,
  };
};
