import React from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { useTheme } from 'styled-components/native';

import { VectorIconProps } from '~/assets/icons.types';
import { verticalScale } from '~/utils/layout';

export const LeftIcon = (props: VectorIconProps) => {
  const { colors } = useTheme();
  const { color = colors.text.default, size = verticalScale(24) } = props;

  return <AiOutlineLeft color={color} size={size * 1.5} />;
};
