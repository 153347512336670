import dayjs from 'dayjs';
import { AxiosRequestConfig } from 'axios';

import { IPlayerSource } from '../screens/Player/Player.types';
import { baseAssetsUrl } from '~/configs/env';

/**
 * https://easings.net/#easeOutCubic
 */
export const easeOutCubic = (x: number): number => {
  return 1 - Math.pow(1 - x, 3);
};

export const debounce = <T extends (...args: any[]) => any>(
  callback: T,
  waitFor: number
) => {
  let timeout: NodeJS.Timeout;
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      result = callback(...args);
    }, waitFor);
    return result;
  };
};

export const getLevelIcon = (level: number) => {
  switch (level) {
    case 1:
      return 'qd-level-beginner';
    case 2:
      return 'qd-level-medium';
    default:
      return 'qd-level-advanced';
  }
};

export const secondsToMinutes = (time: number) =>
  (Math.floor(time / 60) < 10
    ? '0' + Math.floor(time / 60)
    : Math.floor(time / 60)) +
  ':' +
  (Math.floor(time % 60) < 10
    ? '0' + Math.floor(time % 60)
    : Math.floor(time % 60));

export const getSources = (props: {
  streamMetadataHd?: string;
  streamMetadataHls?: string;
}): IPlayerSource[] => {
  const { streamMetadataHd, streamMetadataHls } = props;
  const videoSources = [];
  if (streamMetadataHls) {
    videoSources.push({
      src: streamMetadataHls,
      type: 'application/x-mpegURL',
    });
  }
  if (streamMetadataHd) {
    videoSources.push({
      src: streamMetadataHd,
      type: 'video/mp4',
    });
  }
  return videoSources;
};

export const getFormatedPlayerTime = (seconds: number): string => {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return ('00' + mins).slice(-2) + ':' + ('00' + secs).slice(-2);
};

/* eslint-disable no-bitwise */
export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    var r =
        (parseFloat(
          '0.' +
            Math.random().toString().replace('0.', '') +
            new Date().getTime()
        ) *
          16) |
        0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
/* eslint-enable no-bitwise */

export const omitProps = (obj: Record<string, any>, keys: string[]) => {
  const copy = Object.assign({}, obj);
  keys.forEach((key) => delete copy[key]);
  return copy;
};

export const isValidDate = (expireDate: string) => {
  if (!expireDate) {
    return false;
  }

  const today = dayjs();
  const expirationDate = dayjs(expireDate);

  return today.isBefore(expirationDate);
};

export const appendAxiosHeader = (
  config: AxiosRequestConfig,
  key: string,
  value: string
) => {
  if (!config.headers.common[key]) {
    config.headers.common[key] = value;
  }
};

export const getNameInitials = (fullname: string) => {
  const [name, surname = ''] = fullname.split(' ');
  return `${name.charAt(0)}${surname.charAt(0)}`.toUpperCase();
};

export const millisecondsToDaytime = (milliseconds: number) => {
  const minuteInMilliseconds = 60 * 1000;
  const hourInMilliseconds = minuteInMilliseconds * 60;
  const dayInMilliseconds = hourInMilliseconds * 24;

  const timeInDays = Math.floor(milliseconds / dayInMilliseconds);
  const timeInHours = Math.floor((milliseconds / hourInMilliseconds) % 24);
  const timeInMinutes = Math.floor((milliseconds / minuteInMilliseconds) % 60);
  const remainingSeconds = Math.floor((milliseconds / 1000) % 60);

  return {
    days: timeInDays,
    hours: timeInHours,
    minutes: timeInMinutes,
    seconds: remainingSeconds,
  };
};

export const addLeftPadToNumber = (number: number, padLength: number) => {
  const value = number.toString();

  if (typeof ''.padStart === 'function') {
    return value.padStart(padLength, '0');
  }

  const padding = Array(padLength).fill('0').join('');

  if (value.length < padLength) {
    return `${padding}${value}`.slice(-padLength);
  }

  return value;
};

export const hardFormatImageURL = (url: string) =>
  url?.replace(baseAssetsUrl + '/', '');

/**
 * Language on xx-xx or yy-YY formats to 2 letter ISO 639-1 code
 * @link https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 */
export const localisationToTrackLanguage = (language: string) => {
  return language.split('-')[0];
};

export const capitalizeAllWordsInPhrase = (phrase?: string) => {
  if (!phrase) {
    return '';
  }
  return phrase.replace(/(^\w|\s\w)/g, (match) => match.toUpperCase());
};
