import React, { useLayoutEffect } from 'react';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';

import { Logo } from '~/assets/icons/Logo';
import { useSession } from '~/contexts/SessionContext/SessionContext';

import { Container } from './SplashScreen.styles';

const ICON_DIMENSIONS = Platform.OS === 'web' ? 150 : 80;

export const SplashScreen = () => {
  const { handleAppStart } = useSession();
  const { colors } = useTheme();

  useLayoutEffect(() => {
    handleAppStart();
  }, [handleAppStart]);

  return (
    <Container>
      <Logo
        fill={colors.splashscreen.icon}
        width={ICON_DIMENSIONS}
        height={ICON_DIMENSIONS}
      />
    </Container>
  );
};
