import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { SVGIconProps } from '../icons.types';

export const SpaceBarIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const {
    width = horizontalScale(16),
    height = verticalScale(8),
    fill = colors.text.default,
  } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 16 8">
      <Path d="M14 .27v4.927H2V.269H0v7.392h16V.269h-2z" fill={fill} />
    </Svg>
  );
};
