import { useEffect, RefObject } from 'react';
import { findNodeHandle, TouchableOpacity } from 'react-native';
import { FocusableElement } from '@noriginmedia/react-spatial-navigation';

import { isNativeButton } from '~/components/FocusableButton/FocusableButton';
import type { FocusableButtonRef } from '~/components/FocusableButton/FocusableButton.types';

type INativeRef = RefObject<TouchableOpacity>;
type IFocusTarget = RefObject<FocusableButtonRef> | string;

interface INextFocusProps {
  nextFocusUp?: IFocusTarget;
  nextFocusDown?: IFocusTarget;
  nextFocusRight?: IFocusTarget;
  nextFocusLeft?: IFocusTarget;
}

export const useSetFocusDirection = (
  target: IFocusTarget | undefined,
  setFocus: FocusableElement['setFocus'],
  props: INextFocusProps
) => {
  const { nextFocusUp, nextFocusDown, nextFocusRight, nextFocusLeft } = props;

  useEffect(() => {
    if (
      typeof target === 'string' ||
      !target?.current ||
      !isNativeButton(target.current)
    ) {
      return;
    }

    const nextFocusUpRef = nextFocusUp as INativeRef;
    const nextFocusDownRef = nextFocusDown as INativeRef;
    const nextFocusRightRef = nextFocusRight as INativeRef;
    const nextFocusLeftRef = nextFocusLeft as INativeRef;

    target.current.setNativeProps({
      nextFocusUp: nextFocusUpRef
        ? findNodeHandle(nextFocusUpRef.current)
        : null,
      nextFocusDown: nextFocusDownRef
        ? findNodeHandle(nextFocusDownRef.current)
        : null,
      nextFocusRight: nextFocusRightRef
        ? findNodeHandle(nextFocusRightRef.current)
        : null,
      nextFocusLeft: nextFocusLeftRef
        ? findNodeHandle(nextFocusLeftRef.current)
        : null,
    });
  }, [nextFocusDown, nextFocusLeft, nextFocusRight, nextFocusUp, target]);

  const onArrowPress = (direction: string) => {
    if (nextFocusUp && direction === 'up') {
      if (target !== nextFocusUp) {
        setFocus(nextFocusUp as string);
      }
      return false;
    }
    if (nextFocusDown && direction === 'down') {
      if (target !== nextFocusDown) {
        setFocus(nextFocusDown as string);
      }
      return false;
    }
    if (nextFocusRight && direction === 'right') {
      if (target !== nextFocusRight) {
        setFocus(nextFocusRight as string);
      }
      return false;
    }
    if (nextFocusLeft && direction === 'left') {
      if (target !== nextFocusLeft) {
        setFocus(nextFocusLeft as string);
      }
      return false;
    }

    return true;
  };

  return {
    onArrowPress: typeof target === 'string' ? onArrowPress : undefined,
    innerRef: typeof target !== 'string' ? (target as INativeRef) : undefined,
    focusKey: typeof target === 'string' ? target : undefined,
  };
};
