import React, { useEffect } from 'react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../ActionButton/ActionButton';
import { ScreenBackground } from '../ScreenBackground/ScreenBackground';

import { ErrorContainer, ErrorText } from './PlayerError.styles';

import type { IPlayerError } from './PlayerError.types';

const FOCUS_KEY = 'RETRY_BUTTON';

const PlayerErrorComponent = (props: IPlayerError) => {
  const { retry, setFocus } = props;

  const { t } = useTranslation();

  useEffect(() => {
    setFocus(FOCUS_KEY);
  }, [setFocus]);

  return (
    <ScreenBackground>
      <ErrorContainer>
        <ErrorText>
          {t(
            'player.error',
            'Ops! Não estamos conseguindo reproduzir este vídeo. Verifique sua conexão com a internet e se sua televisão está atualizada.'
          )}
        </ErrorText>

        <ActionButton
          onEnterPress={retry}
          onPress={retry}
          focusKey={FOCUS_KEY}
          hasTVPreferredFocus
          text={t('common.try_again', 'Tentar novamente')}
        />
      </ErrorContainer>
    </ScreenBackground>
  );
};

export const PlayerError = withFocusable()(PlayerErrorComponent);
