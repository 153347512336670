import { useRef, useCallback } from 'react';
import {
  ScrollView,
  Platform,
  LayoutChangeEvent,
  Dimensions,
  UIManager,
  findNodeHandle,
} from 'react-native';
import { LayoutObject } from '@noriginmedia/react-spatial-navigation';

import { BANNER_HEIGHT } from '~/utils/constants';
import { verticalScale } from '~/utils/layout';

const { height: SCREEN_HEIGHT } = Dimensions.get('screen');

const MARGIN_FROM_BANNER = verticalScale(20);

export const useHomeScrollPosition = <T extends ScrollView>() => {
  const scrollRef = useRef<T>(null);
  const visibleHeight = useRef(SCREEN_HEIGHT);

  const onLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    visibleHeight.current = nativeEvent.layout.height || visibleHeight.current;
  };

  const handleScroll = (elementY: number) => {
    const offsetY = elementY - MARGIN_FROM_BANNER - BANNER_HEIGHT;

    scrollRef?.current?.scrollTo({
      animated: true,
      x: 0,
      y: offsetY,
    });
  };

  const handleElementFocus = useCallback((element: LayoutObject) => {
    if (Platform.OS !== 'web') {
      const elementNode = findNodeHandle(element.node as any);
      const scrollNode = findNodeHandle(scrollRef.current);

      if (elementNode === null || scrollNode === null) {
        return;
      }

      UIManager.measureLayout(
        elementNode,
        scrollNode,
        () => {},
        (_left, top) => handleScroll(top)
      );

      return;
    }

    handleScroll(element.y);
  }, []);

  return {
    handleElementFocus,
    getScrollViewProps: () => ({
      ref: scrollRef as any,
      onLayout,
      showsVerticalScrollIndicator: false,
      scrollEventThrottle: 16,
      scrollEnabled: Platform.OS === 'web',
    }),
  };
};
