import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';

export const PlayIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const { width = 33, height = 35, fill = colors.text.default } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 33 35">
      <G fill="none" fillRule="evenodd">
        <Path d="M-7-3h40v40H-7V-3zm0 0h40v40H-7V-3zm0 0h40v40H-7V-3zm0 0h40v40H-7V-3z" />
        <Path
          fill={fill}
          d="M4.095 0c.671 0 1.344.17 1.943.495l24.81 13.399A4.092 4.092 0 0 1 33 17.5a4.09 4.09 0 0 1-2.151 3.605l-24.811 13.4A4.089 4.089 0 0 1 4.095 35 4.102 4.102 0 0 1 0 30.9V4.1C0 1.84 1.837 0 4.095 0z"
        />
      </G>
    </Svg>
  );
};
