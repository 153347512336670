import React, { Fragment } from 'react';
import { ActivityIndicator } from 'react-native';
import { useTheme } from 'styled-components/native';

import { verticalScale } from '~/utils/layout';

import { Container } from './LoadingIndicator.styles';

import type { ILoadingIndicator } from './LoadingIndicator.types';

const SIZE = verticalScale(60);

export const LoadingIndicator = (props: ILoadingIndicator) => {
  const { loading } = props;
  const { colors } = useTheme();

  return loading ? (
    <Container>
      <ActivityIndicator size={SIZE} color={colors.spinner.primary} />
    </Container>
  ) : (
    <Fragment />
  );
};
