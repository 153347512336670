import styled from 'styled-components/native';
import { Dimensions } from 'react-native';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { FocusableButton } from '~/components/FocusableButton/FocusableButton';
import { PROFILE_SCREENS_PADDING } from '~/utils/constants';

const { width: SCREEN_WIDTH } = Dimensions.get('screen');

export const Content = styled.View`
  flex: 1;
  padding-top: ${PROFILE_SCREENS_PADDING}px;
`;

export const AvatarContentComponent = styled.View`
  align-items: center;
  margin-top: ${verticalScale(50)}px;
  margin-bottom: ${verticalScale(72)}px;
`;

export const AvatarContent = withFocusable()(AvatarContentComponent);

export const StyledFocusableAvatar = styled(FocusableButton)`
  border-radius: ${verticalScale(180 / 2)}px;
  width: ${horizontalScale(180)}px;
  height: ${verticalScale(180)}px;
  border: none;
`;

const BaseText = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
`;

export const Title = styled(BaseText)`
  font-size: ${verticalScale(36)}px;
  margin-bottom: ${verticalScale(48)}px;
`;

export const SubTitle = styled(BaseText)`
  font-size: ${verticalScale(24)}px;
  margin-top: ${verticalScale(12)}px;
`;

const FORM_WIDTH = SCREEN_WIDTH * 0.6;

export const Form = styled.View`
  margin: 0 auto;
  width: ${FORM_WIDTH}px;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const LeftColumn = styled.View`
  flex: 1;
`;

export const RightColumn = styled.View`
  flex: 1;
  margin-left: ${horizontalScale(48)}px;
`;

export const FormActions = styled.View`
  margin-top: auto;
  flex-direction: row;
`;

export const FormLoading = styled.View`
  margin-top: ${verticalScale(96)}px;
  width: ${FORM_WIDTH}px;
`;
