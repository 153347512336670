import { api } from '~/configs/api';

import {
  IAddProfile,
  IAvatarResponse,
  IProfileResponse,
} from './profilesApi.types';

export const fetchProfiles = async () => {
  const { data } = await api.get<IProfileResponse>(
    '/users/multiple-profiles/all-profiles'
  );

  return data;
};

export const addProfile = async (payload: { name: string; avatar: string }) => {
  const { data } = await api.post<IAddProfile>(
    '/users/multiple-profiles',
    payload
  );

  return data;
};

export const authProfile = async (payload: { customerId: number }) => {
  const { data } = await api.post<IAddProfile>(
    '/users/multiple-profiles/select-profile',
    payload
  );

  return data;
};

export const updateProfileById = async (
  id: number,
  payload: { name: string; avatar: string }
) => {
  const { data } = await api.put(`/users/multiple-profiles/${id}`, payload);

  return data;
};

export const deleteProfileById = async (profileId: number) => {
  await api.delete(`/users/multiple-profiles/${profileId}`);
  return profileId;
};

export const fetchDefaultAvatars = async () => {
  const { data } = await api.get<IAvatarResponse[]>(
    '/users/profile-default-avatars'
  );

  return data;
};
