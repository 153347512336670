import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { findBestAvailableLanguage } from 'react-native-localize';

import { resources } from '../assets/locales/resources';

const FALLBACK_LANGUAGE = 'pt-BR';

const getBestLanguage = () => {
  const avaliableTranslations = Object.keys(resources);

  return (
    findBestAvailableLanguage(avaliableTranslations)?.languageTag ||
    FALLBACK_LANGUAGE
  );
};

i18n.use(initReactI18next).init({
  resources: resources,
  lng: getBestLanguage(),
  fallbackLng: FALLBACK_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
});
