import { api } from '~/configs/api';
import { IDevicesListProps } from '~/utils/systemInfo';

import type {
  ICurrentUser,
  IUserAccess,
  ProgramsAccess,
  UpdateUserPreferencesParams,
} from './userApi.types';

export const fetchCurrentUser = async () => {
  const { data } = await api.get<ICurrentUser>('/users/customers/current-user');

  return data;
};

export const fetchUserProgramsAccess = async () => {
  let programsAccess: ProgramsAccess = {};
  const { data } = await api.get<IUserAccess[]>(
    '/users/customersprograms/access'
  );

  for (let i = 0; i < data.length; i += 1) {
    const profile = data[i];
    programsAccess = profile.programs.reduce(
      (acc: ProgramsAccess, programId: number) => ({
        ...acc,
        [programId]: {
          createdAt: profile.createdAt,
          expirationDate: profile.expirationDate,
          programTypes: profile.programTypes,
        },
      }),
      programsAccess
    );
  }
  return programsAccess;
};

export const updateUserPreferences = async (
  payload: UpdateUserPreferencesParams
) => {
  await api.post('/users/customerspreferences', payload);
};

export const fetchUserPlayerAccess = async () => {
  const { data } = await api.get<{ allowed: boolean }>('/locker/watch');

  return data;
};

export const fetchUserDevicesConnected = async (status?: string) => {
  const { data } = await api.get<IDevicesListProps>(
    `/devices/devices?status=${status ?? ''}`
  );

  return data;
};

export const deleteUserDevicesConnected = async () => {
  const { data } = await api.delete<{ data: boolean }>('/devices/devices');

  return data;
};
