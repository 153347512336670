import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { getDeviceInfo } from '~/utils/systemInfo';
import { envShortName, appVersion, sentryDNS } from '../env';

import { checkIfErrorIsIgnored } from './ignoredErrors';

export const sentryRoutingInstrumentation = null;

export const initSentry = async () => {
  Sentry.init({
    dsn: sentryDNS,
    environment: envShortName,
    release: appVersion,
    tracesSampleRate: 0.75,
    integrations: [new BrowserTracing()],
    beforeSend(event) {
      const shouldIgnoreError = checkIfErrorIsIgnored(event);
      if (shouldIgnoreError) {
        return null;
      }
      return event;
    },
  });

  const deviceInfo = await getDeviceInfo();
  if (deviceInfo) {
    Sentry.setContext('os', {
      name: deviceInfo.deviceOS,
      version: deviceInfo.platformVersion,
    });

    Sentry.setContext('device', {
      brand: deviceInfo.brand,
      family: deviceInfo.family,
      model: deviceInfo.modelName,
      screen_height_pixels: deviceInfo.screenHeight,
      screen_width_pixels: deviceInfo.screenWidth,
    });
  }
};

export const SentryClient = Sentry;
