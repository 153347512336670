import { BackspaceIcon } from '~/assets/icons/BackspaceIcon';
import { KeyboardLayoutMap } from '../../Keyboard.types';
import {
  ALPHANUMERIC_SYMBOL,
  SPECIAL_SYMBOL,
} from '../KeyboardTemplate/buildKeysRow';

import type { KeyboardTemplate } from '../KeyboardTemplate/KeyboardTemplate.types';

type GenerateEmailKeys = (param: {
  keyboardLayout: KeyboardLayoutMap;
  toggleSpecialLayout: () => void;
  handleBackspace: () => void;
}) => KeyboardTemplate;

export const generateEmailKeys: GenerateEmailKeys = (params) => {
  const { keyboardLayout, toggleSpecialLayout, handleBackspace } = params;

  return [
    [
      {
        symbol: '@gmail.com',
        columns: 'auto',
      },
      {
        symbol: '@hotmail.com',
        columns: 'auto',
      },
      {
        symbol: '@yahoo.com',
        columns: 'auto',
      },
    ],
    [
      {
        symbol:
          keyboardLayout === KeyboardLayoutMap.Special
            ? ALPHANUMERIC_SYMBOL
            : SPECIAL_SYMBOL,
        columns: 2,
        customAction: toggleSpecialLayout,
      },
      { symbol: '@' },
      { symbol: '.' },
      { symbol: '.com', columns: 2 },
      { symbol: '.br', columns: 2 },
      {
        symbol: BackspaceIcon,
        columns: 2,
        customAction: handleBackspace,
      },
    ],
  ];
};
