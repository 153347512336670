import React from 'react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';

import { Container, Title } from './FocusableTitle.styles';

import type { IFocusableTitle } from './FocusableTitle.types';

const FocusableTitleComponent = (props: IFocusableTitle) => {
  const { children, stealFocus, focused } = props;

  return (
    <Container onFocus={stealFocus}>
      <Title focused={focused}>{children}</Title>
    </Container>
  );
};

export const FocusableTitle = withFocusable()(FocusableTitleComponent);
