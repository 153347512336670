import { api } from '~/configs/api';

import type { IFavoritePrograms } from './favoriteProgramsApi.types';

export const fetchAllFavoritePrograms = async () => {
  const { data } = await api.get<IFavoritePrograms[]>(
    '/users/favorites/user-favorites/v2'
  );
  return data;
};

export const checkIfProgramIsFavorite = async (programId: number) => {
  const { data } = await api.get<boolean>(
    `/users/favorites/is-favorite/${programId}`
  );
  return data;
};

export const addProgramToFavorites = async (programId: number) => {
  await api.post('/users/favorites/user-favorites', { id: programId });
};

export const removeProgramfromFavorites = async (programId: number) => {
  await api.delete(`/users/favorites/user-favorites/${programId}`);
};
