import styled from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';

export const Container = styled.View`
  align-items: flex-start;
`;

export const HeaderText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(64)}px;
  font-weight: bold;
`;

export const TextDescription = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(27)}px;
`;

export const Topic = styled.View`
  margin-top: ${verticalScale(12)}px;
  flex-direction: row;
  align-items: stretch;
`;

export const TopicMarker = styled.View`
  background-color: ${({ theme }) => theme.colors.howItWorksTopic};
  width: ${verticalScale(6)}px;
  margin-right: ${horizontalScale(12)}px;
  border-radius: ${verticalScale(4)}px;
`;

export const TopicText = styled(TextDescription)`
  margin: ${verticalScale(8)}px 0;
`;
