import styled from 'styled-components/native';
import { Dimensions } from 'react-native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { LinearGradient } from '../LinearGradient/LinearGradient';
import { FocusableButton } from '../FocusableButton/FocusableButton';

import type { IExpandedSideMenu } from './SideMenu.types';

const { width: SCREEN_WIDTH } = Dimensions.get('screen');

export const SIDEMENU_WIDTH = horizontalScale(104);
export const EXPANDED_SIDEMENU_WIDTH = horizontalScale(300);

export const Container = styled.View<IExpandedSideMenu>`
  background-color: ${({ theme: { colors } }) => colors.background};
  width: ${({ isMenuExpanded }) =>
    isMenuExpanded ? EXPANDED_SIDEMENU_WIDTH : SIDEMENU_WIDTH}px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 12;
  left: -${EXPANDED_SIDEMENU_WIDTH}px;
  transform: translateX(${EXPANDED_SIDEMENU_WIDTH}px);
`;

export const GradientOverlay = styled(LinearGradient).attrs(
  ({ theme: { colors } }) => ({
    colors: [colors.background, 'transparent'],
    direction: 'horizontal',
  })
)`
  width: ${SCREEN_WIDTH}px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: ${EXPANDED_SIDEMENU_WIDTH * 0.9}px;
  z-index: -1;
`;

export const Header = styled.View<IExpandedSideMenu>`
  padding: 0 ${horizontalScale(28)}px;
  flex-direction: row;
  align-items: center;
  height: ${verticalScale(120)}px;
  transform: ${({ isMenuExpanded }) =>
    isMenuExpanded
      ? 'translateX(0)'
      : `translateX(${-EXPANDED_SIDEMENU_WIDTH}px)`};
`;

export const StyledFocusableButton = styled(FocusableButton).attrs(
  ({ theme: { colors } }) => ({
    focusedBorderColor: colors.spatialFocus,
    bgFocusedColor: 'transparent',
  })
)`
  border-radius: ${verticalScale(48)}px;
`;

export const Username = styled.Text`
  margin-left: ${horizontalScale(16)}px;
  font-size: ${verticalScale(24)}px;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme: { colors } }) => colors.text.default};
`;

export const Content = styled.View`
  flex: 1;
  justify-content: center;
`;
