import React, { memo, useState, useRef, useLayoutEffect } from 'react';
import { useTheme } from 'styled-components/native';
import { Animated, Image } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';

import { baseAssetsUrl } from '~/configs/env';
import { BatteryIcon } from '~/assets/icons/Battery/Battery';
import { ClockIcon } from '~/assets/icons/Clock/Clock';
import { FlagIcon } from '~/assets/icons/Flag/Flag';

import {
  Container,
  StyledLinearGradient,
  BannerImage,
  BannerLogo,
  LabelsRow,
  Label,
  Text,
} from './Banner.styles';

import type { IBanner } from './Banner.types';

const ICON_SIZE = 15;

const BannerComponent = (props: IBanner) => {
  const { colors } = useTheme();
  const {
    bannerURL,
    logoNameURL,
    duration,
    objective,
    level,
    levelId,
    children,
  } = props;

  const [aspectRatio, setAspectRatio] = useState<number>();
  const logoOpacity = useRef(new Animated.Value(0)).current;
  const logoUri = `${baseAssetsUrl}/${logoNameURL}`;

  const { name: routeName } = useRoute();
  const isWithingProgram = routeName === 'Program';

  useLayoutEffect(() => {
    Image.getSize(
      logoUri,
      (width, height) => {
        setAspectRatio(width / height);
        Animated.timing(logoOpacity, {
          toValue: 1,
          duration: 200,
          useNativeDriver: true,
        }).start();
      },
      (_err) => {}
    );

    return () => {
      logoOpacity.setValue(0);
      setAspectRatio(undefined);
    };
  }, [logoOpacity, logoUri]);

  return (
    <Container>
      <BannerImage
        resizeMode="stretch"
        source={{ uri: `${baseAssetsUrl}/${bannerURL}` }}
      >
        <StyledLinearGradient
          colors={[
            colors.overlay,
            'transparent',
            'transparent',
            'transparent',
            colors.overlay,
          ]}
          isWithingProgram={isWithingProgram}
        >
          <BannerLogo
            source={{ uri: logoUri }}
            resizeMode="contain"
            aspectRatio={aspectRatio}
            style={{ opacity: logoOpacity }}
          />

          <LabelsRow>
            <Label>
              <ClockIcon size={ICON_SIZE} />
              <Text>{duration}</Text>
            </Label>
            <Label>
              <FlagIcon size={ICON_SIZE} />
              <Text>{objective}</Text>
            </Label>
            <Label>
              <BatteryIcon size={ICON_SIZE} level={levelId} />
              <Text>{level}</Text>
            </Label>
          </LabelsRow>

          {children}
        </StyledLinearGradient>
      </BannerImage>
    </Container>
  );
};

export const Banner = withFocusable()(memo(BannerComponent));
