import React, { createContext, useContext, useState, useEffect } from 'react';

import { api } from '~/configs/api';

import { INetworkContext } from './NetworkContext.types';

const NetworkContext = createContext({} as INetworkContext);

const URL = 'https://micro.queima.tv/prod/programs';
const TIMEOUT = 5 * 1000; // 5s
const INTERVAL = 5 * 1000; // 5s

export const useNetwork = () => useContext(NetworkContext);

export const NetworkProvider: React.FC = ({ children }) => {
  const [isConnected, setIsConnected] = useState<boolean | null>(true);

  const testConnection = (url = URL): Promise<boolean> => {
    return new Promise((resolve) => {
      const isOnline = () => resolve(true);
      const isOffline = () => resolve(false);

      const xhr = new XMLHttpRequest();

      xhr.onerror = isOffline;
      xhr.ontimeout = isOffline;
      xhr.onreadystatechange = () => {
        if (xhr.readyState === xhr.HEADERS_RECEIVED) {
          if (xhr.status) {
            isOnline();
          } else {
            isOffline();
          }
        }
      };

      xhr.open('GET', url);
      xhr.timeout = TIMEOUT;
      xhr.send();
    });
  };

  useEffect(() => {
    api.interceptors.response.use(undefined, (error) => {
      testConnection().then(setIsConnected);
      return Promise.reject(error);
    });
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!isConnected) {
      interval = setInterval(() => {
        testConnection().then(setIsConnected);
      }, INTERVAL);
    }

    return () => clearInterval(interval);
  }, [isConnected]);

  return (
    <NetworkContext.Provider
      value={{
        isConnected,
        testConnection,
        setIsConnected,
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};
