import React, { memo } from 'react';
import { useTheme } from 'styled-components/native';

import { KeyRow, KeyboardKey } from '../../Keyboard.styles';

import type {
  IKeyboardTemplate,
  IKeyboardTemplateKey,
} from './KeyboardTemplate.types';

const KeyboardTemplateComponent = (props: IKeyboardTemplate) => {
  const { textRef, template, onChangeText, hasGap, firstKeyRef, focusKey } =
    props;

  const { colors } = useTheme();

  const onKeyPress = (item: IKeyboardTemplateKey) => {
    if (item.customAction) {
      item.customAction();
      return;
    }

    if (typeof item.symbol === 'string') {
      const newText = textRef.current + item.symbol;
      onChangeText(newText);
    }
  };

  const handleKeyIcon = (focused: boolean, item: IKeyboardTemplateKey) => {
    const Icon = typeof item.symbol === 'string' ? undefined : item.symbol;

    if (!Icon) {
      return null;
    }

    return <Icon fill={colors.keyboard.keyFocused} />;
  };

  return (
    <>
      {template.map((row, rowIndex) => (
        <KeyRow
          key={rowIndex}
          hasGap={template.length - 1 !== rowIndex || hasGap}
        >
          {row.map((item, itemIndex) => {
            const isFirstKey = itemIndex === 0 && rowIndex === 0;

            return (
              <KeyboardKey
                key={`${rowIndex}-${itemIndex}`}
                innerRef={isFirstKey ? firstKeyRef : undefined}
                focusKey={isFirstKey ? focusKey : undefined}
                text={typeof item.symbol === 'string' ? item.symbol : undefined}
                icon={(focused) => handleKeyIcon(focused, item)}
                onPress={() => onKeyPress(item)}
                onEnterPress={() => onKeyPress(item)}
                hasGap={row.length - 1 !== itemIndex}
                columns={item.columns}
                selected={item.selected}
              />
            );
          })}
        </KeyRow>
      ))}
    </>
  );
};

export const KeyboardTemplate = memo(KeyboardTemplateComponent);
