import React, { useEffect } from 'react';
import {
  FocusableElement,
  withFocusable,
} from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';

import { FocusItems } from '~/types';
import { verticalScale } from '~/utils/layout';
import { FloatingLogo } from '~/components/FloatingLogo/FloatingLogo';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { TvOffIcon } from '~/assets/icons/TvOffIcon';
import { ActionButton } from '~/components/ActionButton/ActionButton';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import { useSession } from '~/contexts/SessionContext/SessionContext';

import {
  Content,
  Box,
  IconContent,
  Title,
  Description,
  Buttons,
} from './DeviceDisconnected.styles';

const ICON_SIZE = verticalScale(48);

const DeviceDisconnectedComponent = (props: FocusableElement) => {
  const { setFocus, stealFocus, updateAllSpatialLayouts } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const { t } = useTranslation();
  const { colors } = useTheme();

  const { handleLogout } = useSession();

  useEffect(() => {
    setFocus(FocusItems.CancelProfileDelete);
  }, [handleLogout, setFocus]);

  return (
    <ScreenBackground>
      <FloatingLogo />

      <Content>
        <Box>
          <IconContent>
            <TvOffIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              fill={colors.selectedItem}
            />
          </IconContent>

          <Title>
            {t('device_disconnected.title', 'Dispositivo desconectado')}
          </Title>

          <Description>
            {t(
              'device_disconnected.description',
              'O assinante decidiu desconectar este dispositivo. Realize um novo acesso à plataforma para continuar.'
            )}
          </Description>

          <Buttons>
            <ActionButton
              rightGap
              focusKey={FocusItems.CancelProfileDelete}
              hasTVPreferredFocus
              text={t('device_disconnected.try_again', 'Acessar novamente')}
              onPress={handleLogout}
              onEnterPress={handleLogout}
            />
          </Buttons>
        </Box>
      </Content>
    </ScreenBackground>
  );
};

export const DeviceDisconnected = withFocusable({ blockNavigationOut: true })(
  DeviceDisconnectedComponent
);
