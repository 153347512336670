import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { SVGIconProps } from '../icons.types';

export const UnlockIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const { width = 64, height = 64, fill = colors.text.default } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 64 64">
      <G fill="none" fillRule="evenodd">
        <Path d="M0 0h64v64H0z" />
        <Path
          fill={fill}
          d="M37.703 44.714a.29.29 0 0 1-.152.192l-10.525 6.077a.317.317 0 1 1-.477-.277V38.55c0-.115.058-.221.16-.277a.293.293 0 0 1 .317.003l10.528 6.074c.05.03.093.072.12.12a.312.312 0 0 1 .029.243zM32.13 2.667c8.498 0 15.418 6.727 15.728 15.149l.01.59v1.472a1.432 1.432 0 0 1-2.845.192l-.016-.232v-1.432c0-7.16-5.717-12.877-12.877-12.877a12.812 12.812 0 0 0-12.865 12.3l-.012.577v8.587H49.3c2.221 0 4.076 1.729 4.275 3.905l.018.388v25.755c0 2.221-1.731 4.076-3.905 4.275l-.388.018H14.959c-2.223 0-4.076-1.731-4.275-3.905l-.018-.388V31.286c0-2.224 1.73-4.077 3.905-4.276l.388-.017h1.432v-8.587c0-8.696 7.043-15.739 15.74-15.739zM49.3 29.854H14.96c-.737 0-1.308.497-1.415 1.193l-.018.24V57.04c0 .737.497 1.305 1.193 1.411l.24.018H49.3c.735 0 1.308-.495 1.415-1.19l.018-.24V31.287c0-.737-.5-1.307-1.194-1.414l-.238-.018zm-22.437 5.51c.453 0 .896.094 1.303.274l.297.15 10.525 6.078a3.163 3.163 0 0 1 1.486 1.936 3.16 3.16 0 0 1-.318 2.42c-.224.391-.526.727-.887.99l-.28.182-10.529 6.074a3.122 3.122 0 0 1-1.256.413l-.336.02a3.167 3.167 0 0 1-1.6-.435 3.144 3.144 0 0 1-1.573-2.385l-.019-.378V38.55c0-.853.33-1.653.936-2.256a3.157 3.157 0 0 1 1.89-.91l.366-.02h-.005z"
        />
      </G>
    </Svg>
  );
};
