import styled, { css } from 'styled-components';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { PLAYER_CONTROLS_HEIGHT } from '~/components/PlayerControls/PlayerControls.styles';

import type { IsControlsActive } from './Player.types';

export const Container = styled.div<IsControlsActive>`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .video-js {
    width: 100%;
    height: 100%;
    padding-top: 0;
  }

  /* Text track container */
  .vjs-text-track-cue {
    ${({ isControlsActive }) =>
      isControlsActive
        ? css`
            transform: translateY(-${PLAYER_CONTROLS_HEIGHT}px);
          `
        : css`
            transform: translateY(0px);
          `}
  }

  /* Text track itseft */
  .vjs-text-track-cue > div {
    padding: 0 ${horizontalScale(8)}px;
  }

  /* Support for Tizen, .vjs-text-track-cue is not available */
  video::-webkit-media-text-track-display {
    ${({ isControlsActive }) => {
      if (!window.tizen) {
        return;
      }

      const basePosition = -verticalScale(40);
      const activePosition = -PLAYER_CONTROLS_HEIGHT + basePosition;

      return isControlsActive
        ? css`
            transform: translateY(${activePosition}px) !important;
          `
        : css`
            transform: translateY(${basePosition}px) !important;
          `;
    }}
  }
`;
