/**
 * Common key map for Tizen and WebOS controllers
 */
export enum CommonKeyMap {
  ArrowLeft = 37,
  ArrowUp = 38,
  ArrowRight = 39,
  ArrowDown = 40,
  Enter = 13,
  MediaPlay = 415,
  MediaPause = 19,
  MediaStop = 413,
  MediaRewind = 412,
  MediaFastForward = 417,
  MediaRecord = 416,
  Info = 457,
  ColorF0Red = 403,
  ColorF1Green = 404,
  ColorF2Yellow = 405,
  ColorF3Blue = 406,
  'Controller-0' = 48,
  'Controller-1' = 49,
  'Controller-2' = 50,
  'Controller-3' = 51,
  'Controller-4' = 52,
  'Controller-5' = 53,
  'Controller-6' = 54,
  'Controller-7' = 55,
  'Controller-8' = 56,
  'Controller-9' = 57,
}

/**
 * Exclusive key map for Tizen controller
 * @link https://developer.samsung.com/smarttv/develop/guides/user-interaction/remote-control.html
 */
export enum TizenKeyMap {
  Back = 10009,
  VolumeUp = 447,
  VolumeDown = 448,
  VolumeMute = 449,
  ChannelUp = 427,
  ChannelDown = 428,
  ChannelList = 10073,
  MediaPlayPause = 10252,
  MediaTrackPrevious = 10232,
  MediaTrackNext = 10233,
  Minus = 189,
  PreviousChannel = 10190,
  Menu = 18,
  Tools = 10135,
  Source = 10072,
  Exit = 10182,
  Caption = 10221,
  'E-Manual' = 10146,
  '3D' = 10199,
  Extra = 10253,
  PictureSize = 10140,
  Soccer = 10228,
  Teletext = 10200,
  MTS = 10195,
  Search = 10225,
  Guide = 458,
}

/**
 * Exclusive key map for WebOS controller
 * @link https://webostv.developer.lge.com/design/webos-tv-system-ui/remote-control
 */
export enum WebosKeyMap {
  Back = 461,
  ChannelUp = 33,
  ChannelDown = 34,
}
