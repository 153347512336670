import React from 'react';
import { useTranslation } from 'react-i18next';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';

import { FocusItems } from '~/types';
import { useBackHandler } from '~/hooks/useBackHandler';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';
import { ActionButton } from '../ActionButton/ActionButton';

import { PopupOverlay } from './components/PopupOverlay';
import { Container, Options, Title } from './PopupConfirmation.styles';

import type { IPopupConfirmation } from './PopupConfirmation.types';

const PopupConfirmationComponent = (props: IPopupConfirmation) => {
  const {
    handleLogout = () => {},
    handleCancel = () => {},
    onItemFocus,
    onItemBlur,
  } = props;

  const { t } = useTranslation();
  useBackHandler(handleCancel);

  const yesButtonProps = useBlockFocusDirection(['up', 'down', 'left']);
  const noButtonProps = useBlockFocusDirection(['up', 'down', 'right']);

  return (
    <PopupOverlay>
      <Container>
        <Title>
          {t('home.logout_confirmation', 'Deseja sair da sua conta?')}
        </Title>
        <Options>
          <ActionButton
            rightGap
            expanded
            onBecameFocused={onItemFocus}
            onBecameBlurred={onItemBlur}
            hasTVPreferredFocus
            onPress={handleLogout}
            onEnterPress={handleLogout}
            focusKey={FocusItems.ConfirmLogout}
            text={t('common.yes', 'Sim')}
            {...yesButtonProps}
          />
          <ActionButton
            expanded
            onBecameFocused={onItemFocus}
            onBecameBlurred={onItemBlur}
            onPress={handleCancel}
            onEnterPress={handleCancel}
            text={t('common.no', 'Não')}
            {...noButtonProps}
          />
        </Options>
      </Container>
    </PopupOverlay>
  );
};

export const PopupConfirmation = withFocusable({
  blockNavigationOut: true,
})(PopupConfirmationComponent);
