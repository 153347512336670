import { api } from '~/configs/api';

import {
  IInstructorResponse,
  IInstructorProgramResponse,
} from './instructorsApi.types';

export const fetchAllInstructors = async () => {
  const { data } = await api.get<IInstructorResponse[]>(
    '/programs/instructors/all-valid-instructors'
  );

  return data;
};

export const fetchInstructor = async (id: number) => {
  const { data } = await api.get<IInstructorResponse>(
    `/programs/instructors/${id}`
  );
  return data;
};

export const fetchInstructorPrograms = async (id: number) => {
  const { data } = await api.get<IInstructorProgramResponse[]>(
    `/programs/programs/${id}/instructor-by-programs`
  );
  return data;
};
