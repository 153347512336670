import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { FocusItems } from '~/types';
import { Banner } from '~/components/Banner/Banner';
import { GroupButtons } from '~/components/GroupButtons/GroupButtons';
import { LoadingIndicator } from '~/components/LoadingIndicator/LoadingIndicator';
import { HorizontalCardList } from '~/components/HorizontalCardList/HorizontalCardList';
import type {
  IBannerResponse,
  IExternalProgram,
} from '~/services/programs/programsApi.types';
import {
  fetchBanner,
  fetchAllPrograms,
  fetchRecommended,
} from '~/services/programs/programsApi';
import { useFavoritePrograms } from '~/contexts/MyListContext/MyListContext';
import { useSideMenu } from '~/contexts/SideMenuContext/SideMenuContext';
import { Crashlytics } from '~/utils/crashlytics';
import { fetchAllFavoritePrograms } from '~/services/favoritePrograms/favoriteProgramsApi';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { ScreenError } from '~/components/ScreenError/ScreenError';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';
import { FloatingLogo } from '~/components/FloatingLogo/FloatingLogo';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import type { SideMenuNavigationProp } from '~/routes/routes.types';
import { fetchUserPlayerAccess } from '~/services/user/userApi';
import type { FocusableButtonRef } from '~/components/FocusableButton/FocusableButton.types';
import { isNativeButton } from '~/components/FocusableButton/FocusableButton';

import {
  ScrollArea,
  BannerWrapper,
  BannerDescription,
  FeaturedProgram,
} from './Home.styles';
import { useHomeScrollPosition } from './hooks/useHomeScrollPosition';
import { ContentList } from './components/ContentList/ContentList';
import {
  formatBannerData,
  formatProgramsResponse,
} from './utils/formatHomeData';

import type { IHomeBannerState } from './Home.types';
import type { IContentListRow } from './components/ContentList/ContentList.types';

const HomeComponent = (props: FocusableElement) => {
  const { setFocus, stealFocus, updateAllSpatialLayouts } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const { t } = useTranslation();
  const navigation = useNavigation<SideMenuNavigationProp<'Home'>>();
  const { handleElementFocus, getScrollViewProps } = useHomeScrollPosition();

  const knowMoreRef = useRef<FocusableButtonRef>(null);
  const knowMoreButtonProps = useBlockFocusDirection('up');
  const presentationButtonProps = useBlockFocusDirection(['right', 'up']);
  const { loadFavoriteList } = useFavoritePrograms();
  const { enableSideMenuFocus } = useSideMenu();

  const [bannerData, setBannerData] = useState<IHomeBannerState>();
  const [featuredProgram, setFeaturedProgram] = useState<IBannerResponse>();
  const [programs, setPrograms] = useState<IContentListRow[]>();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    try {
      setError(false);
      setLoading(true);

      const [
        bannerResponse,
        recommendedResponse,
        favsResponse,
        programsResponse,
        // livesResponse,
      ] = await Promise.all([
        fetchBanner(),
        fetchRecommended(),
        fetchAllFavoritePrograms(),
        fetchAllPrograms(),
        // fetchAllLives(),
      ]);

      const formattedBannerData = formatBannerData({
        isFeatured: true,
        data: bannerResponse[0],
      });
      setBannerData(formattedBannerData);

      setFeaturedProgram(bannerResponse[0]);

      setPrograms([
        ...formatProgramsResponse(recommendedResponse, 'recommended'),
        ...formatProgramsResponse(programsResponse, 'all_programs'),
      ]);

      loadFavoriteList(favsResponse.map((favorite) => favorite.program[0]));

      setFocus(FocusItems.KnowMore);
    } catch (err) {
      setError(true);
      Crashlytics.handleException(err, 'Home initial loading');
    } finally {
      setLoading(false);
      enableSideMenuFocus();
    }
  }, [enableSideMenuFocus, loadFavoriteList, setFocus]);

  const handleProgramSelect = useCallback(
    (id: number, isLive: boolean = false) => {
      const navigateTo = isLive ? 'Live' : 'Program';
      navigation.navigate(navigateTo, { id });
    },
    [navigation]
  );

  const handleProgramFocus = useCallback(
    (data: IExternalProgram, isFeatured: boolean = false) => {
      const formattedBannerData = formatBannerData({ isFeatured, data });
      setBannerData(formattedBannerData);

      if (isFeatured) {
        if (knowMoreRef?.current && isNativeButton(knowMoreRef.current)) {
          knowMoreRef.current.setNativeProps({
            hasTVPreferredFocus: true,
          });
        } else {
          setFocus(FocusItems.KnowMore);
        }
      }
    },
    [setFocus]
  );

  const handlePresentationPress = async () => {
    if (!bannerData?.previewSources) {
      return;
    }

    const { allowed } = await fetchUserPlayerAccess();
    if (!allowed) {
      navigation.navigate('DeviceLimit');
      return;
    }

    navigation.navigate('Player', {
      sources: bannerData.previewSources,
      programId: bannerData.id,
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (error) {
    return (
      <ScreenError
        message={t('home.error', 'Não foi possível buscar os programas.')}
        retry={fetchData}
      />
    );
  }

  return (
    <ScreenBackground>
      <LoadingIndicator loading={loading} />

      {bannerData && (
        <>
          <FloatingLogo />

          <BannerWrapper>
            <Banner
              focusable={bannerData.isFeatured}
              bannerURL={bannerData.bannerURL}
              logoNameURL={bannerData.logoNameURL}
              level={bannerData.level}
              levelId={bannerData.levelId}
              objective={bannerData.objective}
              duration={bannerData.duration}
            >
              {bannerData.isFeatured ? (
                <GroupButtons
                  items={[
                    {
                      text: t('home.know_more', 'Saiba mais'),
                      focusKey: FocusItems.KnowMore,
                      innerRef: knowMoreRef,
                      onClick: () => handleProgramSelect(bannerData.id),
                      onBecameFocused: handleElementFocus,
                      hasTVPreferredFocus: true,
                      ...{ ...knowMoreButtonProps },
                    },
                    {
                      text: t('common.presentation', 'Apresentação'),
                      focusKey: FocusItems.Presentation,
                      onClick: handlePresentationPress,
                      onBecameFocused: handleElementFocus,
                      ...{ ...presentationButtonProps },
                    },
                  ]}
                />
              ) : (
                <BannerDescription numberOfLines={2}>
                  {bannerData.description.trim()}
                </BannerDescription>
              )}
            </Banner>
          </BannerWrapper>
        </>
      )}

      <ScrollArea {...getScrollViewProps()}>
        <FeaturedProgram>
          {featuredProgram && (
            <HorizontalCardList
              name={featuredProgram.name}
              onBecameFocused={handleElementFocus}
              cards={[
                {
                  id: featuredProgram.id,
                  imageURL: featuredProgram.imageCard,
                  name: featuredProgram.name,
                  onSelectCard: () => {},
                  onFocus: () => handleProgramFocus(featuredProgram, true),
                },
              ]}
            />
          )}
        </FeaturedProgram>

        {programs && (
          <ContentList
            contentRows={programs}
            onRowFocus={handleElementFocus}
            onProgramFocus={handleProgramFocus}
            onProgramSelect={handleProgramSelect}
          />
        )}
      </ScrollArea>
    </ScreenBackground>
  );
};

export const Home = withFocusable()(HomeComponent);
