import React, { useEffect, useState } from 'react';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ActivityIndicator } from 'react-native';
import { useTheme } from 'styled-components/native';

import { Crashlytics } from '~/utils/crashlytics';
import { FloatingLogo } from '~/components/FloatingLogo/FloatingLogo';
import { Keyboard } from '~/components/Keyboard/Keyboard';
import { TextField } from '~/components/TextField/TextField';
import { Avatar } from '~/components/Avatar/Avatar';
import { BackButton } from '~/components/BackButton/BackButton';
import { baseAssetsUrl } from '~/configs/env';
import { updateProfileById } from '~/services/profiles/profilesApi';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { verticalScale } from '~/utils/layout';
import { ActionButton } from '~/components/ActionButton/ActionButton';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import type {
  RootStackNavigationProp,
  RootStackRouteProp,
} from '~/routes/routes.types';

import {
  Content,
  AvatarContent,
  StyledFocusableAvatar,
  Title,
  SubTitle,
  Form,
  LeftColumn,
  RightColumn,
  FormActions,
  FormLoading,
} from './ProfileEdit.styles';

const FOCUS_KEY = 'profile-edit-keyboard';
const LOADING_SIZE = verticalScale(50);

const ProfileComponent = (props: FocusableElement) => {
  const { setFocus, stealFocus, updateAllSpatialLayouts } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const { t } = useTranslation();
  const { colors } = useTheme();
  const blockHorizontalFocusProps = useBlockFocusDirection(['left', 'right']);
  const navigation = useNavigation<RootStackNavigationProp<'ProfileEdit'>>();
  const route = useRoute<RootStackRouteProp<'ProfileEdit'>>();
  const { id: idProfile, name, avatar, parentProfile } = route.params;

  const [nameProfile, setNameProfile] = useState<string>(name);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const submitForm = async () => {
    if (isLoading) {
      return;
    }

    if (!nameProfile) {
      setError(t('profile_edit.error', 'Insira seu nome para salvar'));
      return;
    }

    try {
      setError('');
      setIsLoading(true);
      await updateProfileById(idProfile, {
        name: nameProfile,
        avatar,
      });
      navigation.navigate('ProfileManage');
    } catch (err) {
      setError(t('profile_edit.error_on_edit_profile', 'Erro ao editar'));
      setIsLoading(false);
      Crashlytics.handleException(err, 'Update profile');
    }
  };

  const handleChangeAvatar = () =>
    navigation.navigate('ProfileAvatar', { pageRequest: 'ProfileEdit' });

  const handleDeleteProfile = () => {
    if (isLoading) {
      return;
    }
    navigation.navigate('ProfileDelete', { idProfile });
  };

  useEffect(() => {
    setFocus(FOCUS_KEY);
  }, [setFocus]);

  return (
    <ScreenBackground>
      <Content>
        <BackButton blockFocusDirections={['right']} />

        <FloatingLogo />

        <AvatarContent>
          <Title>{t('profile_edit.title', 'Editar perfil')}</Title>
          <StyledFocusableAvatar
            onEnterPress={handleChangeAvatar}
            onPress={handleChangeAvatar}
            {...blockHorizontalFocusProps}
          >
            <Avatar
              altText={nameProfile}
              image={`${baseAssetsUrl}/${avatar}`}
            />
          </StyledFocusableAvatar>
          <SubTitle>
            {t('profile_edit.change_avatar', 'Trocar avatar')}
          </SubTitle>
        </AvatarContent>

        <Form>
          <LeftColumn>
            <Keyboard
              text={nameProfile}
              onChangeText={setNameProfile}
              focusKey={FOCUS_KEY}
              hasTVPreferredFocus
            />
          </LeftColumn>

          <RightColumn>
            <TextField
              placeholder={t('profile_edit.placeholder', 'Nome')}
              value={nameProfile}
              error={error}
            />

            <FormActions>
              {!parentProfile && (
                <ActionButton
                  rightGap
                  expanded
                  text={t('profile_edit.delete', 'Excluir perfil')}
                  onEnterPress={handleDeleteProfile}
                  onPress={handleDeleteProfile}
                  disabled={isLoading}
                />
              )}
              <ActionButton
                expanded
                text={t('profile_edit.save', 'Salvar')}
                onEnterPress={submitForm}
                onPress={submitForm}
                disabled={isLoading}
              />
            </FormActions>
          </RightColumn>

          <FormLoading>
            {isLoading && (
              <ActivityIndicator
                size={LOADING_SIZE}
                color={colors.spinner.primary}
              />
            )}
          </FormLoading>
        </Form>
      </Content>
    </ScreenBackground>
  );
};

export const ProfileEdit = withFocusable({ blockNavigationOut: true })(
  ProfileComponent
);
