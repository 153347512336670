import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { BackHandler, Platform } from 'react-native';

import { TizenKeyMap, WebosKeyMap } from '~/utils/controllerKeyMap';

import { backButtonHandler } from './backButtonHandler';

interface IBackButtonContext {
  updateHandler: (func: (() => boolean) | null) => void;
}

export const BackButtonContext = createContext({} as IBackButtonContext);

const isWebBackButton = (
  event?: globalThis.KeyboardEvent
): event is globalThis.KeyboardEvent => {
  return Boolean(event) && Platform.OS === 'web';
};

export const BackButtonProvider: React.FC = ({ children }) => {
  const [handler, setHandler] = useState(() => backButtonHandler);

  const handlerWrapper = useCallback(
    (event?: globalThis.KeyboardEvent) => {
      if (isWebBackButton(event)) {
        const keyPressed = event?.which || event?.keyCode;

        if (
          keyPressed === TizenKeyMap.Back ||
          keyPressed === WebosKeyMap.Back
        ) {
          event.stopPropagation();
          handler();
        }

        return;
      }

      return handler();
    },
    [handler]
  );

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.onkeydown = handlerWrapper;
    } else {
      BackHandler.addEventListener('hardwareBackPress', handlerWrapper);
    }

    return () => {
      if (Platform.OS === 'web') {
        document.removeEventListener('keydown', handlerWrapper);
      } else {
        BackHandler.removeEventListener('hardwareBackPress', handlerWrapper);
      }
    };
  }, [handlerWrapper]);

  const updateHandler = useCallback((func: (() => boolean) | null) => {
    if (func) {
      setHandler(() => func);
    } else {
      setHandler(() => backButtonHandler);
    }
  }, []);

  const memoizedValue = useMemo(() => ({ updateHandler }), [updateHandler]);

  return (
    <BackButtonContext.Provider value={memoizedValue}>
      {children}
    </BackButtonContext.Provider>
  );
};
